import Highcharts, { inArray } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Button from "../Button";
import { useState } from "react";

function LineChart({ data, campaignName}) {

  // Build graph dates
  var graphData = {
    opensMsg: [],
    bounced: [],
    unsubscribed: [],
    dates: []
  }
  var opensMsg = [];
  var bounced = [];
  var unsubscribed = [];
  const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");
  // opens
  if (Array.isArray(data?.opensMsg) && data?.opensMsg.length > 0) {
    data.opensMsg.forEach(element => {
      if (element[0] != null && element[1] != null) {
        if (dateFormat !== 'weeks') {
          var day = moment(element[0]).format(dateFormat).toString();
        } else {
          var day = moment(element[0]).startOf('week').format("DD/MM/YYYY").toString();
        }
        if (opensMsg[day]) {
          opensMsg[day] = (opensMsg[day]+element[1]);
        } else {
          opensMsg[day] = element[1];
        }
      }
    });
    for (const [date, count] of Object.entries(opensMsg)) {
      let obj = [date,count]
      graphData.opensMsg.push(obj)
    }
  }
  // bounces 
  if (Array.isArray(data?.bounced) && data?.bounced.length > 0) {
    data.bounced.forEach(element => {
      if (element[0] != null && element[1] != null) {
        if (dateFormat !== 'weeks') {
          var day = moment(element[0]).format(dateFormat).toString();
        } else {
          var day = moment(element[0]).startOf('week').format("DD/MM/YYYY").toString();
        }
        if (bounced[day]) {
          bounced[day] = (bounced[day]+element[1]);
        } else {
          bounced[day] = element[1];
        }
      }
    });
    for (const [date, count] of Object.entries(bounced)) {
      let obj = [date,count]
      graphData.bounced.push(obj)
    }
  }
  // unsubscribed
  if (Array.isArray(data?.unsubscribed) && data?.unsubscribed.length > 0) {
    data.unsubscribed.forEach(element => {
      if (element[0] != null && element[1] != null) {
        if (dateFormat !== 'weeks') {
          var day = moment(element[0]).format(dateFormat).toString();
        } else {
          var day = moment(element[0]).startOf('week').format("DD/MM/YYYY").toString();
        }
        if (unsubscribed[day]) {
          unsubscribed[day] = (unsubscribed[day]+element[1]);
        } else {
          unsubscribed[day] = element[1];
        }
      }
    });
    for (const [date, count] of Object.entries(unsubscribed)) {
      let obj = [date,count]
      graphData.unsubscribed.push(obj)
    }
  }

  // get date values
  for (const [date, count] of Object.entries(opensMsg)) {
    graphData.dates.push(date);
  }
  for (const [date, count] of Object.entries(bounced)) {
    graphData.dates.push(date);
  }
  for (const [date, count] of Object.entries(unsubscribed)) {
    graphData.dates.push(date);
  }
  graphData.dates = [...new Set(graphData.dates)];

  // Add missing dates
  for (const [key, mainDate] of Object.entries(graphData.dates)) {
    // Opens
    if (opensMsg[mainDate] === undefined) {
      graphData.opensMsg.push([mainDate,0])
    }
    // bounced
    if (bounced[mainDate] === undefined) {
      graphData.bounced.push([mainDate,0])
    }
    // unsubscribed
    if (unsubscribed[mainDate] === undefined) {

      graphData.unsubscribed.push([mainDate,0])
    }
  }

  // Order arrays
  graphData.opensMsg.sort();
  graphData.bounced.sort();
  graphData.unsubscribed.sort();
  graphData.dates.sort();

  // Build highcharts options
  const options = {
    height: "100%",
    redraw: true,
    title: {
      text: `${campaignName} overview`,
    },
    yAxis: {
      title: {
        text: 'Messages'
      }
    },
    xAxis: {
      accessibility: {
        rangeDescription: 'Date'
      },
      title: {
        text: 'Date'
      },
      categories: graphData.dates
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    series: [{
      name: 'Opened',
      data: graphData.opensMsg
    }, {
      name: 'Unsubscribed',
      data: graphData.unsubscribed
    }, {
      name: 'Bounced',
      data: graphData.bounced
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }

  return (
    <div className="flex flex-col justify-center">
      <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <div className="flex">
        <Button
          topStyle={{marginLeft: "1rem"}}
          buttonStyle={{marginLeft: "1rem"}}
          onClick={() => {
            setDateFormat("DD/MM/YYYY")
          }}
          interior="Days"
        ></Button>
        <Button
          topStyle={{marginLeft: "1rem"}}
          buttonStyle={{marginLeft: "1rem"}}
          onClick={() => {
            setDateFormat("weeks")
          }}
          interior="Weeks"
        ></Button>
        <Button
          topStyle={{marginLeft: "1rem"}}
          buttonStyle={{marginLeft: "1rem"}}
          onClick={() => {
            setDateFormat("MM/YYYY")
          }}
          interior="Months"
        ></Button>
        <Button
          topStyle={{marginLeft: "1rem"}}
          buttonStyle={{marginLeft: "1rem"}}
          onClick={() => {
            setDateFormat("YYYY")
          }}
          interior="Years"
        ></Button>
      </div>
      <div className="flex m-2">
      <p className="text-xs text-slate-600">* This is based on data across all emails in this occurrence that have been sent</p>
      </div>
    </div>
    </div>
    
  );
}

export default LineChart;
