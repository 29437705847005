import Dropdown from "../Dropdown";
import { InboxInIcon, AnnotationIcon } from "@heroicons/react/outline"
import { useDispatch } from "react-redux"
import { updateAddingModalDraftData, updateEditModeStep, getOutboundEmail } from "../../features/nurturingJourneyManager";
import { useSelector } from "react-redux"
import TextBox from "./../TextBox";
import Label from "./../Label";
import Heading2 from "./../Heading2";
import { useEffect } from "react";

function Header() {
  const {editMode, outboundEmailData} = useSelector((state) => state.nurturingJourneyManager)
  const stepTypesDropdown = [
    {
      value: "1",
      text: "Email",
    },
    {
      value: "2",
      text: "SMS"
    }
  ]
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOutboundEmail())
  }, []);

  const showAdding = editMode.addingStep
  const stepID = (showAdding ? editMode.addingStepData.id : editMode.editingStepData.id)
  const stepName = showAdding ? editMode.addingStepData.step_name : editMode.editingStepData.step_name
  const stepTypeID = showAdding ? editMode.addingStepData.type_id : editMode.editingStepData.type_id
  const subject = showAdding ? editMode.addingStepData.subject :  editMode.editingStepData.subject
  const replyToAddress = showAdding ? editMode.addingStepData.reply_to_address : editMode.editingStepData.reply_to_address
  const pretext = showAdding ? (editMode.addingStepData.pretext ?? editMode.addingStepData.header) : (editMode.editingStepData.pretext ?? editMode.editingStepData.header)
  const fromAddress = showAdding ? editMode.addingStepData.from_address : editMode.editingStepData.from_address
  return (
    <>
      <div className="w-full min-h-fit flex justify-start border-b border-gray-200 flex-wrap">
        <div className="w-72">
          <div className="flex items-center">
            <h2 className="text-xl font-medium pr-2 leading-5 text-gray-800 leading-2">
              Step Name
            </h2>
          </div>
          <p className="mt-4 text-sm leading-5 mb-4 text-gray-600">
            Select a name and type for this step
          </p>
        </div>
        <div className="md:flex items-start mt-0">
          <div className="md:w-64">
            {editMode.errors?.includes("stepNameCharacters")
              ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
              : null}
            <TextBox
              labelText="Step Name"
              onChange={
                (e) => {
                  if (editMode.addingStep) {
                    dispatch(
                      updateAddingModalDraftData(
                        {
                          step_name: e.target.value
                        }
                      )
                    )
                  } else {
                    dispatch(
                      updateEditModeStep(
                        {
                          step_id: stepID,
                          step_name: e.target.value
                        }
                      )
                    )
                  }
                }
              }
              type="name"
              id="stepName"
              tabIndex="0"
              className="w-full p-3 relative text-sm leading-none text-gray-600 bg-white rounded w-full h-12 py-2 outline-none border-2"
              aria-labelledby="Step name"
              placeholder="Name"
              initialValue={stepName}
              error={editMode.errors?.includes("stepType") && (!stepName || !stepTypeID)}
            />
          </div>
          <div className="md:w-44 md:ml-12 min-h-24">
            <Label
              for="stepType"
              text="Step Type" />
            <Dropdown 
              id="stepType" 
              optionOnClick={
                (e) => {
                  if (editMode.addingStep) {
                    dispatch(
                      updateAddingModalDraftData({
                        type_id: parseInt(e.target.dataset.value),
                        type_name: e.target.innerText
                      })
                    )
                  } else {
                    dispatch(
                      updateEditModeStep(
                        {
                          step_id: stepID,
                          type_id: e.target.value
                        }
                      )
                    )
                  }
                }
              }
              disabled={editMode.editingStep}
              placeHolder={stepTypeID === 2 ? "SMS" : stepTypeID === 1 ? "Email" : "Type"}
              name={"stepType"}
              options={stepTypesDropdown}
              overwritePlaceholder={true} />
          </div>
        </div>
      </div>
      {/* User Selects Email as Step type */}
      { stepTypeID === 1 &&
        <div className="w-full flex justify-start mt-2 pt-4 flex-wrap">
          <div className="w-72">
              <Heading2>
                Step Details - Email
                <InboxInIcon className="h-6 w-6 text-primary-color-dark ml-2"/>
              </Heading2>
          </div>
          <div>
            <div className="md:flex items-start mt-0 justify-end">
              <div className="md:w-64">
                {editMode.errors?.includes("subjectCharacters")
                  ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
                  : null}
                <TextBox 
                  name="subject"
                  labelText={"Subject"}
                  onChange={
                    (e) => {
                      if (editMode.addingStep) {
                        dispatch(
                          updateAddingModalDraftData(
                            {
                              subject: e.target.value
                            }
                          )
                        )
                      } else {
                        dispatch(
                          updateEditModeStep(
                            {
                              step_id: stepID,
                              subject: e.target.value
                            }
                          )
                        )
                      }
                    }
                  }
                  labelledBy="subject"
                  placeholder={"Subject"}
                  initialValue={subject !== undefined ? subject : ""}
                />
              </div>
              <div className="md:w-64 md:ml-12 md:mt-0 mt-4 min-h-24">
                <div className="md:w-64">
                  <Label for="replyToAddress" text="Reply to Address" />
                  <Dropdown id="replyToAddress" 
                    options={["foster@nfagroup.co.uk"]}
                    optionOnClick={
                      (e) => {
                        if (editMode.addingStep) {
                          dispatch(updateAddingModalDraftData({
                            reply_to_address: e.target.innerText
                          }))
                        } else {
                          dispatch(updateEditModeStep(
                            {
                              step_id: stepID,
                              reply_to_address: e.target.innerText
                            }
                          ))
                        }
                      }
                    }
                    placeHolder={replyToAddress !== undefined ? replyToAddress : "Reply to address"}
                    error={editMode.errors?.includes("email") && (!pretext || !subject || !fromAddress || !replyToAddress)}
                    overwritePlaceholder={true} />
                </div>
              </div>
            </div>
            <div>
              <div className="md:flex items-start justify-end">
                <div className="md:w-64">
                  {editMode.errors?.includes("pretextCharacters")
                    ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
                    : null}
                  <TextBox
                  name="pretext"
                  labelText="Pretext"
                  onChange={
                    (e) => {
                      if (editMode.addingStep) {
                        dispatch(
                          updateAddingModalDraftData(
                            {
                              pretext: e.target.value
                            }
                          )
                        )
                      } else {
                        dispatch(
                          updateEditModeStep(
                            {
                              step_id: stepID,
                              pretext: e.target.value
                            }
                          )
                        )
                      }
                    }
                  }
                  labelledBy="pretext"
                  placeholder={pretext !== undefined ? pretext : "Pretext"}
                  initialValue={pretext !== undefined ? pretext : ""} 
                  />
                </div>
                <div className="md:w-64 md:ml-12 md:mt-0 min-h-24">
                  <Label
                    for="fromAddress"
                    text="Outbound Email"/>
                  <Dropdown id="fromAddress" 
                    options={outboundEmailData?.data?.data}
                    optionOnClick={
                      (e) => {
                        if (editMode.addingStep) {
                          dispatch(updateAddingModalDraftData({
                            from_address: e.target.innerText
                          }))
                        } else {
                          dispatch(updateEditModeStep(
                            {
                              step_id: stepID,
                              from_address: e.target.innerText
                            }
                          ))
                        }
                      }
                    }
                    placeHolder={fromAddress !== undefined ? fromAddress : "Outbound email address"}
                    overwritePlaceholder={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/* User selects SMS as Step type */}
      {stepTypeID === 2 &&
        <div className="w-full flex justify-start mt-2 pt-4 flex-wrap">
          <div className="w-72">
            <div className="flex">
              <Heading2>
                Step Details - SMS
                <AnnotationIcon className="ml-2 h-6 w-6 text-primary-color-dark"/>
              </Heading2>
            </div>
          </div>
          <div>
            <div className="w-full justify-start">
              <div className="md:flex items-start justify-start">
                <div className="md:w-64">
                    {editMode.errors?.includes("subjectCharacters")
                      ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
                      : null}
                    <TextBox 
                      name="smsSubject"
                      labelText={"Subject"}
                      onChange={
                        (e) => {
                          if (editMode.addingStep) {
                            dispatch(updateAddingModalDraftData({
                              subject: e.target.value
                            }))
                          } else {
                            dispatch(updateEditModeStep(
                              {
                                step_id: stepID,
                                subject: e.target.value
                              }
                            ))
                          }
                        } 
                      }
                      labelledBy="smsSubject"
                      placeholder={"Subject"}
                      initialValue={subject !== undefined ? subject : ""}
                      error={editMode.errors?.includes("smsSubject") && (!pretext || !subject || !fromAddress || !replyToAddress)}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Header;
