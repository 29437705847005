import {
  ArrowRightIcon,
  EyeIcon,
  MinusSmIcon,
  XIcon
} from "@heroicons/react/outline"
import { useState } from "react"
import { rotateTableData } from "../utils/rotateTableData"
import EngagementReportOccurrenceModal from "../containers/EngagementReportOccurrenceModal"
import { useAppDispatch } from "../redux/store"
import { setModalOpen } from "../features/report"
import { getCampaignStatus } from "../utils/getStrings"
import Button from "./Button"
import { FolderAddIcon } from "@heroicons/react/outline"
import EngagementReportMainBody from "./Reporting/EngagementReportMainBody"
import api_server from "../axios/baseURL"
import SecureFetch from "./SecureFetch"
import Heading1 from "./Heading1"
import moment from "moment"
import {
  toggleCampaignSelection,
} from "../features/abComparison"
import { useSelector } from "react-redux"
import DownloadCSV from "./DownloadCSV"
export default function OccurrencesTable(
  {
    abComparison = false,
    modalData,
    data,
    loading = false,
    fileName,
  }
) {
  const [selectedCampaignForDropdown, setSelectedCampaignForDropdown] = useState({})
  const [selectedCampaignForModal, setSelectedCampaignForModal] = useState({})
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownData, setDropdownData] = useState({})
  const dispatch = useAppDispatch()
  const visibleRows = [
    "status_id",
    "status",
    "reach",
    "sent",
    "opens",
    "global_unsubscribed",
    "bounced",
    "click",
    "unique_clicks",
    "campaign_score"
  ]
  const { campaignsData } = useSelector(state => state.abComparison)
  const { reportMapData } = useSelector(state => state.report)

  

  function buildTable(rows) {
    const rowKeys = Object.keys(rows)
    if (!rowKeys.length || !rows[rowKeys[0]].length) {
      return {
        head: <></>,
        body: <></>
      }
    }
    //cell
    const tableCellStyling = "p-2 relative"
    const headCellStyling =
      tableCellStyling +
      " font-normal bg-primary-color-dark first:rounded-tl-lg last:rounded-tr-lg"
    const bodyCellStyling = tableCellStyling + " border-b border-1"
    //cell interior
    const tableCellInteriorStyling = "block flex justify-center items-center" //applied to span wrapping the entire cell interior
    const tableCellContentsWrapperStyling = "block flex leading-none" //applied to span wrapping the text
    //icon wrapper
    const iconWrapperStyling =
      "block w-4 absolute flex items-center top-0 right-2 bottom-0"
    //icon
    const iconStyling = "block w-4 h-4"
    const rowDefs = {
      status_id: "Status",
      reach: "Messages to send (Reach)",
      sent: "Messages sent",
      opens: "Emails opened",
      global_unsubscribed: "Unsubscribed",
      bounced: "Bounced",
      click: "Links clicked",
      unique_clicks: "Unique clicks",
      campaign_score: "Campaign Score",
    }
    const arrowRows = [
      "reach",
      "sent",
      "opens",
      "global_unsubscribed",
      "bounced",
      "click",
      "unique_clicks",
      "campaign_score",
    ]
    rows.status_id = rows.status_id.map(
      (statusID) => {
        let color
        switch (getCampaignStatus(statusID)) {
          default: color = 0; break;
          case "Active": color = 1; break;
          case "Draft": color = 2; break;
          case "Completed": color = 3; break;
          case "Archived": color = 4; break;
          case "Failed": color = 5; break;
        }
        return <Button disabled={true} inert={true} interior={getCampaignStatus(statusID)} backgroundColorOverride={color} />
      }
    )
    const headerTitleRow = abComparison
      ? rows.campaign_name
      : rows.issued_date
    const occurrenceIDs = rows.id
    const stepsIds = rows.stepIds
    const fullTableHeadMarkup = (
      <tr>
        <th className={headCellStyling}></th>
        {headerTitleRow.map((cellValue, cellIndex) => {
          return (
            <th className={headCellStyling} key={`head-row-${cellIndex}`}>
              <span className={tableCellInteriorStyling}>
                {abComparison
                  ? cellValue
                  : moment(cellValue).format("DD/MM/YYYY")}
              </span>
              <span className="flex flex-row justify-center block">
                <button
                  className="mx-4"
                  onClick={(e) => {
                    setSelectedCampaignForModal({
                      id: occurrenceIDs[cellIndex],
                      name: headerTitleRow[cellIndex],
                    })
                    dispatch(setModalOpen(true))
                  }}
                >
                  <FolderAddIcon
                    className="w-6 h-6 text-white"
                    style={{ marginTop: "8px" }}
                  />
                </button>
                {abComparison && (
                  <button
                    className="mx-4"
                    onClick={async (e) => {
                      if (
                        occurrenceIDs[cellIndex] ===
                        selectedCampaignForDropdown?.id
                      ) {
                        setDropdownOpen(false)
                      } else {
                        setSelectedCampaignForDropdown({
                          id: occurrenceIDs[cellIndex],
                          name: headerTitleRow[cellIndex],
                        })
                        if (!dropdownOpen) {
                          setDropdownOpen(true)
                        }
                      }
                      await setReportData({
                        campaignId: occurrenceIDs[cellIndex],
                        stepsIds: stepsIds[cellIndex],
                      })
                    }}
                  >
                    <EyeIcon className="text-white mt-2 h-6 w-6" />
                  </button>
                )}
                <button
                  className="mx-4"
                  onClick={
                    (e) => {
                      const selection = abComparison
                        ? {
                            id: occurrenceIDs[cellIndex],
                            name: headerTitleRow[cellIndex]
                          }
                        : occurrenceIDs[cellIndex]
                      dispatch(toggleCampaignSelection(selection))
                    }
                  }>
                    <XIcon className="text-white mt-2 h-6 w-6" />
                </button>
              </span>
            </th>
          )
        })}
      </tr>
    )
    const fullTableBodyMarkup = []
    Object.entries(rows).forEach(
      ([rowKey, rowValue], rowIndex) => {
        if (visibleRows.includes(rowKey)) {
          //skips over extraneous "rows" that are included in the data but not part of the UI
          const rowContents = rowValue.map((cellContents, cellIndex) => {
            if (Object.keys(rowDefs).includes(rowKey)) {
              return (
                <td
                  className={bodyCellStyling}
                  key={`row-${rowIndex}-cell-${rowKey}-${cellIndex}`}
                >
                  <span className={tableCellInteriorStyling}>
                    <span className={tableCellContentsWrapperStyling}>
                      {cellContents}
                    </span>
                    {arrowRows.includes(rowKey) && cellIndex > 0 && (
                      <span className={iconWrapperStyling}>
                        {cellContents === rows[rowKey][cellIndex - 1] ? (
                          <MinusSmIcon
                            className={`${iconStyling} text-gray-500`}
                          />
                        ) : (
                          <ArrowRightIcon
                            className={`${iconStyling} ${
                              cellContents > rows[rowKey][cellIndex - 1]
                                ? "-rotate-45 text-primary-color-dark"
                                : "rotate-45 text-red-500"
                            }`}
                          />
                        )}
                      </span>
                    )}
                  </span>
                </td>
              )
            }
          })

          fullTableBodyMarkup.push(
            <tr className="bg-palette-gray-100" key={rowIndex} value={rowValue}>
              <td className={bodyCellStyling}>{rowDefs[rowKey]}</td>
              {rowContents}
            </tr>
          )
        }
      }
    )

    return {
      body: fullTableBodyMarkup,
      head: fullTableHeadMarkup
    }
  }

  const setReportData = async ({ campaignId, stepsIds }) => {
    stepsIds = stepsIds.join(",")
    const [mapData, linksData, graphData, reportData] = await Promise.all([
      SecureFetch(
        `${api_server}/report/maps/${campaignId}?stepsId=${stepsIds}`
      ),
      SecureFetch(
        `${api_server}/report/links/${campaignId}?stepsId=${stepsIds}`
      ),
      SecureFetch(
        `${api_server}/report/graph/${campaignId}?stepsId=${stepsIds}`
      ),
      SecureFetch(
        `${api_server}/report/campaign/${campaignId}?stepsId=${stepsIds}`
      ),
    ])
    const mapPayload = await mapData.json()
    const linksPayload = await linksData.json()
    const graphPayload = await graphData.json()
    const reportPayload = await reportData.json()
    setDropdownData({
      mapData: mapPayload,
      linksData: linksPayload,
      graphData: graphPayload,
      reportData: reportPayload,
    })
  }

  const table = buildTable(rotateTableData(data))

  return (
    <>
      {loading
        ? <div className="flex items-center justify-center text-2xl w-full min-h-occurrences-table">
            <div className="flex items-center justify-center">
              Please wait while your rows are loading
              <div className="w-6 h-6 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent ml-3 mr-3"></div>
            </div>
          </div>
        : <div className="min-h-occurrences-table rounded-xl overflow-scroll-auto flex">
            <table className="border-collapse rounded rounded-t-xl text-sm">
              <thead className="text-white rounded rounded-t-lg rounded-t-xl">
                {table.head}
              </thead>
              <tbody>{table.body}</tbody>
            </table>
            <DownloadCSV
              data={data}
              fileName={fileName}
            />
            {modalData.open && (
              <EngagementReportOccurrenceModal
                campaignId={selectedCampaignForModal.id}
              />
            )}
          </div>
      }
      {selectedCampaignForDropdown.name && (
        <div className="mt-12">
          <Heading1 paddingBottom>
            {selectedCampaignForDropdown.name}
            {(dropdownOpen && !Object.keys(dropdownData).length)
              ? " - Loading additional information"
              : ""}
          </Heading1>
        </div>
      )}
      {(abComparison && dropdownOpen && Object.keys(dropdownData).length > 0) && (
        <EngagementReportMainBody
          campaignName={selectedCampaignForDropdown.name}
          reportGraphData={dropdownData?.graphData?.data}
          reportMapData={reportMapData.data}
          reportLinksData={dropdownData?.linksData?.data}
          idData={dropdownData?.reportData?.data[0]}
        />
      )}
    </>
  )
}
