import * as React from "react";
import TextField from "@mui/material/TextField";
import Dropdown from "../../../Dropdown";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "../../../../features/campaign";
import Heading2 from "../../../Heading2";
import { useState } from "react";

const update = async (call,value,setOccurrences) => {
  const dates = document.querySelectorAll('input[type="tel"]');
  if (call === "to" ) {
    var from = moment(dates[0].value.toString(), "DD/MM/YYYY");
    var to = value;
  } else if (call === "from" ) {
    var from = value;
    var to = moment(dates[1].value.toString(), "DD/MM/YYYY");;
  }
  const diff = to.diff(from,'days');
  let options = [
    { text: "Only Once", value: 1 },
    { text: "Daily", value: 2 }
  ];
  if (diff >= 7) {
    options.push({ text: "Weekly", value: 3 })
  }
  if (diff >= 28) {
    options.push({ text: "Monthly", value: 4 })
  }
  if (diff >= 365) {
    options.push({ text: "Yearly", value: 6 })
  }
  setOccurrences(options);
}

const initOptions = () => {
  var state = useSelector((state) => state.campaign.flags)
  if (state.startDateTime && state.endDateTime) {
    var from = moment(state.startDateTime, "YYYY/MM/DD");
    var to = moment(state.endDateTime, "DD/MM/YYYY");
    var diff = from.diff(to,'days');
    let options = [
      { text: "Only Once", value: 1 },
      { text: "Daily", value: 2 }
    ];
    if (diff >= 7) {
      options.push({ text: "Weekly", value: 3 })
    }
    if (diff >= 28) {
      options.push({ text: "Monthly", value: 4 })
    }
    if (diff >= 365) {
      options.push({ text: "Yearly", value: 6 })
    }
    return options;
  } else {
    return [];
  }
}

function Scheduler({editCampaign, currentSelectedStatus}) {
  
  const dispatch = useDispatch()
  let  { endDateTime, startDateTime, recurrenceName, recurrenceType, status, errors} = useSelector((state) => state.campaign.flags)
  const [occurrences, setOccurrences] = useState(initOptions());
  return (
    <div className="w-full min-h-fit flex justify-between border-b mt-4 mb-16 pb-4 border-gray-200 flex-wrap">
      <div className="w-64">
        <div className="flex items-center">
          <Heading2>
            Scheduler
          </Heading2>
        </div>
        <p className="my-4 text-sm leading-5 text-gray-600">
          Select a start/end time for the campaign and how often you want the
          campaign to run
        </p>
      </div>
      <div className="flex align-center">
        <div className="mr-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Start Date/Time"
              minutesStep={30}
              disabled={
                status === "Completed" || 
                status === "Archived" || 
                status === "Active" ||
                status === "Failed" ||
                currentSelectedStatus == 4 ||
                currentSelectedStatus == 5 ||
                currentSelectedStatus == 3
              }
              displayStaticWrapperAs="desktop"
              openTo="day"
              disablePast={true}
              minDateTime={moment.utc(new Date())}
              value={new Date(startDateTime) || null}
              onChange={(newValue) => {
                update("from",newValue,setOccurrences);
                dispatch(updateCampaign({ startDateTime : moment(newValue, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD HH:mm")}));
                recurrenceType === 1 && dispatch(updateCampaign({endDateTime : moment(newValue, "DD/MM/YYYY HH:mm").format("YYYY/MM/DD")}))
              }}
              inputFormat="DD/MM/yyyy hh:mm a"
              renderInput={(props) => <TextField {...props} variant="outlined" inputProps={{...props.inputProps, placeholder: null}} />}
            />
          </LocalizationProvider>
        </div>
        <div className="mr-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="End Date"
              inputFormat="DD/MM/YYYY"
              type="datetime-local"
              minDate={moment.utc(endDateTime)}
              disabled={
                status === "Completed" || 
                status === "Archived" || 
                status === "Active" ||
                status === "Failed" ||
                currentSelectedStatus == 4 ||
                currentSelectedStatus == 5 ||
                currentSelectedStatus == 3 ||
                recurrenceType === 1
              }
              value={recurrenceType !== 1 ? new Date(endDateTime) : moment(startDateTime).format("YYYY/MM/DD")}
              onChange={(newValue) => {
                update("to",newValue,setOccurrences);
                dispatch(updateCampaign({ endDateTime : (moment(newValue, "DD/MM/YYYY").format("YYYY/MM/DD"))}))
              }}
              renderInput={(props) => <TextField {...props} variant="outlined" inputProps={{...props.inputProps, placeholder: endDateTime}} />}
            />
          </LocalizationProvider>
        </div>
        <Dropdown
          optionOnClick={(e) => {
              dispatch(updateCampaign(
                {
                  recurrenceType: e.target.dataset.value
                }
              ))
            }
          }
          placeHolder={recurrenceName ?? "Occurence"}
          disabled={
            status === "Completed" || 
            status === "Archived" || 
            status === "Active" ||
            status === "Failed" ||
            currentSelectedStatus == 4 ||
            currentSelectedStatus == 5 ||
            currentSelectedStatus == 3
          }
          overwritePlaceholder={true}
          name={"recurrenceType"}
          options={occurrences}
          error={errors ? errors.includes("dateTime") ? "dateTime" : "" : ""}
        />
      </div>
    </div>
  );
}

export default Scheduler;
