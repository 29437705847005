function ProgressCards({campaignStep, changeCampaignStep, validatedSteps}) {
  return (
    <div className="bg-gray-100 flex flex-wrap items-center justify-center">
      <div onClick={() => changeCampaignStep(0)} className="w-52 h-16 relative mt-0">
        <img
          src="https://i.ibb.co/c2k4Gbr/Steps3.png"
          alt="step2"
          className="w-full h-full"
        />
        <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0 cursor-pointer">
          <p className='w-full text-sm font-medium leading-4 text-primary-color-dark'>
            Campaign Header
          </p>
          <p className="w-full text-xs mt-1 leading-none text-gray-500">
            Create a new campaign
          </p>
        </div>
      </div>

      {validatedSteps.includes(0) && (
        <div onClick={() => changeCampaignStep(1)} className="w-52 h-16 relative mt-0 cursor-pointer">
          <img
            src="https://i.ibb.co/c2k4Gbr/Steps3.png"
            alt="step2"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 ${campaignStep > 0 ? 'text-primary-color-dark' : 'text-gray-700'}`}>
              Template Design
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Check the template
            </p>
          </div>
        </div>
      )}
      {!validatedSteps.includes(0) && (
        <div className="w-52 h-16 relative mt-0 cursor-default">
          <img
            src="https://i.ibb.co/c2k4Gbr/Steps3.png"
            alt="step2"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 text-gray-700`}>
              Template Design
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Check the template
            </p>
          </div>
        </div>
      )}

      {validatedSteps.includes(1) && (
        <div onClick={() => changeCampaignStep(2)} className="cursor-pointer w-52 h-16 relative mt-0">
          <img
            src="https://i.ibb.co/c2k4Gbr/Steps3.png"
            alt="step3"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 ${campaignStep > 1 ? 'text-primary-color-dark' : 'text-gray-700'}`}>
              Source List
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Create or select a list of recipients
            </p>
          </div>
        </div>
      )}
      {!validatedSteps.includes(1) && (
        <div className="cursor-default w-52 h-16 relative mt-0">
          <img
            src="https://i.ibb.co/c2k4Gbr/Steps3.png"
            alt="step3"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 text-gray-700`}>
              Source List
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Create or select a list of recipients
            </p>
          </div>
        </div>
      )}

      {validatedSteps.includes(2) && (
        <div onClick={() => changeCampaignStep(3)} className="cursor-pointer w-52 h-16 relative mt-0">
          <img
            src="https://i.ibb.co/XCdjrhm/Steps4.png"
            alt="step4"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 ${campaignStep > 2 ? 'text-primary-color-dark' : 'text-gray-700'}`}>
              Scheduler
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Select campaign dates
            </p>
          </div>
        </div>
      )}
      {!validatedSteps.includes(2) && (
        <div className="cursor-default w-52 h-16 relative mt-0">
          <img
            src="https://i.ibb.co/XCdjrhm/Steps4.png"
            alt="step4"
            className="w-full h-full"
          />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 text-gray-700`}>
              Scheduler
            </p>
            <p className="w-full text-xs mt-1 leading-none text-gray-500">
              Select campaign dates
            </p>
          </div>
        </div>
      )}

    </div>
  );
}

export default ProgressCards;
