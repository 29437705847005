import { useState, useEffect } from "react";
import { PencilAltIcon, CheckIcon, XIcon } from "@heroicons/react/outline";
import Dropdown from "./Dropdown";
import { useAppDispatch } from "../redux/store";
import {
  endEditMode,
  setJourneysListFromAPI,
  setJourneyStepsFromAPI,
  startEditMode
} from "../features/nurturingJourneyManager";
import { useSelector } from "react-redux";
import FilterButton from "./FilterButton";

const NurturingJourneyManagerSearch = () => {
  const dispatch = useAppDispatch();
  const {
    filter,
    editMode,
    journeysList,
    journeysListLoaded,
    journeySteps,
    journeyStepsLoaded,
  } = useSelector((state) => state.nurturingJourneyManager);
  const [showSearchInfo, setShowSearchInfo] = useState(false);
  const hasFilter = Object.values(filter).some((v) => {
    return !!v !== false;
  });

  useEffect(() => {
    if (!journeysListLoaded && !journeysList.length) {
      dispatch(setJourneysListFromAPI());
    }
  });

  let dropdownOptions = [];
  if (journeysList && journeysList.length) {
    journeysList.forEach((journey) => {
      const val = JSON.stringify({
        contactListId: journey.contact_list_id,
        id: journey.id,
        reach: journey.reach,
        contacts: journey.contacts
      });
      dropdownOptions.push({
        text: journey.group_name,
        value: val,
      });
    });
  }

  return (
    <div className="border-b-2 border-primary-color-dark bg-white flex flex-col">
      <div className="flex items-between justify-between">
        <div className={`flex items-center justify-end p-6`}>
          <Dropdown
            placeHolder="Journey"
            loaded={journeysListLoaded}
            options={dropdownOptions}
            search={true}
            disabled={editMode.editing}
            overwritePlaceholder={true}
            optionOnClick={async (e) => {
              if (!editMode.editing) {
                const val = JSON.parse(e.target.dataset.value);
                const groupId = parseInt(val.id);
                const contactListId = parseInt(val.contactListId);
                const reach = parseInt(val.reach);
                const contacts = val.contacts;
                const groupName = e.target.innerText;
                dispatch(
                  setJourneyStepsFromAPI({
                    ...filter,
                    groupId: groupId,
                    groupName: groupName,
                    contactListId: contactListId,
                    reach: reach,
                    contacts: contacts
                  })
                );
              }
            }}
          />
        </div>
        <div className="flex items-center justify-between p-6">
          <button
            className="w-10 h-10 bg-primary-color-dark transition focus:outline-none duration-150 ease-in-out hover:bg-green-400 rounded text-white ml-1 disabled:opacity-[.5] disabled:hover:bg-primary-color-dark"
            disabled={!journeyStepsLoaded || !hasFilter}
            onClick={() => {
              if (!editMode.editing) {
                dispatch(startEditMode());
              } else {
                dispatch(endEditMode());
              }
            }}
          >
            {editMode.editing ? (
              <CheckIcon className="text-white w-6 h-6 m-2" />
            ) : (
              <PencilAltIcon className="text-white w-6 h-6 m-2" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default NurturingJourneyManagerSearch;
