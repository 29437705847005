import { getCampaignStatus } from "../utils/getStrings"
import {
    CalendarIcon,
    ChatAltIcon,
    InboxIcon,
    PresentationChartBarIcon,
    QuestionMarkCircleIcon,
    SelectorIcon,
    XIcon,
    PlusIcon,
    PencilAltIcon
} from "@heroicons/react/outline";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux"
import { setAddingStep, setEditingStep, setEditingStepData, setNewStepPredecessor } from "../features/nurturingJourneyManager";
import Button from "./Button";
import Reach from "./Reach";

const NurturingJourneyManagerCard = (props) => {
	const dispatch = useAppDispatch()
	const {editMode} = useSelector((state) => state.nurturingJourneyManager)
    let icon = <QuestionMarkCircleIcon className="h-6 w-6 min-h-6 min-w-6 text-gray-500" />
    if (props.typeID === 2) {
        icon = <ChatAltIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />
    }
    if (props.typeID === 1) {
        icon = <InboxIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />
    }
    
    function undefinedValueText(str = "Not set") {
        return (
            <span className={`italic text-gray-500`}>
                {str}
            </span>
        )
    }

    let reach = !props?.contactIDs?.length ? 0 : props.contactIDs.length;
   
    return (
        <div className={`
            flex flex-row
            relative
            min-h-92 h-92 max-h-92 basis-92
            min-w-104 w-104 max-w-104
            xl:min-w-184 xl:w-184 xl:max-w-184
            mb-14 lg:mt-0
            rounded
            bg-palette-green-600 bg-palette-green-400 bg-palette-green-900 bg-palette-gray-500`} style={{backgroundColor: "white"}}> {/*inline styling overcomes Tailwind's strange ideas about how opacity should work -PH */}
                <div className={`w-14 absolute -top-6 -right-7 flex flex-col rounded${!props.editing ? " hidden" : ""}`}>
                    <div {...props.dragHandleProps} className="flex items-center justify-center w-14 h-14">
                        <div className="flex items-center justify-center bg-primary-color-dark transition focus:outline-none duration-150 ease-in-out hover:bg-green-400 rounded text-white disabled:opacity-[.5] disabled:hover:bg-primary-color-dark w-10 h-10">
                            <SelectorIcon className="text-white w-8 h-8" />
                        </div>
                    </div>
                    <Button
                        interior={<PencilAltIcon className="w-6 h-6" />}
                        onClick={(e) => {
                            const index = parseInt(props.dragHandleProps["data-rbd-drag-handle-draggable-id"])
                            dispatch(setEditingStep(true))
                            dispatch(setEditingStepData(editMode.newSteps[index]))
                        }} />
                    <Button
                        interior={<XIcon className="w-6 h-6" />}
                        onClick={(e) => {
                            props.setStepId(props.value)
                            props.setShowModal(true)
                            props.setIndex(parseInt(props.dragHandleProps["data-rbd-drag-handle-draggable-id"]))
                           }} />
                </div>
                <div className={`w-14 h-14 absolute -bottom-30 left-45 rounded${!props.editing ? " hidden" : ""}`}>
                    <Button
                        interior={<PlusIcon className="w-6 h-6" />}
                        onClick={(e) => {
                            const newData = [...editMode.newSteps]
                            const index = parseInt(props.dragHandleProps["data-rbd-drag-handle-draggable-id"])
                            dispatch(setAddingStep(true))
                            dispatch(
                                setNewStepPredecessor(
                                    {
                                        index: index,
                                        stepOrder: newData[index].step_order,
                                        id: (newData[index].id ?? newData[index].step_id)
                                    }
                                )
                            )
                        }} />
                </div>
                <div className={`relative flex flex-col dark:bg-gray-900 opacity-100
                    p-4 pt-0 mx-auto
                    lg:pb-6 lg:px-6
                    min-h-80
                    w-104 max-w-104
                    before:absolute
                    before:w-64 
                    before:h-12 
                    before:bg-transparent
                    before:-bottom-12
                    before:left-20
                    before:border
                    before:border-0
                    before:border-transparent
                    before:border-t-[3rem]
                    before:border-x-[8rem]
                    before:border-t-white`}>
                    <div className="flex items-center justify-between w-full align-items-center">
                        <h3 className="flex text-sm lg:text-xl text-gray-900 dark:text-gray-900 min-h-56 font-semibold font-sans pt-3 pb-3">
                            {props.name}
                        </h3>
                    </div>
                    <div className="grid grid-cols-3 py-2 text-xs border border-0 border-y-2 border-gray-90">
                        <div className="flex items-center flex-wrap flex-row h-full border-gray-90 border-r-2 m-1">
                            <CalendarIcon className="text-black min-h-6 min-w-6 h-6 w-6 mr-2" />
                            <span className="break-all w-icon-text">Waiting Days</span>
                        </div>
                        <div className="flex items-center flex-wrap flex-row h-full border-gray-90 border-r-2 m-1">
                            <PresentationChartBarIcon className="text-black min-h-6 min-w-6 h-6 w-6 mr-2" />
                            <span className="break-all w-icon-text">Success Rate</span>
                        </div>
                        <div className="flex items-center justify-center h-full row-span-2 m-1">
                            <div className="inline-flex flex-col justify-around h-full row-span-2 m-1 text-xs">
                                <span>Planned: {props.planned ?? undefinedValueText("n/a")}</span>
                                <span>Sent: {props.sent ?? undefinedValueText("n/a")}</span>
                                <span>Drop-off: {props.dropOff ?? undefinedValueText("n/a")}</span>
                            </div>
                        </div>
                        <div className="flex justify-center text-xl border-gray-90 border-r-2 m-1">
                            {props.waitingDays || undefinedValueText("n/a")}
                        </div>
                        <div className="flex justify-center text-xl border-gray-90 border-r-2 m-1">
                            {props.successRate !== undefined ? `${props.successRate}%` : undefinedValueText("n/a")}
                        </div>
                    </div>
                    <div className="pt-2 pb-2 flex items-center border border-0 border-b-2 border-gray-90">
                        <div className="pr-2">
                            {icon}
                        </div>
                        <div>
                            {props.subject || undefinedValueText()}
                        </div>
                    </div>
                    <div className="flex h-20 text-sm">
                    {
                        props.typeID === 1 && (
                            <>
                                <div className="flex flex-col w-1/2 justify-center">
                                    <span className="block pt-1 pb-1">
                                        Sending email
                                    </span>
                                    <span className="block pt-1 pb-1 text-xs">
                                        {props.replyAddress || undefinedValueText()}
                                    </span>
                                </div>
                                <div className="flex flex-col w-1/2 justify-center">
                                    <span className="block pt-1 pb-1">
                                        Reply to email
                                    </span>
                                    <span className="block pt-1 pb-1 text-xs break-all">
                                        {props.fromAddress || undefinedValueText()}
                                    </span>
                                </div>
                                <hr />
                            </>
                        )
                    }
                    </div>
                    <div className="flex flex-row mt-auto">
                        <div className="flex w-1/2 justify-start items-center">
                            <Reach num={reach} journeyStepContactIDs={props.contactIDs} disabled={editMode.editing} />
                        </div>
                        <div className="flex w-1/2 justify-end">
                            <Button
                                backgroundColorOverride={props.statusID}
                                disabled={true}
                                interior={getCampaignStatus(props.statusID)} />
                        </div>
                    </div>
                </div>
                <div className={`min-w-80 w-80 max-w-80 
                                min-h-80 h-80 max-h-80
                                hidden
                                xl:flex
                                bg-white
                                border-l border-gray-200
                                flex items-center justify-center bg-no-repeat bg-contain bg-center`} style={props.topImage ? {"backgroundImage": `url(${props.topImage}`}: {}}>
                    {!props.topImage && "No template selected"}
                </div>
            </div>
    )
};

export default NurturingJourneyManagerCard;
