import { XIcon } from "@heroicons/react/outline";

export default function ModalHeader(props) {
  return (<div className="bg-gray-100 rounded-tl-md rounded-tr-md md:px-4 md:py-6 py-6 flex items-center justify-between">
    {props.children ?? (<div></div>)}
    <button
      onClick={props.onModalCloseClickFn}
      className="focus:outline-none"
    >
      <XIcon className="text-primary-color-dark w-6 h-6" />
    </button>
  </div>)
}