import { useState, useEffect } from "react";
import StatusButton from "../components/NurturingModal/StatusButton";
import ProgressCards from "../components/NurturingModal/ProgressCards";
import Header from "./../components/NurturingModal/Header"
import TemplateDesign from "./../components/NurturingModal/TemplateDesign";
import Scheduler from "./../components/NurturingModal/Scheduler";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {
  getTemplates, resetCampaign
} from "./../features/campaign";
import { getListHeaders, getList } from "../features/contactList";
import { addError, removeErrors, updateStep, setModalStep, addStep, resetAddingModalDraftData, setContactListModalOpen, setAddingStep, setEditingStep} from "../features/nurturingJourneyManager";
import ModalHeader from "../components/ModalHeader";

function NurturingModal({updateJourneyStep}) {
  const dispatch = useAppDispatch();
  const { dataSource, newListSaved } = useSelector((state) => state.contactList.flags);
  const { filter, editMode } = useSelector((state) => state.nurturingJourneyManager);
  if (editMode?.editingStep) {
    var [validatedSteps, setValidatedSteps] = useState([0,1,2,3,4]);
  } else {
    var [validatedSteps, setValidatedSteps] = useState([]);
  }  

  useEffect(() => {
    dispatch(getListHeaders({ dataSource }));
    dispatch(getList({ dataSource }));
    dispatch(getTemplates());
  }, [dataSource, newListSaved]);

  const changeNurturingStep = (step) => {
    dispatch(setModalStep(step))
  }

   useEffect(() => {
    updateJourneyStep({})
  },[])

  const validateForm = (stepName, stepTypeID, subject, replyToAddress, pretext, fromAddress, templateID, waitingDays) => {
    let isValid = true
    /*
    this section is a trade-off between readability and "correctness" - 
    I would prefer to use an escape hatch variable than read a 
    massively nested if/else structure
    */
    if (editMode.modalStep === 0) {
      var specialCharacters = /[^A-Za-z 0-9]/g;
      dispatch(removeErrors());
      if (!stepName || !stepTypeID) {
        dispatch(addError("stepType"))
        isValid = false
      }
      if (isValid && specialCharacters.test(stepName)) {
        dispatch(addError("stepNameCharacters"))
        isValid = false
      }
      if (isValid && stepTypeID === 1) { //email
        if (!subject || !replyToAddress || !fromAddress) {
          dispatch(addError("email"))
          isValid = false
        }
        if (specialCharacters.test(subject)) {
          dispatch(addError("subjectCharacters"))
          isValid = false
        }
        if (specialCharacters.test(stepName)) {
          dispatch(addError("stepNameCharacters"))
          isValid = false
        } 
        if (specialCharacters.test(pretext)) {
          dispatch(addError("pretextCharacters"))
          isValid = false
        }
      }
      if (isValid && stepTypeID === 2) { //SMS
        if (!subject) {
          dispatch(addError("smsSubject"))
          isValid = false
        }
        
        if (isValid && specialCharacters.test(pretext)) {
          dispatch(addError("pretextCharacters"))
          isValid = false
        }
        if (isValid && specialCharacters.test(subject)) {
          dispatch(addError("subjectCharacters"))
          isValid = false
        } 
      }
    }
    if (editMode.modalStep === 1) {
      if (templateID === undefined) {
        dispatch(addError("template"))
        isValid = false
      }
    }
    if (editMode.modalStep === 2) {
      if (waitingDays === undefined || waitingDays === 0) {
        dispatch(addError("waitingDays"))
        isValid = false
      }
    }
    let steps = [...validatedSteps];
    steps.push(editMode.modalStep)
    setValidatedSteps(steps)
    return true
  }
  
  return (
    <>
      <div id="popup" className="z-50 fixed w-full flex justify-center inset-0">
        <div className="w-full h-full bg-gray-400 opacity-60 z-0 absolute inset-0" />
        <div className="mx-auto container">
          <div className="flex items-center justify-center h-full w-full">
            <div className="bg-white rounded-md shadow fixed overflow-y-auto w-10/12 md:w-10/12 lg:w-10/12 2xl:w-10/12 overflow-visible h-modal">
              <ModalHeader onModalCloseClickFn={
                () => {
                  dispatch(setEditingStep(false));
                  dispatch(setAddingStep(false));
                  dispatch(removeErrors());
                  dispatch(setModalStep(0));
                }}>
                <StatusButton status={
                  (() => {
                    return newListSaved
                      ? 'draft'
                      : editMode.addingStep
                        ? editMode.addingStepData?.status_name?.toLowerCase() ?? ""
                        : editMode.editingStepData?.status_name?.toLowerCase() ?? ""
                })()}/>
                <ProgressCards
                  modalStep={editMode.modalStep}
                  changeNurturingStep={changeNurturingStep}
                  validatedSteps={validatedSteps}
                />
              </ModalHeader>
              <div className="bg-white md:px-4 md:py-8 py-8 flex flex-col min-h-modal-content">
                <div className="px-4 md:px-10 pt-2 md:pt-4 md:pb-4 pb-7">
                  {editMode.modalStep === 0 && <Header />}
                  {editMode.modalStep === 1 && <TemplateDesign validated={validatedSteps} setValidated={setValidatedSteps}/>}
                  {editMode.modalStep === 2 && <Scheduler />}
                  <div className="flex items-center justify-between mt-9 mb-4">
                    <div>
                      <button
                        onClick={() => {
                          dispatch(removeErrors());
                          dispatch(setModalStep(0));
                          dispatch(setAddingStep(false))
                          dispatch(setEditingStep(false))
                        }}
                        className="px-6 py-3 bg-ofg-purple hover:bg-gray-500 shadow rounded text-sm text-white mr-3"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          const addingStep = editMode.addingStep && editMode.modalStep === 0
                          const stepData = addingStep
                            ? editMode.addingStepData
                            : editMode.editingStepData
                          const stepID = stepData.id ?? stepData.step_id
                          const stepName = stepData.step_name
                          const stepTypeID = stepData.type_id
                          const subject = stepData.subject
                          const replyToAddress = stepData.reply_to_address
                          const pretext = stepData.pretext
                          const fromAddress = stepData.from_address
                          const templateID = stepData.template_id
                          const waitingDays = stepData.waiting_days
                          if (validateForm(stepName, stepTypeID, subject, replyToAddress, pretext, fromAddress, templateID, waitingDays)) {
                            if (editMode.modalStep === 0) {
                              updateJourneyStep(
                                {
                                  subject,
                                  step_name: stepName,
                                  step_id : stepID,
                                  status_id: 2,
                                  status_name: "Draft",
                                  reply_to_address: replyToAddress,
                                  from_address: fromAddress
                                }
                              )
                              if (editMode.editingStep) {
                                dispatch(
                                  updateStep(
                                    {
                                      subject,
                                      pretext, 
                                      step_name: stepName,
                                      step_id: stepID,
                                      status_id: 2,
                                      status_name: "Draft",
                                      reply_to_address: replyToAddress,
                                      from_address: fromAddress
                                    }
                                  )
                                )
                              } else {
                                dispatch(
                                  addStep(
                                    {
                                      step_name: stepName,
                                      group_id: filter.groupId,
                                      subject: subject,
                                      type_id: stepTypeID,
                                      from_address: fromAddress,
                                      reply_to_address: replyToAddress,
                                      created_by: JSON.parse(localStorage.getItem("user")).user_id
                                    }
                                  )
                                )
                              }
                            }
                            if (editMode.modalStep === 1) {
                              updateJourneyStep(
                                {
                                  step_id: stepID,
                                  template_id: templateID
                                }
                              )
                              dispatch(
                                updateStep(
                                  {
                                    step_id: stepID,
                                    template_id: templateID
                                  }
                                )
                              )
                            }
                            if (editMode.modalStep === 2) {
                              updateJourneyStep(
                                {
                                  step_id: stepID,
                                  wait_days: waitingDays,
                                  status_id: 2,
                                }
                              )
                              dispatch(
                                updateStep(
                                  {
                                    step_id: stepID,
                                    wait_days: waitingDays,
                                    status_id: 2,
                                  }
                                )
                              )
                            }
                            dispatch(removeErrors())
                            dispatch(setModalStep(0))
                            dispatch(resetCampaign())
                            dispatch(setAddingStep(false))
                            dispatch(setEditingStep(false))
                            dispatch(resetAddingModalDraftData())
                          }
                        }}
                        className="px-6 py-3 bg-primary-color-dark hover:bg-gray-500 shadow rounded text-sm text-white ml-0 md:ml-2"
                      >
                        Save as Draft
                      </button>
                    </div>
                    <button
                      onClick={
                        () => {
                          const addingStep = editMode.addingStep && editMode.modalStep === 0
                          const stepData = addingStep
                            ? editMode.addingStepData
                            : editMode.editingStepData
                          const stepID = stepData.step_id ?? stepData.id
                          const stepName = stepData.step_name
                          const stepTypeID = stepData.type_id
                          const subject = stepData.subject
                          const replyToAddress = stepData.reply_to_address
                          const pretext = stepData.pretext
                          const fromAddress = stepData.from_address
                          const templateID = stepData.template_id
                          const waitingDays = stepData.waiting_days
                          if (validateForm(stepName, stepTypeID, subject, replyToAddress, pretext, fromAddress, templateID, waitingDays)) {
                            if (editMode.modalStep === 0) {
                              updateJourneyStep(
                                {
                                  subject,
                                  step_name: stepName,
                                  step_id : stepID,
                                  reply_to_address: replyToAddress,
                                  from_address: fromAddress
                                }
                              )
                              if (editMode.editingStep) {
                                dispatch(
                                  updateStep(
                                    {
                                      subject,
                                      pretext, 
                                      step_name: stepName,
                                      step_id: stepID,
                                      reply_to_address: replyToAddress,
                                      from_address: fromAddress
                                    }
                                  )
                                )
                              } else {
                                dispatch(
                                  addStep(
                                    {
                                      step_name: stepName,
                                      group_id: filter.groupId,
                                      subject: subject,
                                      type_id: stepTypeID,
                                      from_address: fromAddress,
                                      reply_to_address: replyToAddress,
                                      created_by: JSON.parse(localStorage.getItem("user")).user_id
                                    }
                                  )
                                )
                              }
                            }
                            if (editMode.modalStep === 1) {
                              updateJourneyStep(
                                {
                                  step_id: stepID,
                                  template_id: templateID
                                }
                              )
                              dispatch(
                                updateStep(
                                  {
                                    step_id: stepID,
                                    template_id: templateID
                                  }
                                )
                              )
                            }
                            if (editMode.modalStep === 2) {
                              updateJourneyStep(
                                {
                                  step_id: stepID,
                                  wait_days: waitingDays,
                                  status_id: 1,
                                }
                              )
                              dispatch(
                                updateStep(
                                  {
                                    step_id: stepID,
                                    wait_days: waitingDays,
                                    status_id: 1,
                                  }
                                )
                              )
                              dispatch(removeErrors());
                              dispatch(setModalStep(0));
                              dispatch(setAddingStep(false))
                              dispatch(setEditingStep(false))
                              dispatch(setContactListModalOpen(false))
                            } else {
                              dispatch(setModalStep(editMode.modalStep + 1))
                            }
                          }
                        }
                      }
                      className="px-6 py-3 bg-primary-color-dark shadow rounded text-sm text-white"
                    >
                      {editMode.modalStep === 2 ? "Submit" : "Save and Continue"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NurturingModal;
