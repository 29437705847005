function StatusButton({ status }) {
  let button;
  switch (status) {
    case "active":
      button = (
        <button className="mr-2 my-2 bg-primary-color-dark transition duration-150 ease-in-out focus:outline-none rounded text-white border border-gray-300 px-5 py-1 text-xs">
          Active
        </button>
      );
      break;
    case "draft":
      button = (
        <button className="mr-2 my-2 bg-ofg-orange transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-5 py-1 text-xs">
          Draft
        </button>
      );
      break;
    case "complete":
      button = (
        <button className="mr-2 my-2 bg-ofg-purple transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-5 py-1 text-xs">
          Complete
        </button>
      );
      break;
    case "archived": 
      button = (
        <button className="mr-2 my-2 bg-ofg-blue transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-5 py-1 text-xs">
          Archived
        </button>
      )
      break;
    case "failed": 
      button = (
        <button className="mr-2 my-2 bg-ofg-red transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-5 py-1 text-xs">
          Failed
        </button>
      )
      break;
  }
  return button
}

export default StatusButton;
