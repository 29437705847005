import { rotateTableData } from "./rotateTableData"

export default function funnelDataFormatter(rawData) {
  const data = rotateTableData(rawData)
  const seriesMappings = {
    "messages_send": "Messages Sent",
    "bounced": "Bounced",
    "email_opened": "Opened",
    "unique_clicks": "Unique Clicks",
    "unsubscribed": "Unsubscribed"
  }
  const categoriesData = rawData.map((step) => step.step_name)
  const mappedData = []
  Object.entries(data).forEach(([k, v], i) => {
    if (Object.keys(seriesMappings).includes(k)) {
      mappedData.push(
        {
          name: seriesMappings[k],
          data: v
        }
      )
    }
  })

  return {
    categories: categoriesData,
    series: mappedData
  }
}