import { useDispatch } from "react-redux"

export default function Label({text, name}) {
  return (
    <label
      className="block text-sm h-10 leading-10 text-gray-800 mr-2"
      htmlFor={name}
      >
        {text}
      </label>
  )
}