export function sortSteps(steps, sort = "step_order") {
  const filteredSteps = steps.filter(
    (v) => {
      return v.status_id !== 4;
    }
  )
  const sortedFilteredSteps = filteredSteps.sort(
    (a, b) => {
      return a.step_order - b.step_order;
    }
  )

  return sortedFilteredSteps
}