import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Dropdown from "./Dropdown";
import { 
  setJourneysListFromAPI,
  getGraphDataById,
  setSelectedId,
  setCampaignType,
  getAllRegions,
  toggleCampaignsRegionId,
  setMinDate,
  setMaxDate
} from "../features/nurturingReport";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MultiSelectDropdown from "./MultiSelectDropdown";

const NurturingReportsHeading = () => {
  const dispatch = useDispatch();
  const { journeysList, regions, graphData , typeList } = useSelector((state) => state.nurturingReport)

  useEffect(() => {
    if (!journeysList.data.length && journeysList.status === "idle") {
      dispatch(setJourneysListFromAPI());
    }
    if (!regions.data.length && regions.status === "idle") {
      dispatch(getAllRegions());
    }
  }, [journeysList.data, regions.data]);

  const journeyDropdown = journeysList.data.length
    ? journeysList.data.map((item) => {
        return { 
          text: item.group_name,
          value: item.id,
        };
      })
    : [];


  const typeDropdown = typeList.data.length
  ? typeList.data.map((item) => {
      return {
        text: item.text,
        value: item.value,
      };
    })
  : [];

    const regionDropdown = regions.data.length
    ? regions.data.map((item) => {
        return {
          text: item.display_name,
          value: JSON.stringify(item),
        };
      })
    : [];

  const minDatePickerProps = {
    label: "Start Date",
    inputFormat: "DD/MM/YYYY",
    value: graphData.minDate ? graphData.minDate : null,
    className: "text-xs",
    renderInput: (params) => <TextField {...params} />,
    onChange: (newValue) => {
      dispatch(setMinDate(moment(newValue).format("DD/MM/YYYY")));
      if (graphData.selectedId !== undefined) {
        dispatch(
          getGraphDataById(
            {
              subset: false,
              id: graphData.selectedId
            }
          )
        )
      }
    }
  }

  const maxDatePickerProps = {
    label: "End Date",
    inputFormat: "DD/MM/YYYY",
    value: graphData.maxDate ? graphData.maxDate : null,
    renderInput: (params) => <TextField {...params} />,
    onChange: (newValue) => {
      dispatch(setMaxDate(moment(newValue).format("DD/MM/YYYY")));
      if (graphData.selectedId !== undefined) {
        dispatch(
          getGraphDataById(
            {
              subset: false,
              id: graphData.selectedId
            }
          )
        )
      }
    }
  }
  const getRegionNameFromId = (id) => {
    const region = regions.data.find(
      (v) => {
        return v.id === id
      }
    )

    if (region) {
      return region.display_name
    } else {
      throw new Error("Bad region data")
    }
  }

  const buildRegionsString = (id) => {
    let str = getRegionNameFromId(id)
    if (regions.selectedRegionIds.length > 1) {
      str += ` (+${regions.selectedRegionIds.length - 1})`
    }

    return str
  }

  return (
    <div>
      <div className="bg-white py-4 w-full border-b-2 border-primary-color-dark">
        <div className="z-10 relative container flex flex-col md:flex-row items-start md:items-center">
          <div className="w-full flex items-center">
            <div className="flex flex-wrap items-center justify-end ml-6 flex-row">
              <Dropdown
                disabled={!journeysList.data.length}
                loaded={journeysList.data.length}
                placeHolder={"Journey"}
                search={true}
                overwritePlaceholder={true}
                options={journeyDropdown}
                optionOnClick={(e) => {
                  const id = e.target.dataset.value
                  dispatch(setSelectedId(id))
                  dispatch(
                    getGraphDataById(
                      {
                        id: id
                      }
                    )
                  )
                }
              }
              />
              <MultiSelectDropdown
                disabled={!regions.data.length}
                loaded={regions.data.length}
                placeHolder={regions.selectedRegionIds.length === 0 ? "Region" : `${buildRegionsString(regions.selectedRegionIds[0])}`}
                search={true}
                overwritePlaceholder={false}
                options={regionDropdown}
                checkedFn={(value) => {
                  return regions.selectedRegionIds.includes(value.id)
                }}
                optionOnToggle={(e) => {
                  const value = JSON.parse(e.target.dataset.value)
                  const regionId = value.id
                  dispatch(toggleCampaignsRegionId(regionId))
                  if (graphData.selectedId !== undefined) {
                    dispatch(
                      getGraphDataById(
                        {
                          id: graphData.selectedCampaignId
                        }
                      )
                    )
                  }
                }}
              />
              <Dropdown
                disabled={!typeList.data.length}
                loaded={typeList.data.length}
                placeHolder={"Type"}
                search={false}
                overwritePlaceholder={true}
                options={typeDropdown}
                optionOnClick={(e) => {
                  let id = e.target.dataset.value
                  if(id == 0) id = ''
                  dispatch(setCampaignType(id))
                  dispatch(
                    getGraphDataById(
                      {
                        id: id
                      }
                    )
                  )
                }
              }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NurturingReportsHeading;
