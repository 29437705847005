import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNextOccurrenceAfterDateTime, parseDateStringToDate } from "../utils/dates";
import  api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";
import moment from "moment";
import addNextOccurrences from "../utils/addNextOccurrences";

export const engagementHubManagerInitialState = {
  awaiting: false,
  initialLoadDone: false,
  data: [],
  filter: {
    search_name: "",
    created_date: undefined,
    start_date: undefined,
    type_id: undefined, //Email/SMS
    status_id: [1],//Failed, Active, Draft, etc
    order_by: "next occurrence"//"next occurrence"/"oldest"/"status",
  },
  modalVisible: false, 
  flags: { editCampaign: false },
  editCampaignId: "",
  editCampaignStatus: "",
};

const buildQueryString = (params) => {
  let queryString = ""
  let operator =  "?";
  params && Object.keys(params).forEach((key, i) => {
      if (params[key] !== undefined && params[key].toString() !== "") {
          queryString += `${operator}${key}=${params[key]}`
          operator = "&"
      }
  })
  if (params && params.status_id && params?.status_id.length === 0) {
    queryString += (queryString.length === 0 ? "?" : "&") + "status_id=1" //returns Active by default
  }

  return queryString
}

export function order(data, orderBy = "next occurrence") {
  let goodSortedData = []
  const badSortedData = []
  if (orderBy === "status") {
    goodSortedData = data.map(v => v)
    goodSortedData.sort((a, b) => {
      return a.status_name.localeCompare(b.status_name)
    });
  }
  
  if (orderBy === "next occurrence") {
    data.forEach(
      (v) => {
        if (v.nextOccurrenceDate) {
          goodSortedData.push(v)
        } else {
          badSortedData.push(v)
        }
      }
    )
    goodSortedData.sort((a, b) => {
      const aDate = moment(a.nextOccurrenceDate).unix()
      const bDate = moment(b.nextOccurrenceDate).unix()
      return aDate - bDate
    });
  }
  
  if (orderBy === "oldest first") {
    data.forEach(
      (v) => {
        if (v.dates.start_date) {
          goodSortedData.push(v)
        } else {
          badSortedData.push(v)
        }
      }
    )
    goodSortedData.sort((a, b) => {
      const aDate = moment(a.dates.start_date, "YYYY-MM-DD").unix()
      const bDate = moment(b.dates.start_date, "YYYY-MM-DD").unix() 
      return aDate - bDate
    });
  }
  return goodSortedData.concat(badSortedData)
}

export const setDataFromAPI = createAsyncThunk(
  "setDataFromAPI",
  async (params, thunkAPI) => {
    let payload = {
      data: []
    }
    const state = thunkAPI.getState()
    if (params && params.status_id && params.status_id.length > 0) {
      const queryString = buildQueryString(params)
      const response = await SecureFetch(`${api_server}/campaign${queryString}`);
      payload = await response.json()
    }
    return {
      data: addNextOccurrences(payload.data) ?? [],
      filter: {
        search_name: (params && params.hasOwnProperty("search_name")) ? params.search_name : state.engagementHubManager.filter.search_name,
        created_date: (params && params.hasOwnProperty("created_date")) ? params.created_date : state.engagementHubManager.filter.created_date,
        start_date: (params && params.hasOwnProperty("start_date")) ? params.start_date : state.engagementHubManager.filter.start_date,
        type_id: (params && params.hasOwnProperty("type_id")) ? params.type_id : state.engagementHubManager.filter.type_id,
        status_id: (params && params.hasOwnProperty("status_id")) ? params.status_id : state.engagementHubManager.filter.status_id,
        order_by: (params && params.hasOwnProperty("order_by")) ? params.order_by : state.engagementHubManager.filter.order_by
      }
    }
  }
)

export const engagementHubManager = createSlice({
  name: "engagementHubManager",
  initialState: engagementHubManagerInitialState,
  reducers: {
    updateEngagementHubManager: (state, action) => {
      state.flags = {
        ...state.flags,
        ...action.payload,
      };
    },
    setData: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        filter: {
          ...state.filter,
          ...action.payload.filter,
        },
      };
    },
    toggleModal: (state, action) => {
      return {
        ...state,
        modalVisible: action.payload,
      };
    },
    orderBy: (state, action) => {
      return {
        ...state,
        data: order(
          state.data,
          action.payload
        ),
        filter: {
          ...state.filter,
          order_by: action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setDataFromAPI.pending, (state) => {
        if (!state.awaiting) {
          state.awaiting = true;
        }
      })
      .addCase(setDataFromAPI.fulfilled, (state, action) => {
        if (state.awaiting) {
          state.awaiting = false;
        }
        if (!state.initialLoadDone) {
          state.initialLoadDone = true;
        }
        state.data = order(
          action.payload.data,
          action.payload.filter.order_by ?? state.filter.order_by
        );
        state.filter = {
          ...state.filter,
          ...action.payload.filter,
        };
      })
      .addCase(setDataFromAPI.rejected, (state) => {
        if (state.awaiting) {
          state.awaiting = false;
        }
        if (!state.initialLoadDone) {
          state.initialLoadDone = true;
        }
        state.data = [];
      });
  },
});
export const {
  setData,
  orderBy,
  toggleModal,
  setInitialLoadDone,
  updateEngagementHubManager,
} = engagementHubManager.actions;

export const getData = (state) => state;

export default engagementHubManager.reducer;
