import LeadAgGrid from "../components/CampaignModal/campaignModalMain/sourceList/LeadAgGrid";
import Dropdown from "../components/Dropdown";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import Alert from "../components/Alert";
import { UserAddIcon, BackspaceIcon, XIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaign } from "../features/campaign";
import {
  getListHeaders,
  getList,
  updateList,
  getCampaignCount,
  changeList,
  resetList,
} from "./../features/contactList";
import CheckAuth from "../components/CheckAuth";
import { setDataFromAPI } from "../features/engagementHubManager";
import Heading1 from "../components/Heading1";
import ConfirmModal from "./ConfirmModal";
import Switch from "react-switch";

function DataList() {
  const { campaignId, errors } = useSelector((state) => state.campaign.flags);
  const {
    dataSource,
    listType,
    sourceList,
    statusId,
    createNewList,
    newListName,
    newListSaved,
    listSelection,
    disabled
  } = useSelector((state) => state.contactList.flags);
  const {
    getListHeadersData,
    getListData,
    getCampaignCountData,
    changeListData,
  } = useSelector((state) => state.contactList);
  const { data } = useSelector((state) => state.engagementHubManager);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showUnarchiveButton, setShowUnarchiveButton ] = useState(false)
  const listTypeDropdown = ["Fixed", "Dynamic"];
  const listSelectionDropdown = ["Pre-Selected List", "List By Campaign"];

  useEffect(
    () => {
      document.title = "Engagement Hub - Data List"
      dispatch(
        updateList({
          dataSource: "Lead Data"
        })
      )
    },
    []
  )

  useEffect(() => {
    getListHeaders;
  }, [showModal]);

  useEffect(() => {
    if (dataSource) {
      dispatch(getListHeaders({ dataSource }))
      dispatch(getList({ dataSource }))
    }
    dispatch(setDataFromAPI())
    dispatch(
      updateList({
        listSelection: "",
        sourceList: "",
        newListName: "",
      })
    )
    dispatch(
      updateCampaign({
        campaignId: "",
      })
    )
  }, [dataSource]);

  useEffect(() => {
    sourceList && dispatch(getCampaignCount({ sourceList }));
    dataSource && dispatch(getListHeaders({ dataSource }));
  }, [sourceList]);

  useEffect(() => {
    campaignId && dispatch(getList({ campaignId }));
    campaignId && dispatch(getCampaignCount({ campaignId }));
  }, [campaignId]);

  const sourceListDropdown = getListHeadersData.data.data
    ? getListHeadersData.data.data
        .filter((item) => (showArchived ? item : item.status_id != 4))
        .map((item) => {
          return {
            text: item.list_name,
            value: item.id,
            status: item.status_id,
          };
        })
    : [];

  const campaignDropdown = data
    ? data.map((item) => {
        return {
          text: item.campaign_name,
          value: item.id,
        };
      })
    : [];

  const sourceListInfo = getListHeadersData.data.data
    ? getListHeadersData.data.data.filter((list) => list.id == sourceList)
    : "";

  const campaignListInfo = data
    ? data.filter((list) => list.id == campaignId)
    : "";

  const campaignNameArray = data.map((campaign) =>
    campaign.campaign_name.toLowerCase()
  );

  function getCursor(disabled) {
    if (disabled) {
      return "cursor-default";
    } else {
      return "cursor-pointer";
    }
  }

  let cursor = getCursor(disabled)

  return (
    <>
      <CheckAuth />
      <div>
        {!createNewList || newListSaved ? (
          <div className="bg-white py-2 w-full">
            <div className="relative container px-6 ml-5 mx-auto flex flex-col md:flex-row items-start md:items-center">
              {(dataSource && getListData.data.data ) && (
                <Dropdown
                disabled={getListData ? false : true}  
                placeHolder={newListSaved ? listType : "List Type"}
                  overwritePlaceholder={true}
                  options={listSelectionDropdown}
                  optionOnClick={(e) => {
                    setShowUnarchiveButton(false)
                    dispatch(
                      updateList({
                        listSelection: e.target.dataset.value,
                      })
                    );
                  }}
                />
              )}
              {(listSelection === "Pre-Selected List") | newListSaved ? (
                <Dropdown
                  placeHolder={
                    newListSaved
                      ? newListName
                      : sourceListInfo[0]?.list_name
                      ? sourceListInfo[0]?.list_name
                      : "List"
                  }
                  overwritePlaceholder={true}
                  options={sourceListDropdown}
                  search={true}
                  optionOnClick={(e) => {
                    setShowUnarchiveButton(true)
                    dispatch(
                      updateCampaign({
                        campaignId: "",
                      })
                    );
                    dispatch(
                      updateList({
                        sourceList: e.target.dataset.value,
                        statusId: e.target.dataset.status,
                      })
                    );
                  }}
                />
              ) : null}
              {listSelection === "List By Campaign" && (
                <Dropdown
                  placeHolder={
                    campaignListInfo[0]?.list_name
                      ? campaignListInfo[0]?.list_name
                      : "Campaign"
                  }
                  overwritePlaceholder={true}
                  options={campaignDropdown}
                  search={true}
                  optionOnClick={(e) => {
                    setShowUnarchiveButton(true)
                    dispatch(
                      updateCampaign({
                        campaignId: e.target.dataset.value,
                      })
                    );
                    dispatch(
                      updateList({
                        sourceList: "",
                      })
                    );
                  }}
                />
              )}
              <Button
                labelText="Create new list"
                name="createNewList"
                onClick={() => {
                  dispatch(
                    updateList({
                      createNewList: true,
                      newListSaved: false,
                      sourceList: "433",
                      dataSource: "Lead Data",
                    })
                  );
                }}
                interior={<UserAddIcon className="text-white w-6 h-6" />}
                error={
                  errors?.includes("currentList") &&
                  (!dataSource || !sourceList)
                }
                flat={true}
              />
              {showUnarchiveButton && !createNewList && !newListSaved && (
                <div className="ml-4">
                  <Button
                    labelText={
                      Number(statusId) === 1 ? "Archive List" : "Unarchive List"
                    }
                    name={"deleteList"}
                    onClick={() => setShowModal(true)}
                    interior={
                      changeListData.status === "Loading" ? (
                        <div className="w-6 h-6 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent ml-3 mr-3"></div>
                      ) : (
                        <XIcon className="text-white w-6 h-6" />
                      )
                    }
                    flat={true}
                  ></Button>
                </div>
              )}
              {listSelection && dataSource ? (
                <div className="flex ml-8">
                  <Switch
                    onChange={() => setShowArchived(!showArchived)}
                    checked={showArchived}
                  />
                  <span className="ml-2">Show Archived Lists</span>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {createNewList && !newListSaved ? (
          <div className="bg-white py-2 w-full">
            <div className="relative container mx-auto flex flex-col md:flex-row items-start md:items-center">
              {(dataSource && getListData.data.data) ? (
                <Dropdown
                  overwritePlaceholder
                  optionOnClick={(e) => {
                    dispatch(
                      updateList({
                        listType: e.target.dataset.value,
                      })
                    );
                  }}
                  placeHolder="List Type"
                  options={listTypeDropdown}
                  name={"listType"}
                />
              ) : (
                <div className={`${cursor} mr-5 bg-white dark:bg-gray-800 flex items-center justify-between border rounded border-gray-300 dark:border-gray-700 w-40 cursor-pointer h-12`}>
                  <div>
                    <div className={`${cursor} w-6 h-6 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent ml-3 mr-3`}></div>
                  </div>
                </div>
              )}
              <TextBox
                name="listName"
                onChange={(e) => {
                  dispatch(
                    updateList({
                      newListName: e.target.value,
                    })
                  );
                }}
                type="name"
                aria-labelledby="Campaign name"
                placeholder="Name"
                marginTop={false}
                marginBottom={false}
              />

              <Button
                name="selectExistingList"
                labelText="Select Existing List"
                onClick={() => {
                  setShowUnarchiveButton(false)
                  dispatch(
                    updateList({
                      createNewList: false,
                    })
                  );
                }}
                interior={<BackspaceIcon className="text-white w-8 h-6" />}
                flat={true}
                error={
                  errors.includes("newList") && (!dataSource || !newListName)
                }
              />
            </div>
          </div>
        ) : null}
        {showModal && (
          <ConfirmModal
            show={showModal}
            setShowModal={setShowModal}
            text={
              statusId == 1
                ? "Are you sure you want to archive this list?"
                : "Are you sure you want to unarchive this list?"
            }
            handleOnClickModalButton={async (e) => {
              dispatch(changeList({statusId:statusId,sourceList:sourceList}));
              dispatch(resetList());
              dispatch(setDataFromAPI());
              dispatch(
                updateList({
                  dataSource: "",
                  listSelection: "",
                  sourceList: "",
                  newListName: "",
                  data: null
                })
              );
              dispatch(
                updateCampaign({
                  campaignId: "",
                })
              );
              if (statusId == 1) alert('The selected list has been archived.');
              if (statusId != 1) alert('The selected list has been unarchived.');
            }}
          />
        )}
      </div>
      <div className="py-2 lg:px-2 flex-auto flex flex-col">
        {newListSaved && <Alert />}
        <div className="ml-10 pt-2">
          <Heading1 className="text-4xl ml-5 font-semibold  text-ofg-purple justify-center">
            List Manager
          </Heading1>
        </div>
        <LeadAgGrid
          campaignNameArray={campaignNameArray}
          gridData={getListData.data}
          gridFilters={getListData.data.queryFilters}
          count={getCampaignCountData.data.data}
        />
      </div>
    </>
  );
}

export default DataList;
