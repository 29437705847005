import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircularProgress({ value, text, data, title, isRed = false }) {
  const color = isRed ? "#FF4F45" : "#00946E"
  return (
    <div className="flex content-center w-full flex-col bg-white p-4 text-center first:rounded-t-xl last:rounded-b-xl">
      <h1 className="font-medium leading-tight text-l mb-3">{title}</h1>
      <div className="flex w-32 h-32 m-auto">
        <CircularProgressbar
          value={value}
          text={`${text}%`}
          styles={{
            path: {
              stroke: color,
              strokeLinecap: "butt",
            },
            trail: {
              stroke: "#D0D0CE",
              strokeLinecap: "butt",
            },
            text: {
              fill: "#000000",
              fontSize: "16px",
              fontWeight: "800",
              transform: "translate(0px, 0px)"
            },
            background: {
              fill: "#EFF6FF",
            },
          }}
        />
      </div>
      {data && (
        <div className="pt-4 w-full min-h-1/2 text-xs font-normal rounded-xl">
          <ul className="text-sm">
            {data.map((element, index) => {
              return (
                  <li key={`text-${index}`}>
                    {element}
                  </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CircularProgress;
