import { useEffect, useState } from "react";
import api_server from "../axios/baseURL";
import axios from 'axios';
import { getUnsubscribeList } from "../features/unsubscribeList";
import { useDispatch, useSelector } from "react-redux";
import { Buffer } from 'buffer';

function handleSubmit (setTitle, setText) {
  let options = useSelector((state) => state.unsubscribeList);
  if (options.getUnsubscribeList.data.options) { 
    options = options.getUnsubscribeList.data.options;
    return event => {
      event.preventDefault();
      // Get crypto data
      let data     = {};
      data.options = [];
      let url      = window.location.href.split('?');
      let values   = url[1].split('&');
      values.forEach((value) => {
        let v = value.split('=')
        let va = v[0]
        data[va] = v[1]
      });
      // Get options
      options.forEach((value) => { 
        data.options.push({
          "id"          :value.id,
          "display_name":value.display_name,
          "selected"    :document.getElementById(value.display_name).checked
        });
      });
      // Send to BE
      var config = {
        method: 'post',
        url: api_server+"/unsubscribe",
        headers: { 
          'Content-Type': 'application/json' 
        },
        data: JSON.stringify(data),
        body: JSON.stringify(data)
      };
      axios(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.error(error);
          return error.response.data;
        });
      // Update UI
      setTitle("Completed");
      setText("You have been unsubscribed from your selected platforms.");
      document.getElementById("submit").remove();
      document.getElementById("mainForm").remove();
    }
  }
}

const OptionData = () => {
  const data = useSelector((state) => state.unsubscribeList);
  if (data.getUnsubscribeList.data.options) { 
    let values = data.getUnsubscribeList.data.options;
    return (<>
      {values.map((value) => (
        <div className="flex items-center mb-4" key={value.id}>
          <input id={value.display_name} name={value.display_name} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
          <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value.display_name}</label>
        </div>
      ))}
    </>)
  }
}

const RenderData = () => {
  let url    = window.location.href.split('?');
  let values = url[1].split('&');
  if (values.length == 1) {
    redirect(values[0]);
    return (<></>);
  } else {
    if (values[3]) {
      let render = values[3].split('=')
      render = Buffer.from(decodeURIComponent(render[1]), "base64").toString();
      if (render) {
        return (<><img src={render} className="mb-4 drop-shadow-md border border-grey-800 rounded"></img></>)
      }
    }
  }
}

const redirect = (code) => {
  let shortcode = code.split('=');
  var config = {
    method: 'get',
    url: api_server+"/unsubscribe/"+shortcode[1],
    headers: { 
      'Content-Type': 'application/json' 
    }
  };
  axios(config)
    .then(function (response) {
      if (
        response.data && 
        response.data.options && 
        response.data.options[0] && 
        response.data.options[0].link
      ) {
        window.location.href = response.data.options[0].link;
      } else {
        window.open("about:blank", "_self");
        window.close();
      }
    })
    .catch(function (error) {
      alert('Invalid Unsubscribe Code');
      console.error(error);
      return error.response.data;
    });
}

function UnsubscribeForm() {
  const dispatch          = useDispatch();
  const [text, setText]   = useState("You can unsubscribe from all our commercial and marketing related email/SMS using the options below.");
  const [title, setTitle] = useState("Unsubscribe");
  useEffect(()=>{
    dispatch(getUnsubscribeList());
    let element = document.getElementById('layout');
    if (element) element.remove();
    let url    = window.location.href.split('?');
    let values = url[1].split('&');
    if (values.length > 3) {
      document.getElementById('unsubscribe').classList.remove("invisible");
    }
  }, []);
  return ( 
    <>
        <div id="unsubscribe" className="bg-slate-300 invisible ">
          <div className="flex items-center justify-center h-screen">
            <div className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div className="p-5">
                <RenderData/>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{text}</p>
                <form onSubmit={handleSubmit(setTitle, setText)} id="mainForm">
                  <OptionData/>
                  <button type="submit" id="submit" className="w-full disabled:opacity-25 text-white focus:outline-none bg-gradient-to-r from-blue-800 to-blue-400 rounded-md py-2 px-3" >Continue</button>
                </form>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default UnsubscribeForm;