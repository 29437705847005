import Error from "./Error"
import Label from "./Label"

export default function TextBox({type = "text", error, errorMessage="* Please fill in all required fields", placeholder, labelText, labelledBy, onChange, onKeyUp, reference, marginBalancing = false, name, icon, initialValue, max, min, disabled, required = false}) {
  return (
    <>
      {icon && icon}
      {labelText && <Label text={labelText} for={name} />}
      <input
        className={`w-64 ${icon ? " p-2 pl-10" : " p-2"}${!labelText && marginBalancing ? " mt-10 " : " "}h-12 border rounded border-gray-200 focus:outline-none focus:border-gray-600 text-sm leading-none mr-2 text-gray-800`}
        onChange={onChange}
        onKeyUp={onKeyUp}
        id={name}
        tabIndex="0"
        disabled={disabled}
        defaultValue={initialValue ?? ""}
        aria-labelledby={labelledBy}
        placeholder={placeholder}
        type={type}
        ref={reference}
        max={max}
        min={min}
        required={required}
      />
      <Error
        error={error}
        errorMessage={errorMessage}/>
    </>
  )
}