import Heading1 from "../components/Heading1";
import CheckAuth from "../components/CheckAuth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getOptionsList } from "../features/optionList";
import SecureFetch from "../components/SecureFetch";
import api_server from "../axios/baseURL";

const SelectData = (header) => {
  const data = useSelector((state) => state.optionList);
  if (data.getOptionsList.data.options !== undefined) {
    let group = data.getOptionsList.data.options.group.find(o => o.display_description === header.value);
    let values = [];
    for (var i=0; i < data.getOptionsList.data.options.list.length; i++) {
      if (data.getOptionsList.data.options.list[i].group_id === group.id) {
        values.push(data.getOptionsList.data.options.list[i]);
      }
    }
    return (<>
      <select name={header.value.replace(/\s/g, '').toLowerCase()} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
        <option value="noneselected">None Selected</option>
        {values.map((value) => (
          <option value={value.id}>{value.display_name}</option>
        ))}
      </select><br></br>
    </>)
  }
}

async function handleSubmit(event) {
  event.preventDefault();
  document.getElementById("submit").disabled = true;
  let data = {
    "data": {
      "lead_details": {
        "first_name": event.currentTarget.elements.firstName.value,
        "last_name": event.currentTarget.elements.lastName.value,
        "date_of_birth": event.currentTarget.elements.dateOfBirth.value,
        "gender_id": parseInt(event.currentTarget.elements.gender.value),
        "region_id": parseInt(event.currentTarget.elements.region.value),
        "ethnic_group_id": parseInt(event.currentTarget.elements.ethnicgroup.value),
        "marital_status_id": parseInt(event.currentTarget.elements.relationshipstatus.value),
        "sexuality_id": parseInt(event.currentTarget.elements.sexualorientation.value),
        "religion_id": parseInt(event.currentTarget.elements.religion.value),
        "occupation" : {
          "job_sector_id": parseInt(event.currentTarget.elements.jobsectors.value),
          "job_type_id": parseInt(event.currentTarget.elements.jobtype.value)
        },
        "phone_number": [
          {
            "type_id": 440,
            "primary": 1,
            "value": event.currentTarget.elements.phoneNumber.value
          }
        ],
        "email_address": [
          {
            "type_id": 437,
            "primary": 1,
            "value": event.currentTarget.elements.emailAddress.value
          }
        ],
        "addresses": [
          {
            "type_id": parseInt(event.currentTarget.elements.addresstype.value),
            "primary": 1,
            "address_1": event.currentTarget.elements.address1.value,
            "address_2": event.currentTarget.elements.address2.value,
            "address_3": event.currentTarget.elements.address3.value,
            "address_4": event.currentTarget.elements.address4.value,
            "address_5": event.currentTarget.elements.address5.value,
            "postcode": event.currentTarget.elements.postcode.value,
            "city": event.currentTarget.elements.city.value,
            "county": event.currentTarget.elements.county.value,
            "country": event.currentTarget.elements.country.value
          }
        ],
      },
      "marketing_information": {
        "marketing_consent": event.currentTarget.elements.marketingConsent.checked,
        "information_pack": event.currentTarget.elements.infopack.checked,
        "lead_source_id": parseInt(event.currentTarget.elements.leadsource.value),
        "external_guid": event.currentTarget.elements.externalguid.value,
        "campaigns_ref_data": [
          {
            "campaign_ref": "Spare Room",
            "campaign_value": event.currentTarget.elements.spareroom.value
          },
          {
            "campaign_ref": "Campaign Name",
            "campaign_value": event.currentTarget.elements.campaign.value
          },
          {
            "campaign_ref": "Source Notes",
            "campaign_value": event.currentTarget.elements.sourcenotes.value
          }
        ]
      },
      "form_capture": [
        {
          "group_input_ref": event.currentTarget.elements.groupref1.value,
          "input_ref": event.currentTarget.elements.inputref1.value,
          "input_value": event.currentTarget.elements.inputvalue1.value,
        },
        {
          "group_input_ref": event.currentTarget.elements.groupref2.value,
          "input_ref": event.currentTarget.elements.inputref2.value,
          "input_value": event.currentTarget.elements.inputvalue2.value,
        },
        {
          "group_input_ref": event.currentTarget.elements.groupref3.value,
          "input_ref": event.currentTarget.elements.inputref3.value,
          "input_value": event.currentTarget.elements.inputvalue3.value,
        }
      ]
    }
  };
  data.data.lead_details = Object.fromEntries(Object.entries(data.data.lead_details).filter(([_, v]) => v != "noneselected"));
  data.data.lead_details = Object.fromEntries(Object.entries(data.data.lead_details).filter(([_, v]) => v != ""));
  const response = await SecureFetch(`${api_server}/lead`,"POST",JSON.stringify(data));
  const payload = await response.json();
  alert(JSON.stringify(payload));
  document.getElementById("submit").disabled = false;
}

function DataApi() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOptionsList());
  });
  return ( 
    <>
      <CheckAuth />
      <Heading1>Data API</Heading1>
      <form style={{marginRight: "30px"}} onSubmit={handleSubmit}>
        <div class="grid grid-cols-4 gap-4">
          <div>
            <hr></hr><br></br>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Email Address*
                </label>
              </div>
              <div class="md:w-2/3">
                <input required type="text" name="emailAddress" placeholder="example@test.com" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
              </div>
            </div>
            <hr></hr><br></br>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Title
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Title"/>
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  First Name
                </label>
              </div>
              <div class="md:w-2/3">
                <input type="text" name="firstName" placeholder="First Name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Last Name
                </label>
              </div>
              <div class="md:w-2/3">
                <input type="text" name="lastName" placeholder="Last Name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Phone Number
                </label>
              </div>
              <div class="md:w-2/3">
                <input type="number" name="phoneNumber" placeholder="0757234567" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Date of Birth
                </label>
              </div>
              <div class="md:w-2/3">
                <input type="date" name="dateOfBirth" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Gender
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Gender"/>          
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Ethnic Group
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Ethnic Group"/>         
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Relationship Status
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Relationship Status"/>         
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Sexual Orientation
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Sexual Orientation"/>         
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Religion
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Religion"/>         
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                  Region
                </label>
              </div>
              <div class="md:w-2/3">
                <SelectData value="Region"/>         
              </div>
            </div>
            <hr></hr><br></br>
          </div>
        <div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Job Type
              </label>
            </div>
            <div class="md:w-2/3">
              <SelectData value="Job Type"/>         
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Job Sector
              </label>
            </div>
            <div class="md:w-2/3">
              <SelectData value="Job Sectors"/>         
            </div>
          </div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Address 1
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="address1" placeholder="Address 1" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Address 2
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="address2" placeholder="Address 2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Address 3
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="address3" placeholder="Address 3" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Address 4
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="address4" placeholder="Address 4" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Address 5
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="address5" placeholder="Address 5" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Postcode
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="postcode" placeholder="DE55 6EE" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                City
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="city" placeholder="City" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                County
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="county" placeholder="County" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Country
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="country" placeholder="County" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              Address Type
              </label>
            </div>
            <div class="md:w-2/3">
              <SelectData value="Address Type"/>         
            </div>
          </div>
          <hr></hr><br></br>
        </div>
        <div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Marketing Consent
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="checkbox" name="marketingConsent"/><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Information Pack
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="checkbox" name="infopack"/><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Lead Source ID
              </label>
            </div>
            <div class="md:w-2/3">
              <input required type="number" name="leadsource" placeholder="1" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                External GUID
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="externalguid" placeholder="12345" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Spare Room?
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="spareroom" placeholder="Yes/No" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Campaign Name
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="campaign" placeholder="Campaign Name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                Source Notes
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="sourcenotes" placeholder="Notes" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <hr></hr><br></br>
        </div>
        <div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                group_input_ref (1)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="groupref1" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_ref (1)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputref1" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_value (1)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputvalue1" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                group_input_ref (2)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="groupref2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_ref (2)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputref2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_value (2)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputvalue2" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                group_input_ref (3)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="groupref3" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_ref (3)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputref3" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
              input_value (3)
              </label>
            </div>
            <div class="md:w-2/3">
              <input type="text" name="inputvalue3" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /><br></br>
            </div>
          </div>
          <hr></hr><br></br>
          <div class="md:flex md:items-center mb-6">
            <br></br><button id="submit" style={{width: "100%"}} className="disabled:opacity-25 text-white focus:outline-none bg-gradient-to-r from-blue-800 to-blue-400 rounded-md py-2 px-3">Add Contact</button>      
          </div>
          <small>* Required Fields</small>
        </div>
      </div>
    </form>
    </>
  );
}

export default DataApi;