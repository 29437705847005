import Heading1 from "./Heading1";

const ABComparisonHeading = () => {
  return (
    <>
      <div className="bg-white py-6 w-full">
        <div className="z-10 relative container w-11/12 flex flex-col md:flex-row">
          <div className="w-full flex flex-col ml-6">
            <div className="flex items-center flex-row text-xs w-full">
              <Heading1 paddingBottom={false}>A/B Comparison</Heading1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ABComparisonHeading;
