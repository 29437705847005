import { UserGroupIcon } from "@heroicons/react/outline";
import { useAppDispatch } from "../redux/store";
import { getContactDetailsFromContactIDs } from "../features/contactList";
import { setContactListModalOpen } from "../features/nurturingJourneyManager";
import { useState } from "react";


export default function Reach({num, journeyStepContactIDs, isDisabled}) {
  const [contactIDs] = useState(journeyStepContactIDs)
  const dispatch = useAppDispatch()
  return (
    <button className="flex items-center justify-between border border-gray-900 text-xs px-2 py-1 w-16"
    disabled={isDisabled}
    onClick={
      (e) => {
        if (num > 0 ) { 
          dispatch(setContactListModalOpen(true))
          dispatch (
            getContactDetailsFromContactIDs(contactIDs)
          )
        }
      }
    }>
        <UserGroupIcon className="h-6 w-6 text-black" />&nbsp;
        {num}
    </button>
    )
}