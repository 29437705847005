import CircularProgress from "./CircularProgress";
import SemiCircleProgress from "./SemiCircleProgress";
import LineChart from "./LineChart";
import Map from "./Map";
import Links from "./Links";

function EngagementReportMainBody({ idData, reportGraphData, reportMapData, reportLinksData, campaignName = "", nurturing }) {
  //don't divide by 0
  const messagesOpenedPercentage = idData?.sent > 0
    ? Math.round((idData?.unique_opens / idData?.sent) * 100)
    : 0
  const unsubscribedPercentage = idData?.reach > 0
    ? Math.round((idData?.global_unsubscribed / idData?.reach) * 100)
    : 0
  const sentPercentage = idData?.sent > 0
    ? Math.round((idData?.reach / idData?.sent) * 100)
    : 0
  const uniqueClicksPercentage = idData?.click > 0
    ? Math.round((idData?.unique_clicks / idData?.click) * 100)
    : 0
  const bouncedPercentage = idData?.click > 0
    ? Math.round((idData?.bounced / idData?.sent) * 100)
    : 0
  return (
    <div className="w-full flex flex-wrap">
      <div className="bg-white pb-2 h-fit w-full flex flex-wrap sm:justify-evenly justify-center">
        <div className="flex h-40 w-64 justify-center m-1 sm:m-2 rounded-xl shadow-lg">
          <SemiCircleProgress
            percentage={messagesOpenedPercentage}
            label={"Messages Opened"}
            diameter={180}
            strokeWidth={10}
            subLabel={`${idData?.unique_opens} out of ${idData?.sent}`}
            isRed={messagesOpenedPercentage < 10}
          />
        </div>
        <div className="flex h-40 w-64 justify-center m-1 sm:m-2 rounded-xl shadow-lg">
          <SemiCircleProgress
            percentage={unsubscribedPercentage}
            label={"Unsubscribed"}
            diameter={180}
            strokeWidth={10}
            subLabel={`${idData?.global_unsubscribed} out of ${idData?.reach}`}
            isRed={unsubscribedPercentage > 1.5}
          />
        </div>
        <div className="flex h-40 w-64 sm:justify-center m-1 sm:m-2 rounded-xl shadow-lg">
          <SemiCircleProgress
            percentage={bouncedPercentage}
            label={"Bounced"}
            diameter={180}
            strokeWidth={10}
            subLabel={`${idData?.bounced} out of ${idData?.reach}`}
            isRed={bouncedPercentage > 1.5}
          />
        </div>

        <div className="w-full h-fit grid grid-cols-10 auto-cols-max bg-white">
          <div className="flex flex-wrap flex-col justify-evenly lg:col-span-2 sm:col-span-4 col-span-10 bg-white m-2 shadow-xl rounded-xl">
            <CircularProgress
              title="Messages Sent"
              value={sentPercentage}
              text={sentPercentage}
              data={[
                `Messages To Send : ${idData?.reach ?? "0"}`,
                `Messages Sent : ${idData?.sent ?? "0"}`
              ]}
            />
            <CircularProgress
              title="Clicks"
              isRed={uniqueClicksPercentage < 10}
              value={uniqueClicksPercentage}
              text={uniqueClicksPercentage}
              data={[
                `Clicked Links : ${idData?.click ?? "0"}`,
                `Unique Clicks : ${idData?.unique_clicks ?? "0"}`,
              ]}
            />
          </div>
          <div className="bg-white lg:col-span-2 sm:col-span-6 col-span-10 mt-2 mb-2 rounded-xl shadow-xl flex flex-col justify-center">
            <Map data={reportMapData} campaignId={idData.id} nurturing={nurturing}/>
          </div>
          <div className="bg-white lg:col-span-6 col-span-10 m-2 rounded-xl shadow-xl flex flex-col justify-center" style={{height:'100% !important'}}>
            <LineChart data={reportGraphData} campaignName={campaignName}/>
          </div>
          <div className="md:col-span-10 col-span-10 bg-white overflow-scroll scrollbar-hide ml-2 mr-2 rounded-xl shadow-xl min-h-28">
            {reportLinksData
              ? <div className="h-full w-full flex justify-center align-center">
                  <p className="m-auto">No Links Data Available</p>
                </div>
              : <Links data={reportLinksData} />
            }
            <div className="col-span-10 sm:col-span-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EngagementReportMainBody;
