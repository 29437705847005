import { Routes, Route } from "react-router-dom";
import UnsubscribeForm from "./containers/UnsubscribeForm";
import "./index.css";

import { LicenseManager } from 'ag-grid-enterprise';
const licenseKey = "CompanyName=8bit solutions,LicensedApplication=OFG CRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-017542,ExpiryDate=22_July_2022_[v2]_MTY1ODQ0NDQwMDAwMA==7724ab981632a426882fea89456e30d8";
LicenseManager.setLicenseKey(licenseKey);

function Unsubscribe() {
  return (
    <Routes>
      <Route exact path="/unsubscribe" element={<UnsubscribeForm />} />
    </Routes>
  );
}

export default Unsubscribe;
