import {
  ChatAltIcon,
  InboxIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";

export function EngagementReportsCampaignDataPanel({data}) {
  let icon = (
    <QuestionMarkCircleIcon className="h-6 w-6 min-h-6 min-w-6 text-gray-500" />
  );
  if (data.campaignTypeID === 2) {
    icon = <ChatAltIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />;
  }
  if (data.campaignTypeID === 1) {
    icon = <InboxIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />;
  }
    
  function undefinedValueText(str = "Not set") {
    return (
        <span className={`italic text-gray-500`}>
            {str}
        </span>
    )
  }

  return Object.keys(data).length ? ( 
    <div className="w-108 relative dark:bg-gray-900 bg-white pb-0 px-4 pt-0 md:pb-0 md:px-6 md:pt-0">
      <div className="flex pt-1 pb-1 text-sm border-b-2 border-gray-90">
        <div className="flex flex-col w-1/2">
          <span className="block pt-1 pb-1">Campaign Date</span>
          <span className="block pt-1 pb-1 text-xs">
            {data.dates.start_date && data.dates.start_time
              ? `${data.dates.start_date} ${data.dates.start_time}`
              : undefinedValueText()}
          </span>
        </div>
        <div className="flex flex-col w-1/2">
          <span className="block pt-1 pb-1">Occurrence Type</span>
          <span className="block pt-1 pb-1 text-xs">
            {data.occurrence || undefinedValueText()}
          </span>
        </div>
      </div>
      <div className="pt-2 pb-2 flex items-center border-b-2 border-gray-90">
        <div className="pr-2">{icon}</div>
        <div>{data.header || undefinedValueText()}</div>
      </div>
      {data.type_id === 1 && (
        <div className="flex flex-row items-start">
          <div className="flex flex-row h-20 text-sm border-gray-90 w-1/2 pr-2">
            <div className="flex flex-col justify-center">
              <span className="block pt-1 pb-1">Sending email</span>
              <span className="block pt-1 pb-1 text-xs break-all">
                {data.sending_address || undefinedValueText()}
              </span>
            </div>
          </div>
          <div className="flex h-20 text-sm border-gray-90 w-1/2">
            <div className="flex flex-col justify-center">
              <span className="block pt-1 pb-1">Reply to email</span>
              <span className="block pt-1 pb-1 text-xs break-all">
                {data.reply_address || undefinedValueText()}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : <></>
}