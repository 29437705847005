import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import moment from "moment"
import SecureFetch from "../components/SecureFetch";
import { updateList } from "./contactList";
import addNextOccurrences from "../utils/addNextOccurrences";

export const campaignInitialState = {
  flags: {
    campaignId: "",
    campaignName: "",
    campaignType: "",
    campaignTypeName: "",
    subject: "",
    pretext: "",
    sourceList: "",
    replyToAddress: "",
    outboundEmail: "",
    template: -1,
    templateName: "",
    startDateTime: "",
    endDateTime: "",
    recurrenceType: "",
    recurrenceName: "",
    status: "Draft",
    errors: [],
  },
  templateData: {
    status: "idle",
    data: [],
    error: {},
  },
  postCampaignData: {
    status: "idle",
    data: [],
    error: {},
  },
  getCampaignData: {
    status: "idle",
    data: [],
    error: {},
  },
  statusData: {
    status: "idle",
    data: [],
    error: {},
  },
  typeData: {
    status: "idle",
    data: [],
    error: {},
  },
  outboundEmailData: {
    status: "idle",
    data: [],
    error: {},
  }
};

export const getTemplates = createAsyncThunk("getTemplates", async () => {
  const response = await SecureFetch(`${api_server}/campaign/template?`);
  const payload = await response.json();
  return {
    data: payload.data ?? [],
  };
});

export const getCampaign = createAsyncThunk(
  "getCampaign",
  async ({ campaignId}, thunkAPI) => {
    const response = await SecureFetch(
      `${api_server}/campaign/${campaignId}`
    );
    const payload = await response.json();
    let campaign = payload.data;
  
    thunkAPI.dispatch(
        updateCampaign({
          status: campaign.status_name,
          campaignId: campaign.id,
          campaignName: campaign.campaign_name,
          campaignType: campaign.type_id,
          campaignTypeName: campaign.type_name,
          subject: campaign.header,
          pretext: campaign.pretext,
          replyToAddress: campaign.reply_to_address,
          outboundEmail: campaign.sending_address,
          template: campaign.template_id,
          templateName: campaign.template_name,
          recurrenceType: campaign.occurrence_id,
          recurrenceName: campaign.occurrence,
          sourceList: campaign.contact_list_id,
          startDateTime: `${campaign.dates.start_date} ${campaign.dates.start_time}`,
          endDateTime: `${campaign.dates.end_date} 00:00`,
        }
      )
    )
    thunkAPI.dispatch(
      updateList({
        sourceList: campaign.contact_list_id,
        sourceListName: campaign.contact_list_name,
        source: campaign.contact_list_source,
      })
    )

    return {
      data: payload.data ?? [],
    };
  }
);

export const postCampaign = createAsyncThunk("postCampaign", async (params, thunkAPI) => {
  const { campaignName, campaignTypeName, subject, pretext, replyToAddress, outboundEmail, statusId } =
    params;
  const body =
    campaignTypeName === "SMS"
      ? {
          campaign_name: campaignName,
          type_id: 2,
          header: subject,
          status_id : statusId,
          created_by: JSON.parse(localStorage.getItem("user")).user_id
        }
      : {
          campaign_name: campaignName,
          type_id: 1,
          header: subject,
          pretext: pretext,
          reply_to_address: replyToAddress ?? null,
          from_address: outboundEmail ?? null,
          status_id : statusId,
          created_by: JSON.parse(localStorage.getItem("user")).user_id
        };
  const response = await SecureFetch(`${api_server}/campaign`,"POST",JSON.stringify(body));
  const payload = await response.json();
  thunkAPI.dispatch(updateCampaign({
    campaignId : payload.campaign_id
  }))
  return {
    data: payload ?? [],
  };
});

export const patchCampaign = createAsyncThunk(
  "patchCampaign",
  async ({
    campaignId,
    template,
    campaignName,
    campaignType,
    recurrenceType,
    startDateTime,
    endDateTime,
    replyToAddress,
    outboundEmail,
    subject,
    pretext,
    sourceList,
    statusId,
  }) => {
    let body = {};
    campaignName && (body.campaign_name = campaignName);
    subject && (body.header = subject);
    pretext && (body.pretext = pretext);
    campaignType && (body.type_id = campaignType);
    template !== undefined && (body.template_id = template);
    recurrenceType && (body.occurrence_id = recurrenceType);
    startDateTime &&
      (body.start_date = moment(startDateTime).format("YYYY/MM/DD"));
    startDateTime && (body.start_time = moment(startDateTime).format("HH:mm"));
    endDateTime && (body.end_date = moment(endDateTime).format("YYYY/MM/DD"));
    replyToAddress && (body.reply_to_address = replyToAddress);
    outboundEmail && (body.from_address = outboundEmail);
    sourceList && (body.contact_list_id = sourceList);
    statusId && (body.status_id = statusId)
    const response = await SecureFetch(`${api_server}/campaign/${campaignId}`,"PATCH",JSON.stringify(body));
    const payload = await response.json();
    return {
      data: payload ?? [],
    };
  }
);

export const getStatus = createAsyncThunk(
  "getStatus", async (params) => {
    const response = await SecureFetch(`${api_server}/campaign/status`);
    const payload = await response.json();
    return {
      data: payload ?? []
    }
  }
)

export const getType = createAsyncThunk(
  "getType", async (params) => {
    const response = await SecureFetch(`${api_server}/campaign/type`);
    const payload = await response.json();
    return {
      data: payload ?? []
    }
  }
)

export const getOutboundEmail = createAsyncThunk(
  "getOutboundEmail", async (params) => {
    const response = await SecureFetch(`${api_server}/campaign/email`);
    const payload = await response.json();
    return {
      data: payload ?? []
    }
  }
)

export const campaign = createSlice({
  name: "campaign",
  initialState: campaignInitialState,
  reducers: {
    updateCampaign: (state, action) => {
      state.flags = {
        ...state.flags,
        ...action.payload,
      };
    },
    resetCampaign: (state) => {
      state.flags = {
        ...campaignInitialState.flags,
      };
    },
    setErrors: (state, action) => {
      state.flags.errors = action.payload;
    },
    addError: (state, action) => {
     state.flags.errors.push(action.payload)

    },
    removeError: (state, action) => {
      const index = state.flags.errors.indexOf(action.payload);
      state.flags.errors.splice(index, 1);
    },
    removeErrors: (state) => {
      state.flags.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.templateData.data = [];
      state.templateData.status = "loading";
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templateData.data = action.payload.data;
      state.templateData.status = "loaded";
    });
    builder.addCase(getTemplates.rejected, (state, action) => {
      state.templateData.status = "error";
      state.templateData.error = action.error.message;
    });
    builder.addCase(postCampaign.pending, (state) => {
      state.postCampaignData.data = [];
      state.postCampaignData.status = "loading";
    });
    builder.addCase(postCampaign.fulfilled, (state, action) => {
      state.postCampaignData.data = addNextOccurrences(action.payload.data)
      state.postCampaignData.status = "loaded"
    });
    builder.addCase(postCampaign.rejected, (state, action) => {
      state.postCampaignData.status = "error";
      state.postCampaignData.error = action.error.message;
    });

    builder.addCase(getCampaign.pending, (state) => {
      state.getCampaignData.data = [];
      state.getCampaignData.status = "loading";
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.getCampaignData.data = action.payload.data;
      state.getCampaignData.status = "loaded";
    });
    builder.addCase(getCampaign.rejected, (state, action) => {
      state.getCampaignData.status = "error";
      state.getCampaignData.error = action.error.message;
    });

    builder.addCase(getStatus.pending, (state) => {
      state.statusData.data = [];
      state.statusData.status = "loading";
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.statusData.data = action.payload.data;
      state.statusData.status = "loaded";
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.statusData.status = "error";
      state.statusData.error = action.error.message;
    });

    builder.addCase(getType.pending, (state) => {
      state.typeData.data = [];
      state.typeData.status = "loading";
    });
    builder.addCase(getType.fulfilled, (state, action) => {
      state.typeData.data = action.payload.data;
      state.typeData.status = "loaded";
    });
    builder.addCase(getType.rejected, (state, action) => {
      state.typeData.status = "error";
      state.typeData.error = action.error.message;
    });

    builder.addCase(getOutboundEmail.pending, (state) => {
      state.outboundEmailData.data = [];
      state.outboundEmailData.status = "loading";
    });
    builder.addCase(getOutboundEmail.fulfilled, (state, action) => {
      state.outboundEmailData.data = action.payload.data;
      state.outboundEmailData.status = "loaded";
    });
    builder.addCase(getOutboundEmail.rejected, (state, action) => {
      state.outboundEmailData.status = "error";
      state.outboundEmailData.error = action.error.message;
    });
  },
});

export const {
  updateCampaign,
  resetCampaign,
  setErrors,
  addError,
  removeError,
  removeErrors,
} = campaign.actions;

export default campaign.reducer;
