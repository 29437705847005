import React, { useState, useEffect, useRef } from "react";
import { SearchIcon, ChevronRightIcon } from "@heroicons/react/outline";
import Error from "./Error";
import useOutsideAlerter from "../utils/useOutsideAlerter";

const Dropdown = ({
  placeHolder,
  optionOnClick,
  options,
  alignLeft = true,
  disabled = false,
  loaded = true,
  overwritePlaceholder = false,
  error,
  errorMessage = "* Please fill in all required fields",
  marginTop = false,
  marginBottom = false,
  search = false,
}) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("")
  const [visibleText, setVisibleText] = useState(placeHolder);
  const margin = `${marginTop ? "mt-10" : "mt-0"} ${marginBottom ? "mb-10" : "mb-0"} `;
  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef,
    (ref, event) => {
      return ref.current && !ref.current.contains(event.target)
    },
    () => {
      setShow(false)
    }
  )

  useEffect(() => {
    setVisibleText(placeHolder);
  }, [placeHolder]);

  function getCursor(disabled) {
    if (disabled) {
      return "cursor-default";
    } else {
      return "cursor-pointer";
    }
  }

  if (search) { options = options?.filter((option) => option?.text?.toLowerCase().includes(searchTerm.toLowerCase())) }

  let cursor = getCursor(disabled)

  return (
    <>
      <div
        ref={wrapperRef}
        className={`${cursor} flex justify-start mr-6 ${
          disabled ? " opacity-[0.5]" : ""
        } ${margin}`}
      >
        {/* Code block starts */}
        <div className={`${cursor} relative`}>
          {loaded ? (
            <div
              className={`${cursor} bg-white dark:bg-gray-800 
                  flex items-center justify-between 
                  border rounded border-gray-300 dark:border-gray-700 
                  w-40 h-12`}
              onClick={() => {
                if (!disabled) {
                  setShow(true);
                }
              }} 
            >
              <p className={`${cursor} pl-3 py-3 text-gray-600 dark:text-gray-400 text-sm leading-3 tracking-normal font-normal overflow-hidden`}>
                {visibleText}
              </p>
              <div className={`${cursor} text-gray-600 dark:text-gray-400 mr-3`}>
                {show ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=" icon icon-tabler icon-tabler-chevron-up"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${cursor} icon icon-tabler icon-tabler-chevron-up`}
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 15 12 9 18 15" />
                  </svg>
                )}
              </div>
            </div>
          ) : (
            <div className={`${cursor} bg-white dark:bg-gray-800 flex items-center justify-between border rounded border-gray-300 dark:border-gray-700 w-40 cursor-pointer h-12`}>
              <div>
                <div className={`${cursor} w-6 h-6 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent ml-3 mr-3`}></div>
              </div>
            </div>
          )}
          {show ? (
            <div
              className={`${cursor} visible transition duration-300 opacity-100 bg-white dark:bg-gray-800 rounded min-w-48 max-w-96 py-1 absolute z-50  ${
                alignLeft ? "left-0" : "right-0"
              } border border-1 shadow`}
            > 
              {search &&
                <div className={`${cursor} flex items-center justify-around`}>
                <div className={`${cursor} w-32 flex items-center p-2 bg-gray-100 rounded-md`}>
                 <SearchIcon className="w-4 h-4"/>
                  <input
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                    type="search1"
                    className={`${cursor} w-full h-full text-sm bg-transparent focus:outline-none pl-2`}
                  />
                </div>
                <div className={`${cursor} w-8 h-8 border rounded-md cursor-pointer hover:bg-gray-100 bg-transparent border-gray-200 flex items-center justify-center`}>
                  <ChevronRightIcon className="w-4 h-4"/>
                </div>
              </div> 
              }
              <ul className={`${cursor} visible position-absolute transition duration-300 opacity-100 mt-2 w-48 py-1 z-9999 max-h-80 overflow-y-auto break-all`}>
                {options.map((option, index) => {
                  const text = option?.text ?? option;
                  const value =
                    option?.value || option?.value === 0
                      ? option?.value
                      : option;

                  return (
                    <li
                      key={index}
                      data-value={value}
                      data-status={option?.status}
                      onClick={(e) => {
                        if (overwritePlaceholder) {
                          setVisibleText(e.target.innerText);
                        }
                        optionOnClick(e);
                        setShow(false);
                        setSearchTerm("")
                      }}
                      className={`${cursor} text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal`}
                    > 
                    {option.valid === false &&
                      <span data-value={value} className={`pl-1 pr-1 mb-2 ${option.valid === false && 'rounded-3xl text-slate-200 bg-red-400 leading-5'} `}>!</span> 
                    }
                    <span data-value={value} className='ml-1'>{text}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        : <></>}
        </div>
        {/* Code block ends */}
      </div>
      {error !== undefined && (
        <Error error={error} errorMessage={errorMessage} />
      )}
    </>
  );
};
export default Dropdown;
