import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Pagination({ items, itemsPerPage = 10, setItems, position }) {
  let { filter } = useSelector(
    (state) => state.engagementHubManager
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(items.length / itemsPerPage) ?? 0;

  const [savedStart, setSavedStart] = useState(0);
  const [savedEnd, setSavedEnd] = useState(0);

  const getSubset = (arr, pagesOffset, numberOfItems) => {
    const start = (pagesOffset-1) * numberOfItems
    const end = start + numberOfItems
    setSavedStart(start)
    setSavedEnd(end)
    const subset = arr.slice(start, end)
    return subset
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [filter])

  useEffect(() => {
    const subset = getSubset(items, currentPage, itemsPerPage);
    setItems(subset);
  }, [items, currentPage])

  const onIncrement = () => {
    if (currentPage < pageCount+1) {
      setCurrentPage(currentPage + 1);
    }
  }

  const onDecrement = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const lastPage = () => {
    setCurrentPage(pageCount);
  }

  const firstPage = () => {
    setCurrentPage(1);
  }

  const onSelect = (event) => {
    const pageNumber = parseInt(event.currentTarget.id);
    setCurrentPage(pageNumber);
  }

  if (position === "top") var border = "border-b";
  if (position === "bottom") var border = "border-t";

  if (pageCount > 99) {
    var numbersEitherSide = 3;
  } else {
    var numbersEitherSide = 5;
  }
  const arrayOfVisibleNumbers = new Array(pageCount).fill(-1).map((v, i) => {return i + 1});
  const lowerLimit = Math.floor(Math.max(0, currentPage - numbersEitherSide));
  const upperLimit = Math.ceil(Math.min(pageCount, lowerLimit + numbersEitherSide + 1 + numbersEitherSide));
  const arrayOfVisibleNumbersShown = arrayOfVisibleNumbers.slice(lowerLimit, upperLimit);

  return (
    <>
      <div className="flex items-center justify-center lg:px-0 sm:px-6 px-4" style={{userSelect: 'none !important'}}>
        {pageCount > 1 &&
          <div style={{ height: "48px" }} className={`${border} lg:w-3/5 w-full flex items-center justify-between border-gray-200`}>
            <div onClick={onDecrement} style={{userSelect: 'none'}} className={`flex font-bold items-center pt-3 text-black hover:text-primary-color-dark/50 cursor-pointer ${currentPage > 1 ? " visible": "invisible"}`}>
                <svg width={26} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={'mb-3.5'} style={{userSelect: 'none'}}>
                  <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className={`mb-3.5 text-sm font-bold leading-none mr-3 ml-2`} style={{userSelect: 'none'}}>Previous</p>
            </div>
            <div className="sm:flex hidden">
                <p onClick={firstPage} className={`text-sm font-medium leading-none cursor-pointer text-gray-600 border-t border-transparent hover:border-secondary-color-light/30 pt-3 mr-4 px-2 ${lowerLimit > 0 ? "visible": "invisible"}`}>...</p>
              <ul>
                {arrayOfVisibleNumbersShown.map(v => (
                  <li key={v} className="inline" style={{userSelect: 'none'}}>
                    <button id={v} onClick={onSelect} className={` pb-3 px-4 rounded-3xl text-sm font-bold leading-none cursor-pointer border-b border-transparent pt-3 mr-4 px-2 ${currentPage === v ? " border-primary-color-dark bg-primary-color-dark/25 cursor-default" : "hover:border-secondary-color-light/30 cursor-pointer"}`} style={{userSelect: 'none'}}>{v}</button>
                  </li>
                ))}
              </ul>
              <p onClick={lastPage} className={`text-sm font-medium leading-none cursor-pointer text-gray-600 border-t border-transparent hover:border-secondary-color-light/30 pt-3 mr-4 px-2 ${upperLimit < pageCount ? "visible": "invisible"}`}>...</p>
            </div>
            <div onClick={onIncrement} style={{userSelect: 'none'}} className={` flex font-bold items-center pt-3 text-black hover:text-primary-color-dark/50 cursor-pointer ${currentPage < pageCount ? "visible": "invisible"}`}>
              <>
                <p style={{userSelect: 'none'}} className={`text-sm font-bold leading-none mr-3 mb-3.5`}>Next</p>
                <svg width={20} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={'mb-3.5'} style={{userSelect: 'none'}}>
                  <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.5 7.33333L12.8333 4" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.5 0.666687L12.8333 4.00002" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </>
            </div>
          </div>
        }
      </div>
      <div className="text-xs ml-6 text-slate-500/75 mt-10">
        <h3>Total : {items.length}</h3>
        <h3>Showing : {savedStart+1} - {savedEnd}</h3>
      </div>
    </>
  );
}