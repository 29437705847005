import Dropdown from "../../../Dropdown";
import { InboxInIcon, AnnotationIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { getOutboundEmail, updateCampaign, removeErrors } from "../../../../features/campaign";
import { useSelector } from "react-redux";
import TextBox from "../../../TextBox";
import Label from "../../../Label";
import Heading2 from "../../../Heading2";
import { useEffect } from "react";

function CampaignHeader({editCampaign, campaignNameArray, currentSelectedStatus}) {
  const {
    outboundEmail,
    campaignName,
    campaignType,
    campaignTypeName,
    pretext,
    subject,
    replyToAddress,
    status,
    errors,
  } = useSelector((state) => state.campaign.flags);
  const { outboundEmailData } = useSelector((state) => state.campaign)
  const campaignTypesDropdown = ["Email", "SMS"];
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getOutboundEmail())
  }, []);

  return (
    <>
      <div className="w-full min-h-fit flex justify-start border-b border-gray-200 flex-wrap cursor-pointer">
        <div className="w-72">
          <div className="flex items-center">
            <h2 className="text-xl font-medium pr-2 leading-5 text-gray-800 leading-2">
              Campaign Header
            </h2>
          </div>
          <p className="mt-4 text-sm leading-5 mb-4 text-gray-600">
            Select a campaign name and type
          </p>
        </div>
        <div className="md:flex items-start mt-0">
          <div className="md:w-64">
            {errors?.includes("campaignDuplicate") && campaignNameArray?.includes(campaignName?.toLowerCase()) && campaignName
              ? <span className="text-red-700  leading-10">{`* Campaign name "${campaignName}" has already been used please select an alternative name`}</span>
              : null}
            {errors?.includes("campaignCharacters")
              ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
              : null}
            {errors?.includes("campaignSpacesSMS")
              ? <span className="text-red-700  leading-10">{`* No spaces allowed`}</span>
              : null}
            <TextBox
              labelText="Campaign Name"
              initialValue={campaignName ?? null}
              disabled={
                status === "Completed" || 
                status === "Archived" || 
                status === "Active" ||
                status === "Failed" ||
                currentSelectedStatus == 4 ||
                currentSelectedStatus == 5 ||
                currentSelectedStatus == 3
              }
              onChange={(e) => {
                dispatch(
                  updateCampaign({
                    campaignName: e.target.value,
                  })
                );
              }}
              type="name"
              id="campaignName"
              tabIndex="0"
              className="w-full p-3 relative text-sm leading-none text-gray-600 bg-white rounded h-12 py-2 outline-none border-2"
              aria-labelledby="Campaign name"
              placeholder="Name"
              required={true}
              error={
                (errors?.includes("campaignType")) &&
                (!campaignName || !campaignType) 
              }
            />
          </div>
          <div className="md:w-44 md:ml-12 min-h-24">
            <Label for="campaignType" text="Campaign Type" />
            <Dropdown
              disabled={
                editCampaign ||
                status === "Completed" || 
                status === "Archived" || 
                status === "Active" ||
                status === "Failed" ||
                currentSelectedStatus == 4 ||
                currentSelectedStatus == 5 ||
                currentSelectedStatus == 3
              }
              id="campaignType"
              optionOnClick={(e) => {
                dispatch(removeErrors());
                dispatch(
                  updateCampaign({ campaignTypeName: e.target.dataset.value })
                );
              }}
              placeHolder={campaignTypeName ?? "Type"}
              name={"campaignType"}
              options={campaignTypesDropdown}
              overwritePlaceholder={true}
            />
          </div>
        </div>
      </div>
      {/* User Selects Email as campaign type */}
      {campaignTypeName  === "Email" &&  (
        <div className="w-full flex justify-start mt-2 pt-4 flex-wrap">
          <div className="w-72">
            <Heading2>
              Campaign Details - Email
              <InboxInIcon className="h-6 w-6 text-primary-color-dark ml-2" />
            </Heading2>
          </div>
          <div>
            <div className="md:flex items-start mt-0 justify-end">
              <div className="md:w-64">
                {errors?.includes("subjectCharacters")
                  ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
                  : null}
                <TextBox
                  name="subject"
                  labelText={"Subject"}
                  initialValue={subject ?? null}
                  disabled={
                    status === "Completed" || 
                    status === "Archived" || 
                    status === "Active" ||
                    status === "Failed" ||
                    currentSelectedStatus == 4 ||
                    currentSelectedStatus == 5 ||
                    currentSelectedStatus == 3
                  }
                  onChange={(e) =>
                    dispatch(
                      updateCampaign({
                        subject: e.target.value,
                      })
                    )
                  }
                  labelledBy="Campaign name"
                  placeholder="Subject"
                />
              </div>
              <div className="md:w-64 md:ml-12 md:mt-0 mt-4 min-h-24">
                <div className="md:w-64">
                  <Label for="replyToAddress" text="Reply to Address" />
                  <Dropdown id="replyToAddress" 
                    disabled={
                      status === "Completed" || 
                      status === "Archived" || 
                      status === "Active" ||
                      status === "Failed" ||
                      currentSelectedStatus == 4 ||
                      currentSelectedStatus == 5 ||
                      currentSelectedStatus == 3
                    }
                    options={["foster@nfagroup.co.uk"]}
                    optionOnClick={
                      (e) => {
                        dispatch(
                          updateCampaign({
                            replyToAddress: e.target.innerText,
                          })
                        )
                      }
                    }
                    placeHolder={replyToAddress !== undefined ? replyToAddress : "Reply to address"}
                    overwritePlaceholder={true} />
                </div>
              </div>
            </div>
            <div>
              <div className="md:flex items-start justify-end">
                <div className="md:w-64">
                  {errors?.includes("pretextCharacters")
                    ? <span className="text-red-700  leading-10">{`* No special characters are allowed`}</span>
                    : null}
                  <TextBox
                  name="pretext"
                  labelText="Pretext"
                  disabled={
                    status === "Completed" || 
                    status === "Archived" || 
                    status === "Active" ||
                    status === "Failed" ||
                    currentSelectedStatus == 4 ||
                    currentSelectedStatus == 5 ||
                    currentSelectedStatus == 3
                  }
                  initialValue={pretext ?? null}
                  onChange={(e) => {
                    dispatch(
                      updateCampaign({
                        pretext: e.target.value,
                      })
                    );
                  }}
                  labelledBy="Campaign name"
                  placeholder="Pretext"
                  />
                </div>
                <div className="md:w-64 md:ml-12 md:mt-0 min-h-24">
                  <Label for="outboundEmail" text="Outbound Email" />
                  <Dropdown
                    id="outboundEmail"
                    disabled={
                      status === "Completed" || 
                      status === "Archived" || 
                      status === "Active" ||
                      status === "Failed" ||
                      currentSelectedStatus == 4 ||
                      currentSelectedStatus == 5 ||
                      currentSelectedStatus == 3
                    }
                    options={outboundEmailData?.data.data}
                    optionOnClick={(e) => {
                      dispatch(
                        updateCampaign({
                          outboundEmail: e.target.outerText                         
                        })
                      );
                    }}
                    placeHolder={outboundEmail ?? "Email"}
                    error={
                      errors?.includes("campaignEmail") &&
                      (!pretext ||
                        !subject ||
                        !outboundEmail ||
                        !replyToAddress)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* User selects SMS as campaign type */}
      {campaignTypeName === "SMS" && (
        <div className="w-full flex justify-start mt-2 pt-4 flex-wrap">
          <div className="w-72">
            <div className="flex">
              <Heading2>
                Campaign Details - SMS
                <AnnotationIcon className="ml-2 h-6 w-6 text-primary-color-dark" />
              </Heading2>
            </div>
          </div>
          <div>
            <div className="w-full justify-start">
              <div className="md:flex items-start justify-start">
                <div className="md:w-64">
                  <TextBox
                    name="SmsSubject"
                    labelText="Subject"
                    disabled={
                      status === "Completed" || 
                      status === "Archived" || 
                      status === "Active" ||
                      status === "Failed" ||
                      currentSelectedStatus == 4 ||
                      currentSelectedStatus == 5 ||
                      currentSelectedStatus == 3
                    }
                    initialValue={subject ?? null}
                    onChange={(e) => {
                      dispatch(
                        updateCampaign({
                          subject: e.target.value,
                        })
                      );
                    }}
                    type="name"
                    labelledBy="Campaign name"
                    placeholder="Subject"
                    error={errors?.includes("campaignSMS") && !subject}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CampaignHeader;
