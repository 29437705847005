import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";

export const optionListInitialState = {
  getOptionsList : {
    status: "idle",
    data: [],
    error: {}
  }
};

export const getOptionsList = createAsyncThunk(
  "getOptionsList",
  async (params, thunkAPI) => {
    const response = await SecureFetch(`${api_server}/options`);
    const payload = await response.json()
    return {
      data: payload ?? []
    }
  }
)

export const optionList = createSlice({
  name: "optionList",
  initialState: optionListInitialState,
  reducers: {
    getOptions: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOptionsList.pending, (state) => {
        state.getOptionsList.data = [];
        state.getOptionsList.status = "loading";
      })
      .addCase(getOptionsList.fulfilled, (state, action) => {
        state.getOptionsList.data = action.payload.data;
        state.getOptionsList.status = "loaded";
      })
      .addCase(getOptionsList.rejected, (state) => {
        state.getOptionsList.status = "error";
        state.getOptionsList.error = action.error.message;
      });
  },
});

export const {
  getOptions
} = optionList.actions;

export const getData = (state) => state;

export default optionList.reducer;
