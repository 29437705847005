import React, { useState } from "react";

import {
  CalendarIcon,
  HeartIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChartBarIcon,
  UserIcon,
  LogoutIcon,
  XIcon,
  MenuAlt4Icon,
  DocumentReportIcon,
} from "@heroicons/react/outline";

const Layout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [profile, setProfile] = useState(false)
  const [engagementHubMenuFoldOpen, setEngagementHubMenuFoldOpen] = useState(false);
  const [nurturingJourneyMenuFoldOpen, setNurturingJourneyMenuFoldOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"))
  const name = `${user?.first_name} ${user?.last_name}`
  const logout = () => { 
    window.location.href = "/logout"
  }
  const menuChange = (type) => {
    if (type === "engagementHub") {
      setEngagementHubMenuFoldOpen(!engagementHubMenuFoldOpen)
      setNurturingJourneyMenuFoldOpen(false)
    } else {
      if (type === "nurturingJourney") {
        setEngagementHubMenuFoldOpen(false)
        setNurturingJourneyMenuFoldOpen(!nurturingJourneyMenuFoldOpen)
      }
    }
  }
  return (
    <>
      <div className="w-full h-full bg-gray-100">
        <div className="flex flex-no-wrap max-h-screen overflow-y-hidden">
          {/* Main sidebar starts */}
          <div className="w-72 h-screen bg-primary-color-dark hidden lg:block text-white">
            <div className="h-16 w-full flex justify-start items-center px-8 bg-white border-b-2 border-solid border-primary-color-dark">
            <img
            src="https://ofg-yv-images-bucket.s3.eu-west-2.amazonaws.com/center_logos/nfg_logo.png"
            alt="logo"
            className="max-h-full"
          />
            </div>
            <ul className="mt-5">
              <li className={`flex flex-col mt-2 w-full ${
                  engagementHubMenuFoldOpen ? "bg-secondary-color-light" : ""
                }`}
              >
                <div
                  onClick={() => {menuChange("engagementHub")}}
                  className="flex items-center justify-between w-full hover:text-primary-color-dark hover:bg-secondary-color-light cursor-pointer px-4 py-2"
                >
                  <div className="flex items-center rounded">
                    <CalendarIcon className="h-6 w-6" />
                    <span className="text-sm ml-2 leading-none">
                      Engagement Hub
                    </span>
                  </div>
                  {engagementHubMenuFoldOpen 
                    ? <ChevronDownIcon className="h-5 w-5" />
                    : <ChevronRightIcon className="h-5 w-5" />
                  }
                </div>
                {engagementHubMenuFoldOpen && (
                  <ul className=" text-sm leading-none w-full">
                    <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                      <a href="/engagement-hub">Engagement Hub Manager</a>
                    </li>
                    <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                      <a href="/engagement-hub/reports">
                        Engagement Hub Reports
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className={`flex flex-col mt-2 w-full ${
                  nurturingJourneyMenuFoldOpen ? "bg-secondary-color-light" : ""
                }`}
              >
                <div
                  onClick={() => {menuChange("nurturingJourney")}}
                  className="flex items-center justify-between w-full hover:text-primary-color-dark hover:bg-secondary-color-light cursor-pointer px-4 py-2"
                >
                  <div className="flex items-center rounded">
                    <HeartIcon className="h-6 w-6" />
                    <span className="text-sm ml-2 leading-none">
                      Nurturing Journey
                    </span>
                  </div>
                  {nurturingJourneyMenuFoldOpen
                    ? <ChevronDownIcon className="h-5 w-5" />
                    : <ChevronRightIcon className="h-5 w-5" />
                  }
                </div>
                {nurturingJourneyMenuFoldOpen && (
                  <ul className=" text-sm leading-none w-full">
                    <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                      <a href="/nurturing-journey">Nurturing Journey Manager</a>
                    </li>
                    <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                      <a href="/nurturing-journey/reports">
                        Nurturing Journey Reports
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`flex items-center mt-2 w-full text-sm hover:bg-secondary-color-light hover:text-primary-color-dark cursor-pointer px-4 py-2`}
              >
                <ChartBarIcon className="h-6 w-6 mr-2" />
                <a href="/data-manager/list">List Manager</a>
              </li>
              <li
                className={`flex items-center mt-2 w-full text-sm hover:bg-secondary-color-light hover:text-primary-color-dark cursor-pointer px-4 py-2`}
              >
                <DocumentReportIcon className="h-6 w-6 mr-2" />
                <a href="/reports/a-b-comparison">A/B Comparison</a>
              </li>
            </ul>
          </div>
          {/* Main sidebar ends */}

          {/*Mobile responsive sidebar starts */}
          <div
            className={
              showSidebar
                ? "w-full h-full absolute z-40 transform translate-x-0 text-white"
                : "   w-full h-full absolute z-40 transform -translate-x-full"
            }
          >
            <div
              className="bg-gray-800 opacity-50 absolute h-full w-full lg:hidden"
              onClick={() => {setShowSidebar(!showSidebar)}}
            />
            <div className="absolute z-40 w-72 md:w-96 pb-4 bg-primary-color-dark lg:hidden transition duration-150 ease-in-out h-full">
              <div className="flex flex-col justify-between h-full w-full">
                <div>
                  <div className="flex items-center justify-between px-8 bg-white">
                    <div className="w-full flex items-center">
                    <img
                      src="https://ofg-yv-images-bucket.s3.eu-west-2.amazonaws.com/center_logos/nfg_logo.png"
                      alt="logo"
                      className="max-h-full"
                    />
                    </div>
                    <div
                      className="flex items-center justify-center h-10 w-10"
                      onClick={() => {setShowSidebar(!showSidebar)}}
                    >
                      <XIcon className="text-primary-color-dark w-6 h-6" />
                    </div>
                  </div>
                  <ul className="mt-5">
                    <li
                      className={`flex flex-col mt-2 w-full hover:bg-secondary-color-light cursor-pointer ${
                        engagementHubMenuFoldOpen ? "bg-secondary-color-light" : ""
                      }`}
                    >
                      <div
                        onClick={() => {menuChange("engagementHub")}}
                        className="flex items-center justify-between w-full hover:text-primary-color-dark hover:bg-secondary-color-light cursor-pointer px-4 py-2"
                      >
                        <div className="flex items-center rounded">
                          <CalendarIcon className="h-6 w-6" />
                          <span className="text-sm ml-2 leading-none">
                            Engagement Hub
                          </span>
                        </div>
                        {engagementHubMenuFoldOpen ? (
                          <ChevronDownIcon className="h-5 w-5" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5" />
                        )}
                      </div>
                      {engagementHubMenuFoldOpen && (
                        <ul className=" text-sm leading-none w-full">
                          <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                            <a href="/engagement-hub">Engagement Hub Manager</a>
                          </li>
                          <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                            <a href="/engagement-hub/reports">
                              Engagement Hub Reports
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li
                      className={`flex flex-col mt-2 w-full hover:bg-secondary-color-light cursor-pointer ${
                        nurturingJourneyMenuFoldOpen ? "bg-secondary-color-light" : ""
                      }`}
                    >
                      <div
                        onClick={() => {menuChange("nurturingJourney")}}
                        className="flex items-center justify-between w-full hover:text-primary-color-dark hover:bg-secondary-color-light cursor-pointer px-4 py-2"
                      >
                        <div className="flex items-center rounded">
                          <HeartIcon className="h-6 w-6" />
                          <span className="text-sm ml-2 leading-none">
                            Nurturing Journey
                          </span>
                        </div>
                        {nurturingJourneyMenuFoldOpen ? (
                          <ChevronDownIcon className="h-5 w-5" />
                        ) : (
                          <ChevronRightIcon className="h-5 w-5" />
                        )}
                      </div>
                      {nurturingJourneyMenuFoldOpen && (
                        <ul className=" text-sm leading-none w-full">
                          <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                            <a href="/nurturing-journey">
                              Nurturing Journey Manager
                            </a>
                          </li>
                          <li className="py-2 pl-12 pr-4 cursor-pointer hover:text-primary-color-dark">
                            <a href="/nurturing-journey/reports">
                              Nurturing Journey Reports
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li
                      className={`flex items-center mt-2 w-full text-sm hover:bg-secondary-color-light hover:text-primary-color-dark cursor-pointer px-4 py-2`}
                    >
                      <ChartBarIcon className="h-6 w-6 mr-2" />
                      <a href="/data-manager/list">List Manager</a>
                    </li>
                    <li
                      className={`flex items-center mt-2 w-full text-sm hover:bg-secondary-color-light hover:text-primary-color-dark cursor-pointer px-4 py-2`}
                    >
                      <DocumentReportIcon className="h-6 w-6 mr-2" />
                      <a href="/reports/a-b-comparison">A/B Comparison</a>
                    </li>
                  </ul>
                </div>
                <div className="w-full h-full mt-16">
                  <div className="border-t-4 border-gray-300 item-end">
                    <div className="w-full mt-3">
                      <div className="block items-center">
                        <div className="flex items-center hover:bg-secondary-color-light hover:text-primary-color-dark pl-4 py-2">
                          <UserIcon className="h-6 w-6 cursor-default" />
                          <p className="md:text-xl leading-4 ml-2 cursor-default">
                            {name}
                          </p>
                        </div>
                        <div className="flex items-center hover:bg-secondary-color-light hover:text-primary-color-dark pl-4 py-2" onClick={logout}>
                          <LogoutIcon className="h-6 w-6 cursor-pointer" />
                          <span className="md:text-xl leading-4 ml-2 cursor-pointer">
                            Sign out
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar ends */}

          {/* Navigation starts */}
          <div className="w-full max-h-screen h-screen">
            <nav className="h-16 flex items-center lg:items-stretch justify-between bg-white z-10 border-b-2 border-solid border-primary-color-dark">
              <div className="h-full flex items-center pl-6">
                <img
                  src="https://ofg-yv-images-bucket.s3.eu-west-2.amazonaws.com/center_logos/nfg_logo.png"
                  alt="logo"
                  className="lg:hidden max-h-full"
                />
              </div>
              <div className="hidden lg:flex w-full pr-6">
                <div className="w-full flex items-center pl-8 justify-end">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {setProfile(!profile)}}
                  >
                    <div className="rounded-full">
                      {profile && (
                        <ul className="p-2 w-full border-r bg-white absolute rounded left-0 mt-0 sm:mt-10">
                          <li className="flex w-full justify-between text-primary-color-dark cursor-pointer items-center">
                            <div className="flex items-center" onClick={logout}>
                              <LogoutIcon className="text-primary-color-dark w-5 h-5" />
                              <span className="text-sm ml-2">Sign out</span>
                            </div>
                          </li>
                        </ul>
                      )}
                    </div>
                    <p className="text-primary-color-dark text-sm mx-3">{name}</p>
                    <div className="cursor-pointer text-gray-600 md:justify-end">
                      {!profile
                        ? <ChevronDownIcon className="h-5 w-5 text-primary-color-dark" />
                        : <ChevronUpIcon className="h-5 w-5 text-primary-color-dark" />
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="text-primary-color-dark mr-8 visible lg:hidden"
                onClick={() => {setShowSidebar(!showSidebar)}}
              >
                {showSidebar ? (
                  " "
                ) : (
                  <div>
                    <MenuAlt4Icon className="text-primary-color-dark w-6 h-6" />
                  </div>
                )}
              </div>
            </nav>
            {/* Navigation ends */}
            <div className="md:w-full md:max-w-full w-full overflow-y-auto max-h-84 h-84 max-h-screen-minus-header-bar h-screen-minus-header-bar overflow-y-visible overflow-x-hidden">
              {/*Content goes here! */}
              {children}
            </div>
          </div>
          {/* Navigation ends */}
        </div>
      </div>
    </>
  );
};
export default Layout;
