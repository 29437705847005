import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {
	setContactListModalOpen,
} from "../features/nurturingJourneyManager";
import LeadAgGrid from "../components/CampaignModal/campaignModalMain/sourceList/LeadAgGrid";
import Heading2 from "../components/Heading2";
import ModalHeader from "../components/ModalHeader";
import CheckAuth from "../components/CheckAuth";

export default function ContactListModal({ data }) {
  const dispatch = useAppDispatch();

  return (
    <>
      <CheckAuth />
      <div id="popup" className="z-50 fixed w-full flex justify-center inset-0">
        <div className="w-full h-full bg-gray-400 opacity-60 z-0 absolute inset-0" />
        <div className="mx-auto container">
          <div className="flex items-center justify-center h-full w-full">
            <div className="bg-white rounded shadow fixed overflow-y-auto w-10/12 md:w-10/12 lg:w-10/12 2xl:w-10/12 overflow-visible h-modal relative">
              <ModalHeader
                onModalCloseClickFn={
                  () => {
                    dispatch(setContactListModalOpen(false));
                    // dispatch(resetCampaign());
                  }
                }
              >
              </ModalHeader>
              <div className="bg-white md:px-4 md:py-8 py-8 flex flex-col">
                <Heading2>Contact List</Heading2>
                <LeadAgGrid editable={false} gridData={{data: data}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}