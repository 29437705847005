import React, { useState, useEffect,  useRef, useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useDispatch, useSelector } from "react-redux";
import { postList, updateList } from "../../../../features/contactList";
import { getList } from "../../../../features/contactList"
import  CampaignCounter  from "../../../CampaignCounter"
import moment from 'moment'

function LeadAgGrid({ count, gridData, gridFilters, campaignNameArray }) {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const {
    newListFilters,
    newListIds,
    newListName,
    dataSource,
    listType,
    createNewList,
    sourceList,
    listSelection,
  } = useSelector((state) => state.contactList.flags);
  const { data } = useSelector((state) => state.contactList.getListHeadersData.data)
  const { getListData } = useSelector((state) => state.contactList);
  const [selectionError, setSelectionError] = useState(false)
  const listNames = data?.map((list) => list.list_name.toLowerCase())
 
  useEffect(() => {
    setSelectionError("")
  }, [listType, createNewList])

  // useEffect(() => {
  //   dispatch(getList({sourceList}))
  // }, [sourceList])

  const checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };
  const checkbox = function (gridOptions) {
    return gridOptions.columnApi.setColumnVisible('check', false);
  };

  const dobFilterParams = {
    suppressAndOrCondition: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var cellDate = moment(cellValue,"DD/MM/yyyy");
      var filterDate = moment(filterLocalDateAtMidnight);
      if (filterDate.isSame(cellDate)) {
        return 0;
      } else if (cellDate < filterDate) {
        return -1;
      } else if (cellDate > filterDate) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const createdFilterParams = {
    suppressAndOrCondition: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      cellValue = cellValue.split('T');
      var cellDate = moment(cellValue[0],"yyy-MM-DD");
      var filterDate = moment(filterLocalDateAtMidnight);
      if (filterDate.isSame(cellDate)) {
        return 0;
      } else if (cellDate < filterDate) {
        return -1;
      } else if (cellDate > filterDate) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const createdDate = (params) => {
    if (!params.value) return "";
    var date = params.value.split('T');
    return moment(date[0],"yyy-MM-DD").format("DD/MM/yyyy");
  }

  const createdTime = (params) => {
    if (!params.value) return "";
    var date = params.value.split('T');
    return date[1].slice(0,5) ;
  }

  let [leadColumnDefs] = useState([
    { field: "contact_id", hide: true, filter: false },
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { 
      field: "date_of_birth", 
      headerName: "DOB", 
      filter: "agDateColumnFilter", 
      filterParams: dobFilterParams
    },
    { field: "ethnic_group_name", headerName: "Ethnic Background" },
    { field: "marital_status_name", headerName: "Relationship Status" },
    { field: "address_line_2", headerName: "Address" },
    { field: "county", headerName: "County" },
    { field: "city", headerName: "City" },
    { field: "post_code", headerName: "PostCode" },
    { field: "country", headerName: "Country" },
    { field: "email_address", headerName: "Email" },
    { field: "job_sector_name", headerName: "Occupation" },
    { field: "gender_name", headerName: "Gender" },
    { field: "sexuality_name", headerName: "Sexuality" },
    { field: "religion_name", headerName: "Religion" },
    { field: "phone_number", headerName: "Phone" },
    { field: "age_range", headerName: "Age Range" },
    { field: "spare_room", headerName: "Spare Room" },
    { field: "source_campaign_name", headerName: "Source" },
    { field: "marketing_source", headerName: "Marketing Source" },
    { field: "campaign_name", headerName: "Campaign Name" },
    { field: "date/time", headerName: "Date/Time" },
    { field: "system_id", headerName: "System Id" },
    { field: "assigned_agency", headerName: "Agency" },
    { field: "charms_close_reason", headerName: "Charms Close Reason" },
    { field: "charms_currnet_situation", headerName: "Charms Current Situation" },
    { field: "charms_family_id", headerName: "Charms Family Id" },
    { field: "charms_onhold_reason", headerName: "Charms On Hold Reason" },
    {
      field: `created_ts`, 
      headerName: "Lead Creation Date",
      filter: "agDateColumnFilter", 
      valueFormatter: params => createdDate(params),
      filterParams: createdFilterParams
    },
    {
      field: `created_ts`, 
      headerName: "Lead Creation Time",  
      valueFormatter: params => createdTime(params)
    },
    { 
      checkboxSelection, 
      headerName: "", 
      suppressRowClickSelection:true,
      headerCheckboxSelection:true, 
      suppressMenu:true, 
      suppressSorting:true, 
      sortable:false, 
      suppressFilter:true, 
      suppressFilterButton: false, 
      suppressColumnsToolPanel:true,
      editable: false,
      sideBar: false,
      suppressMovable: true,
      enableHiding: false,
      functionsReadOnly: true,
      filter: false,
      pinned: "left",
      toolPanelClass: ['hidden']
    }
  ]);

  let [staffColumnDefs] = useState([
    { field: "contact_id", hide: true, filter: false },
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { field: "date_of_birth", headerName: "DOB", filter: "agDateColumnFilter", filterParams: dobFilterParams},
    { field: "gender_name", headerName: "Gender" },
    { field: "marital_status_name", headerName: "Relationship Status" },
    { field: "address_type_name", headerName: "Address Type" },
    { field: "is_manager", headerName: "Manager" },
    { field: "working_status", headerName: "Working Status" },
    { field: "contract", headerName: "Contract" },
    { field: "address_line_1", headerName: "Address line 1" },
    { field: "address_line_2", headerName: "Address line 2" },
    { field: "address_line_3", headerName: "Address line 3" },
    { field: "address_line_4", headerName: "Address line 4" },
    { field: "address_line_4", headerName: "Address line 4" },
    { field: "post_code", headerName: "Post Code" },
    { field: "city", headerName: "City" },
    { field: "country", headerName: "Country" },
    { field: "address_line_4", headerName: "Address line 4" },
    { field: "email_address", headerName: "Email" },
    { field: "email_address_type_name", headerName: "Email Type" },
    { field: "phone_number", headerName: "Phone Number" },
    {
      field: `created_ts`, 
      headerName: "Creation Date",
      filter: "agDateColumnFilter", 
      valueFormatter: params => createdDate(params),
      filterParams: createdFilterParams
    },
    {
      field: `created_ts`, 
      headerName: "Creation Time",  
      valueFormatter: params => createdTime(params)
    },
    { field: "staff_group", headerName: "Staff Group" },
    { field: "department", headerName: "Department" },
    { field: "work_location", headerName: "Work Location" },
    { field: "region", headerName: "Region" },
    { field: "employment_start_date", headerName: "Employment Start Date" },
    { field: "job-title", headerName: "Job Title" },
    { field: "id_key", headerName: "Id Key" },
    { field: "cascade_display_id", headerName: "Cascade Display Id" },
    { 
      headerName: "", 
      checkboxSelection, 
      headerCheckboxSelection:true, 
      suppressMenu:true, 
      suppressSorting:true, 
      sortable:false, 
      suppressFilter:true, 
      suppressFilterButton: true, 
      editable: false,
      sideBar: false,
      suppressMovable: true,
      enableHiding: false,
      functionsReadOnly: true,
      filter: false,
      suppressColumnsToolPanel:true,
      pinned: "left",
      toolPanelClass: ['hidden']
    }
  ]);

  const defaultColDef = {
    filter: true,
    sortable: true,
    width: 120,
    resizable: "true",
    sortable: "true",
    enableValue: true,
    enableRowGroup: true,
    suppressRowClickSelection: true,
    groupSelectsFiltered: true,
    headerCheckboxSelectionFilteredOnly: true
  };

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        minWidth: 225,
        maxWidth: 225,
        width: 225,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        minWidth: 180,
        maxWidth: 400,
        width: 250,
      },
    ],
    defaultToolPanel: "",
  };

  const onQuickFilterChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("quickFilter").value
    );
  }, []);

  const onSelectionChanged = useCallback(() => {
    const selectedIds = gridRef.current.api
      .getSelectedRows()
      .map((contact) => contact.contact_id);
      // setselectedListIds(selectedIds)
      dispatch(
        updateList({
          newListIds: selectedIds,
        })
      );
  }, []);

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const saveList = useCallback(() => {
    dispatch(postList({ newListName, newListIds, dataSource, listTypeId: 1 }));
  }, [newListName, newListIds, dataSource]);

  const onFilterChange = () => {
    dispatch(updateList({
      newListFilters : gridRef?.current?.api?.getFilterModel()
    }))
  }

  const saveFilters = () => {
    const filters = gridRef?.current?.api?.getFilterModel();
    dispatch(postList({ newListName, dataSource, listTypeId: 2, filters }));
  };

  if (getListData?.status === "idle" || getListData?.status === "error") {
    gridRef.current?.api?.showNoRowsOverlay()
  }

  if (getListData?.status === "loading") {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  if (gridFilters && gridFilters.length>1) {
    gridRef?.current?.api?.setFilterModel(gridFilters)
  }

  const colDefsFixed = () => {
    return listSelection === "List By Campaign" && getListData.status === "loaded" ? getListData?.data?.data[0]?.contact_type_id === 434 ? staffColumnDefs : leadColumnDefs: dataSource === "Lead Data" ? leadColumnDefs : staffColumnDefs
  }

  const colDefsDynamic = () => {
    let list = colDefsFixed();
    let filteredList = list.filter((item) => item.headerName !== "");
    return filteredList;
  }

  const checkSelection = ({type}) => {
    if (!newListName) {
      setSelectionError('No list name selected please provide a list name to continue')
    }
    else if (listNames.includes(newListName.toLowerCase())) {
      setSelectionError(`List name ${newListName} already exists please select a new name`)
    } else {
    if (type === "fixed") { 
      if (newListIds.length < 1) {
        setSelectionError("Please select at least one person to save list") 
      } else {
        setSelectionError("")
        saveList()
        dispatch(updateList({
          newListIds : []
        }))
      }
    } else if (type === "dynamic") {
      if (Object.keys(newListFilters).length < 1) {
        setSelectionError("Please select at least one filter to save list")
      } else {
        setSelectionError("")
        saveFilters()
        dispatch(updateList({
          newListFilters : []
        }))
      }
    }
    }
  }
  return (
    <>
      <div className="w-full ml-10 mb-2 justify-start">
      {listSelection === "Pre-Selected List" && sourceList ? <CampaignCounter count={count}/> : null}
        <input
          className="w-64 p-3 mt-3 bg-white border rounded border-gray-500 focus:outline-none focus:border-gray-600 text-sm font-medium leading-none text-gray-800"
          type="text"
          onInput={onQuickFilterChanged}
          id="quickFilter"
          placeholder="Dynamic Search..."
        />
        {createNewList && (
          <>
            <button
              className="px-6 py-3 bg-primary-color-dark hover:bg-gray-500 shadow rounded text-sm text-white ml-0 md:ml-2"
              onClick={clearFilters}
            >
              Reset Filters
            </button>
            {listType === "Fixed" ? (
              <button
                className="px-6 py-3 bg-primary-color-dark hover:bg-gray-500 shadow rounded text-sm text-white ml-0 md:ml-2"
                onClick={() => {checkSelection({type : "fixed"})}}
              >
                Save List
              </button>
            ) : (
              <button
                className="px-6 py-3 bg-primary-color-dark hover:bg-gray-500 shadow rounded text-sm text-white ml-0 md:ml-2"
                onClick={() => {

                  checkSelection({type: "dynamic"})}
                }
              >
                Save Filters
              </button>
            )}
          </>
        )}
      {selectionError &&  <span className="text-xs text-red-700 ml-4">{selectionError}</span> }
      </div>
      <div className="flex w-full justify-center">
        <div className="ag-theme-alpine" style={{ height: 700, width: "95%" }}>
        {createNewList ? (
          listType !== "Dynamic" ?
          (
          <AgGridReact
            ref={gridRef}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChange}
            paginationAutoPageSize={true}
            pagination={true}
            sideBar={sideBar}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: white">Please wait while your rows are loading</span>'
            }
            rowData={gridData.data}
            columnDefs={colDefsFixed()}
            rowSelection="multiple"
            suppressRowClickSelection={listType === "Dynamic"}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
          ></AgGridReact>) :
          (
            <AgGridReact
              ref={gridRef}
              onSelectionChanged={onSelectionChanged}
              onFilterChanged={onFilterChange}
              paginationAutoPageSize={true}
              pagination={true}
              sideBar={sideBar}
              defaultColDef={defaultColDef}
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: white">Please wait while your rows are loading</span>'
              }
              rowData={gridData.data}
              columnDefs={colDefsDynamic()}
              rowSelection="multiple"
              suppressRowClickSelection={listType === "Dynamic"}
              overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
            ></AgGridReact>
          )
        ) : (
          <AgGridReact
            ref={gridRef}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChange}
            paginationAutoPageSize={true}
            pagination={true}
            sideBar={sideBar}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: white">Please wait while your rows are loading</span>'
            }
            rowData={gridData.data}
            columnDefs={colDefsDynamic()}
            rowSelection="multiple"
            suppressRowClickSelection={listType === "Dynamic"}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
          ></AgGridReact>
        )}
          
        </div>
      </div>
    </>
  );
}

export default LeadAgGrid;
