import { Routes, Route } from "react-router-dom";
import EngagementHubManager from "./containers/EngagementHubManager";
import EngagementReports from "./containers/EngagementReports";
import NurturingJourneyManager from "./containers/NurturingJourneyManager";
import NurturingReports from "./containers/NurturingReports";
import DataList from "./containers/DataList";
import DataApi from "./containers/DataApi";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import "./index.css";

import { LicenseManager } from 'ag-grid-enterprise';
import ABComparison from "./containers/ABReportsComparison";
const licenseKey = "CompanyName=8bit solutions,LicensedApplication=OFG CRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-017542,ExpiryDate=22_July_2022_[v2]_MTY1ODQ0NDQwMDAwMA==7724ab981632a426882fea89456e30d8";
LicenseManager.setLicenseKey(licenseKey);

function App() {
  return (
    <div className="App flex flex-col h-full max-h-full">
      <Routes>
        <Route exact path="/" element={<EngagementHubManager />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/engagement-hub" element={<EngagementHubManager />} />
        <Route exact path="/engagement-hub/reports" element={<EngagementReports />} />
        <Route exact path="/nurturing-journey" element={<NurturingJourneyManager />} />
        <Route exact path="/nurturing-journey/reports" element={<NurturingReports />} />
        <Route exact path="/reports/a-b-comparison" element={<ABComparison />} />
        <Route exact path="/data-manager/list" element={<DataList />} />
        <Route exact path="/data-manager/api" element={<DataApi />} />
      </Routes>
    </div>
  );
}

export default App;
