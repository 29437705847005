import { useEffect, useState } from "react";
import EngagementHubManagerCard from "../components/EngagementHubManagerCard";
import CampaignModal from "./CampaignModal";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {
  toggleModal,
  setDataFromAPI,
} from "../features/engagementHubManager";
import EngagementHubManagerSearch from "../components/EngagementHubManagerSearch";
import Heading1 from "../components/Heading1";
import CheckAuth from "../components/CheckAuth";
import { resetCampaign } from "../features/campaign";
import Pagination from "../components/Pagination";

function EngagementHubManager() {
  const dispatch = useAppDispatch();
  let { awaiting, data, modalVisible, initialLoadDone, filter } = useSelector(
    (state) => state.engagementHubManager
  )

  const [currentDataItems, setCurrentDataItems] = useState([])
  const { flags } = useSelector((state) => state.campaign)

  useEffect(() => {
    document.title = "Engagement Hub - Engagement Hub Manager"
    dispatch(resetCampaign())
  }, [])

  useEffect(() => {
    if (!initialLoadDone && !data.length && !awaiting) {
      dispatch(setDataFromAPI(filter));
    }
  }, [data, flags]);

  const openCloseModal = () => {
    dispatch(toggleModal(!modalVisible));
  };

  useEffect(() => {
    if (!modalVisible && !initialLoadDone && !data.length && !awaiting) {
      dispatch(setDataFromAPI(filter));
    }
  }, [modalVisible, initialLoadDone, data, awaiting, flags]);

  return (
    <>
      <CheckAuth />
      <EngagementHubManagerSearch />
      <div className="py-6 md:py-10 md:px-6 flex-auto flex flex-col">
        <Heading1>Engagement Manager</Heading1>
        <div>
          {data && <Pagination
            items={data}
            setItems={setCurrentDataItems}
            itemsPerPage={12}
            position="top" />
          }
        </div>
        <div className="grid grid-cols-card auto-cols-min gap-x-4 gap-y-12 justify-center overflow-y-auto md:w-full md:max-w-full min-w-full w-full flex-auto mt-6">
          {!awaiting ? (
            currentDataItems !== undefined && !!currentDataItems.length ? (
              currentDataItems.map((campaign, index) => {
                return (
                  <div key={index}>
                    <EngagementHubManagerCard
                      id={campaign.id}
                      topImage={
                        campaign.render
                      }
                      campaignName={campaign.campaign_name}
                      campaignTypeID={campaign.campaign_type.type_id}
                      dates={campaign.dates}
                      occurrence={campaign.occurrence}
                      header={campaign.header}
                      replyAddress={campaign.reply_to_address ?? undefined}
                      sendingAddress={campaign.sending_address ?? undefined}
                      reach={campaign.reach ?? 9999}
                      dynamicReach={campaign.dynamic_reach}
                      campaignStatusID={campaign.status_id}
                      parentCampaignStartDate={campaign.parent_campaign_start_date}
                      nextOccurrenceDate={campaign.nextOccurrenceDate}
                    />
                  </div>
                );
              })
            ) : (
              <div className="col-span-5 justify-self-center self-center flex items-center">
                No results found
              </div>
            )
          ) : (
            <div className="col-span-5 justify-self-center self-center flex items-center">
              <div>
                <div className="w-8 h-8 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent mr-6"></div>
              </div>
              <span className="text-3xl">Loading...</span>
            </div>
          )}
        </div>
        {modalVisible && <CampaignModal openCloseModal={openCloseModal} />}
      </div>
    </>
  );
}

export default EngagementHubManager;
