import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading2 from "./../Heading2";
import TextBox from "../TextBox";
import { updateEditModeStep } from "../../features/nurturingJourneyManager";

function Scheduler() {
  const dispatch = useDispatch()
	const {editMode} = useSelector((state) => state.nurturingJourneyManager)  
  const stepID = editMode.editingStepData.id ?? editMode.editingStepData.step_id
  
  return (
    <div className="w-full min-h-fit flex border-b pb-4 border-gray-200 flex-wrap">
      <div className="w-64 mr-6">
        <div className="flex flex-col">
          <Heading2>
            Scheduler
          </Heading2>
          <p className="my-4 text-sm leading-5 text-gray-600">
            Select the waiting period between repetitions of this step, measured in days.
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="mb-4">
            <TextBox
              labelText="Waiting period"
              onChange={
                (e) => {
                  dispatch(
                    updateEditModeStep(
                      {
                        step_id: stepID,
                        waiting_days : parseInt(e.target.value)
                      }
                    )
                  )
                }
              }
              type="number"
              min="1"
              max="100"
              initialValue={editMode.editingStepData.waiting_days ?? ""}
              id="waitingPeriod"
              tabIndex="0"
              className="w-full p-3 relative text-sm leading-none text-gray-600 bg-white rounded w-full h-12 py-2 outline-none border-2"
              aria-labelledby="waitingPeriod"
              error={editMode.errors.includes("waitingDays")}
            />
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
