import { configureStore } from "@reduxjs/toolkit"
import nurturingJourneyManager from "../features/nurturingJourneyManager"
import engagementHubManager from "../features/engagementHubManager"
import campaign from "../features/campaign"
import contactList from "../features/contactList"
import optionList from "../features/optionList"
import nurturingReport from "../features/nurturingReport"
import { useDispatch } from 'react-redux'
import report from "../features/report"
import abComparison from "../features/abComparison"
import unsubscribeList from "../features/unsubscribeList"
export const useAppDispatch = () => useDispatch()

export const store = configureStore(
    {
        reducer: {
            engagementHubManager: engagementHubManager,
            nurturingJourneyManager: nurturingJourneyManager,
            campaign: campaign,
            contactList: contactList,
            report: report,
            abComparison: abComparison,
            optionList: optionList,
            nurturingReport: nurturingReport,
            unsubscribeList: unsubscribeList
        }
    }
)

export default store