import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";
import { sortSteps } from "../utils/sortSteps";


export const defaultStepData = {
  //required for the update
  step_name: undefined,
  subject: undefined,
  pretext: undefined,
  template_id: undefined,
  waiting_days: undefined,
  reply_to_address: undefined,
  from_address: undefined,
  status_name: undefined,
  group_id: undefined,
  type_id: undefined,
  type_name: undefined,
  contact_id: undefined,
  drop_off: undefined,
  planned: undefined,
  render: undefined,
  sent: undefined,
  status_id: undefined,
  step_order: undefined,
  success_rating: undefined,
}
export const nurturingJourneyManagerInitialState = {
  journeysListAwaiting: false,
  journeysListLoaded: false,
  journeysList: [],

  journeyStepsAwaiting: false,
  journeyStepsLoaded: false,
  journeySteps: [],

  groupListAwaiting: false,
  groupListLoaded: false,
  groupList: [],

  stepListAwaiting: false,
  stepListLoaded: false,
  stepList: [],
  
  postUpdateAwaiting: false,
  filter: {
    groupId: undefined,
    groupName: "",
    contactListId: undefined,
    reach: undefined,
    contacts: []
  },

  outboundEmailData: {
    status: "idle",
    data: [],
    error: {},
  },
  
  editMode: {
    editing: false, //the master switch
    archived: false,
    modalStep: 0,
    editingStep: false, //controls if the edit/add modal is open
    editingStepData: defaultStepData, //a journeyStep, the one that is "in focus" in the edit/add modal WHEN IT IS BEING USED TO EDIT AN EXISTING STEP      
    addingStep: false, //as above
    addingStepData: defaultStepData,
    newStepPredecessor: {
      id: undefined,
      stepOrder: undefined,
      index: undefined
    },
    newStep: {},
    newSteps: [],
    updatedSteps: [], //the ids of steps which have been altered; less error-prone than, say, iterating over the two arrays and comparing each
    errors: [],
    dataSource: "Lead Data", //does not change (for now)
    reorderedElementsPayload: {}
  },
  contactListModalOpen: false
};
export const setJourneysListFromAPI = createAsyncThunk(
  "setJourneysListFromAPI",
  async (params, thunkAPI) => {
    const response = await SecureFetch(
      `${api_server}/nurturing`
    );
    const payload = await response.json();
    return payload.data ?? [];
  }
);

export const setJourneyStepsFromAPI = createAsyncThunk(
  "setJourneyStepsFromAPI",
  async (params, thunkAPI) => {
    if (params.groupId) {
      const response = await SecureFetch(
        `${api_server}/nurturing/steps?groupId=${params.groupId}&contactListId=${params.contactListId}`
      )
      if (response.status === 404) {
        thunkAPI.rejectWithValue()
      }
      const payload = await response.json()
      return {
        journeySteps: sortSteps(payload.data),
        filter: params,
      }
    } else {
      return {
        journeySteps: [],
        filter: {
          groupId: undefined,
          groupName: "",
        },
      };
    }
  }
);

export const addStep = createAsyncThunk("addStep", async (params, thunkAPI) => {
  const state = thunkAPI.getState().nurturingJourneyManager
  const response = await SecureFetch(
    `${api_server}/nurturing/`, "POST", JSON.stringify(params)
  )
  const responsePayload = await response.json()
  const newStep = {
    id: responsePayload.step_id,
    step_order: state.editMode.newStepPredecessor.stepOrder + 1
  }
  const reorderedElementsPayload = [];
  state.editMode.newSteps.forEach((step) => {
    if (step.step_order < newStep.step_order) {
      reorderedElementsPayload.push({
        id: step.id,
        step_order: step.step_order
      })
    } else {
      if(step.step_order >= newStep.step_order) {
        reorderedElementsPayload.push({
          id: step.id,
          step_order: step.step_order + 1
        })
      }
    }
  });
  reorderedElementsPayload.push(newStep)
  thunkAPI.dispatch(setReorderedElementsPayload(reorderedElementsPayload))
  thunkAPI.dispatch(
    modifyStepOrder()
  )

  return {
    ...params,
    ...responsePayload
  }
})

export const updateStep = createAsyncThunk("updateStep", async (params) => {
  const stepID = params.step_id
  const payload = Object.assign({}, params)
  delete payload.step_id
  const response = await SecureFetch(`${api_server}/nurturing/step/${stepID}`, "PATCH", JSON.stringify(payload));
  if (!response.ok) {
    throw new Error(`Update to step ${params.step_name} failed`)
  }
  return {
    step: payload,
    step_id: stepID
  }
})

export const modifyStepOrder = createAsyncThunk("modifyStepOrder", async (params, thunkAPI) => {
  if (params) {
    const {fromIndex, toIndex} = params
    thunkAPI.dispatch(modifyStepOrderLocally({fromIndex, toIndex}))
  }
  const state = thunkAPI.getState().nurturingJourneyManager
  const response = await SecureFetch(
    `${api_server}/nurturing/${state.filter.groupId}`,
    "PATCH",
    JSON.stringify(state.editMode.reorderedElementsPayload)
  );
  const payload = await response.json();

  const mergedSteps = payload.data.map((payloadStep) => {
    const payloadStepID = payloadStep.id ?? payloadStep.step_id
    const localStep = state.editMode.newSteps.find(
      (localStep) => {
        return payloadStepID === localStep.id ?? localStep.step_id
      }
    )

    return {
      ...localStep,
      ...payloadStep,
    }
  })

  return sortSteps(mergedSteps)
});

export const getOutboundEmail = createAsyncThunk(
  "getOutboundEmail", async (params) => {
    const response = await SecureFetch(`${api_server}/campaign/email`);
    const payload = await response.json();
    return {
      data: payload ?? []
    }
  }
)

export const nurturingJourneyManager = createSlice({
  name: "nurturingJourneyManager",
  initialState: nurturingJourneyManagerInitialState,
  reducers: {
    setReorderedElementsPayload: (state, action) => {
      state.editMode.reorderedElementsPayload = action.payload
    },
    startEditMode: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          editing: true,
          newSteps: [].concat(state.journeySteps),
        },
      };
    },
    endEditMode: (state) => {
      const newSteps = [].concat(state.editMode.newSteps)
      return {
        ...state,
        editMode: {
          ...state.editMode,
          editing: false,
          newSteps: [],
        },
        journeySteps: newSteps
      };
    },
    setAddingStep: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          addingStep: action.payload,
        }
      };
    },
    setEditingStep: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          editingStep: action.payload,
        }
      };
    },
    setEditingStepData: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          editingStepData: {
            ...state.editMode.editingStepData,
            ...action.payload
          }
        }
      };
    },
    setModalStep: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          modalStep: action.payload,
        },
      };
    },
    archiveStep: (state, action) => {
      return produce(state, (draft) => {
        const stepIndex = action.payload
        draft.editMode.archived = true
        draft.editMode.newSteps = draft.editMode.newSteps.map(
          (v, i) => {
            if (i > stepIndex) {
              return {
                ...v,
                step_order: v.step_order - 1
              }
            } else {
              return v
            }
          }
        )
        /*
        finds uniqueness by comparing the id of each element
        */
        let uniqueUpdatedSteps = [...new Set(draft.editMode.updatedSteps.map(
          (item) => {
            return (item?.id ?? item)
          }
        ))]
        uniqueUpdatedSteps.push(draft.editMode.newSteps[stepIndex].id)
        draft.editMode.updatedSteps = uniqueUpdatedSteps
        draft.editMode.newSteps = [
          ...draft.editMode.newSteps.slice(0, stepIndex),
          ...draft.editMode.newSteps.slice(stepIndex + 1)
        ]
        draft.journeySteps = sortSteps(draft.editMode.newSteps)
      });
    },
    setContactListModalOpen: (state, action) => {
      return {
        ...state,
        contactListModalOpen: action.payload,
      };
    },
    updateAddingModalDraftData: (state, action) => {
      return {
        ...state,
        editMode: {
          ...state.editMode,
          addingStepData: {
            ...state.editMode.addingStepData,
            ...action.payload,
          }
        }
      }
    },
    resetAddingModalDraftData: (state) => {
      state.editMode.addingStepData = defaultStepData
    },
    setErrors: (state, action) => {
      state.editMode.errors = action.payload
    },
    addError: (state, action) => {
      state.editMode.errors.push(action.payload)
    },
    removeError: (state, action) => {
      const index = state.editMode.errors.indexOf(action.payload)
      state.editMode.errors.splice(index, 1)
    },
    removeErrors: (state) => {
      state.editMode.errors = []
    },
    setNewStepPredecessor: (state, action) => {
      return produce(state, (draft) => {
        draft.editMode.newStepPredecessor = action.payload;
      })
    },
    modifyStepOrderLocally: (state, action) => {
      const fromIndex = action.payload.fromIndex
      const toIndex = action.payload.toIndex
      const reorderedElementsPayload = []
      let newSteps = []
      const fromIndexStepOrder = state.editMode.newSteps[fromIndex].step_order
      const toIndexStepOrder = state.editMode.newSteps[toIndex].step_order
      if (fromIndex !== toIndex) {
        
        if (fromIndex > toIndex) { //moving an element UP the page
          state.editMode.newSteps.forEach((step, i) => {
            const id = step.id ?? step.step_id
            const stepStepOrder = step.step_order
            if (stepStepOrder === fromIndexStepOrder) {
              newSteps.push({
                ...step,
                step_order: toIndexStepOrder
              })
              reorderedElementsPayload.push({
                id: id,
                step_order: toIndexStepOrder
              })
            } else {
              if (stepStepOrder < fromIndexStepOrder && stepStepOrder >= toIndexStepOrder) {
                newSteps.push({
                  ...step,
                  step_order: step.step_order + 1
                })
                reorderedElementsPayload.push({
                  id: id,
                  step_order: step.step_order + 1
                })
              } else {
                newSteps.push(step)
              }
            }
          })
        }

        if (toIndex > fromIndex) { //moving an element DOWN the page
          state.editMode.newSteps.forEach((step, i) => {
            const id = step.id ?? step.step_id
            const stepStepOrder = step.step_order
            if (stepStepOrder === fromIndexStepOrder) {
              newSteps.push({
                ...step,
                step_order: toIndexStepOrder
              })
              reorderedElementsPayload.push({
                id: id,
                step_order: toIndexStepOrder
              })
            } else {
              if (stepStepOrder > fromIndexStepOrder && stepStepOrder <= toIndexStepOrder) {
                newSteps.push({
                  ...step,
                  step_order: step.step_order - 1
                })
                reorderedElementsPayload.push({
                  id: id,
                  step_order: step.step_order - 1
                })
              } else {
                newSteps.push(step)
              }
            }
          })
        }
      }
      state.editMode.newSteps = sortSteps(newSteps)
      state.editMode.reorderedElementsPayload = reorderedElementsPayload
    },
    updateEditModeStep: (state, action) => {
      state.editMode.editingStepData = {
        ...state.editMode.editingStepData,
        ...action.payload
      }
      if (state.editMode.updatedSteps.includes(action.payload.step_id)) {
        state.editMode.updatedSteps.push(action.payload.step_id)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setJourneysListFromAPI.pending, (state) => {
        state.journeysListAwaiting = true;
        state.journeysListLoaded = false;
      })
      .addCase(setJourneysListFromAPI.fulfilled, (state, action) => {
        state.journeysListAwaiting = false;
        state.journeysListLoaded = true;
        state.journeysList = action.payload;
      })
      .addCase(setJourneysListFromAPI.rejected, (state, action) => {
        state.journeysListAwaiting = false;
        state.journeysListLoaded = true;
        state.data = [];
      })

      .addCase(setJourneyStepsFromAPI.pending, (state) => {
        state.journeyStepsAwaiting = true;
        state.journeyStepsLoaded = false;
      })
      .addCase(setJourneyStepsFromAPI.fulfilled, (state, action) => {
        state.journeyStepsAwaiting = false;
        state.journeyStepsLoaded = true;
        state.journeySteps = action.payload.journeySteps
        state.filter = {
          ...state.filter,
          ...action.payload.filter,
        };
      })
      .addCase(setJourneyStepsFromAPI.rejected, (state, action) => {
        state.journeyStepsAwaiting = false;
        state.journeyStepsLoaded = true;
        state.journeySteps = [];
      })
      .addCase(addStep.fulfilled, (state, action) => {
        return produce(state, (draft) => {
          draft.editMode.editingStepData = action.payload;
          draft.newSteps = [action.payload].concat(draft.newSteps)
          draft.journeySteps = draft.newSteps
        })
      })
      .addCase(addStep.rejected, (state, action) => {
        state.journeySteps = [];
      })

      .addCase(updateStep.fulfilled, (state, action) => {
        return produce(state, (draft) => {
          const stepIndex = draft.editMode.newSteps.findIndex((v) => {
            return (v.step_id ?? v.id) === action.payload.step_id
          })
          draft.editMode.newSteps[stepIndex] = {
            ...draft.editMode.newSteps[stepIndex],
            ...action.payload.step
          }
          draft.editMode.editingStepData = {
            ...draft.editMode.editingStepData,
            ...action.payload.step
          }
          if (draft.editMode.updatedSteps.includes(action.payload.step_id)) {
            draft.editMode.updatedSteps.push(action.payload.step_id)
          }
          draft.editMode.updatedSteps.forEach(
            (step) => {
              const index = draft.editMode.newSteps.findIndex((draftStep) => {
                return (draftStep.step_id ?? draftStep.id) === step
              })
              const stepData = action.payload.step
              if (stepData.wait_days) {
                stepData.waiting_days = stepData.wait_days
                delete stepData.wait_days
              }
              draft.editMode.newSteps[index] = {
                ...draft.editMode.newSteps[index],
                ...stepData
              }
            }
          )
        })
      })
      .addCase(updateStep.rejected, (state, action) => {
        state.journeySteps = [];
      })

      .addCase(modifyStepOrder.rejected, (state, action) => {
        state.editMode.newSteps = []
      })
      .addCase(modifyStepOrder.fulfilled, (state, action) => {
        state.editMode.newSteps = sortSteps(action.payload)
      });

      builder.addCase(getOutboundEmail.pending, (state) => {
        state.outboundEmailData.data = [];
        state.outboundEmailData.status = "loading";
      });
      builder.addCase(getOutboundEmail.fulfilled, (state, action) => {
        state.outboundEmailData.data = action.payload.data;
        state.outboundEmailData.status = "loaded";
      });
      builder.addCase(getOutboundEmail.rejected, (state, action) => {
        state.outboundEmailData.status = "error";
        state.outboundEmailData.error = action.error.message;
      });
  },
});
export const {
  setReorderedElementsPayload,
  startEditMode,
  endEditMode,
  setAddingStep,
  setEditingStep,
  setEditingStepData,
  archiveStep,
  setEditModeJourneySteps,
  setContactListModalOpen,  
  setErrors,
  addError,
  updateAddingModalDraftData,
  resetAddingModalDraftData,
  removeError,
  removeErrors,
  setModalStep,
  setNewStepPredecessor,
  updateEditModeStep,
  modifyStepOrderLocally
} = nurturingJourneyManager.actions;

export const getData = (state) => state;

export default nurturingJourneyManager.reducer;