import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import StreamgraphModule from "highcharts/modules/streamgraph";
more(Highcharts);
StreamgraphModule(Highcharts);

function Funnel({data}) {
  if (data?.categories && data?.series) {
    const options = {
      chart: {
        type: "streamgraph",
        zoomType: "x",
        borderRadius: 20,
        margin: 40,
      },
      title: {
        floating: true,
        align: "center",
        style: {
          fontSize : "1.2rem",
          color: "#4b5563",
          fontWeight: "700"
        },
        text: "Nurturing Journey Step Report",
      },
      subtitle: {
        floating: true,
        align: "left",
      },
      xAxis: {
        type: "category",
        maxPadding: 15,
        crosshair: true,
        categories: data.categories,
        labels: {
          align: "left",
          reserveSpace: false,
          rotation: 270,
        },
        lineWidth: 0,
        margin: 20,
        tickWidth: 0,
      },
      yAxis: {
        visible: false,
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          label: {
            minFontSize: 5,
            maxFontSize: 15,
            style: {
              color: "rgba(255,255,255,0.75)",
            },
          },
          accessibility: {
            exposeAsGroupOnly: true,
          },
        },
      },
      series: data.series
    }

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    );
  }

  return <></>
}

export default Funnel;
