import {
  InboxIcon,
  UserIcon,
  CubeIcon,
  SwitchVerticalIcon,
  ChatAltIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import SemiCircleProgress from "../Reporting/SemiCircleProgress";
import CampaignStatusButton from "../CampaignModal/CampaignModalHeader/CampaignStatusButton";
import { useState } from "react";
import EngagementReportMainBody from "./EngagementReportMainBody";
import SecureFetch from "../SecureFetch";
import api_server from "../../axios/baseURL";
import { getReportMap } from "../../features/report";
import { useSelector,  useDispatch} from "react-redux";
import { useEffect } from "react";

function Steps({ data }) {
  const [open, setOpen] = useState(false);
  const [dropdownData, setDropdownData] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [stepsId, setStepsId] = useState("");
  const { type } = useSelector((state) => state.report.maps);
  const setReportData = async ({ stepsId, campaignId, nurturing }) => {
    if (!open) {
      const mapData = await SecureFetch(
        `${api_server}/report/maps/${stepsId}?dataSourceType=Region&stepsId=1&eventType=1`
      );
      const linksData = await SecureFetch(
        `${api_server}/report/links/${campaignId}?stepsId=${stepsId}&nurturing=${nurturing}`
      );
      const graphData = await SecureFetch(
        `${api_server}/report/graph/${campaignId}?stepsId=${stepsId}&nurturing=${nurturing}`
      );
      const reportData = await SecureFetch(
        `${api_server}/report/campaign/${campaignId}?stepsId=${stepsId}&nurturing=${nurturing}`
      );
      const mapPayload = await mapData.json();
      const linksPayload = await linksData.json();
      const graphPayload = await graphData.json();
      const reportPayload = await reportData.json();
      setDropdownData({
        mapData: mapPayload,
        linksData: linksPayload,
        graphData: graphPayload,
        reportData: reportPayload,
      });
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  };

  useEffect(() => {
    let eventType
    switch (type) {
      default:
        eventType = 3;
        break;
      case "Sent":
        eventType = 1;
        break;
      case "Delivery":
        eventType = 2;
        break;
      case "Opens":
        eventType = 3;
        break;
      case "Clicks":
        eventType = 4;
        break;
      case "Bounced":
        eventType = 5;
        break;
      case "Unsubscribed":
        eventType = 7;
        break;
    }
    if (stepsId) {
   const getMapData =  async () => { 
   const data = await SecureFetch(`${api_server}/report/maps/${stepsId}?dataSourceType=Region&stepsId=1&eventType=${eventType}`)
   const payload = await data.json()
   setDropdownData({ ...dropdownData, 'mapData' : payload })
   }
   getMapData()
   return () => {}
  }
  }, [type])

  return data ? (
    <div className="flex flex-wrap items-center justify-between w-full mb-6 rounded-xl shadow bg-white dark:bg-gray-800 border-2 border-gray-200">
      <div className="w-full rounded-xl">
        <div className="w-full h-full flex flex-wrap flex-col sm:flex-row">
          <div className="w-1/6">
            <div className="w-full  bg-white flex border-b-2 border-gray-200">
              <div className="m-2 w-full flex flex-wrap justify-between">
                <p className="ml-2 font-bold">{data.step_name}</p>
                {data.type_id === 1 ? (
                  <InboxIcon className="h-6 w-8 min-h-6 min-w-8 text-black" />
                ) : (
                  <ChatAltIcon className="h-6 w-8 min-h-6 min-w-8 text-black" />
                )}
              </div>
            </div>
            <div className="w-full bg-white flex border-b-2 border-gray-200">
              <div className="m-2 w-full flex flex-wrap justify-between">
                <p className="m-2">{data.subject}</p>
              </div>
            </div>
            <div className="w-full bg-white p-2 overflow-scroll scrollbar-hide">
              <div className="pb-2">
                <p className="text-sm ml-2">Sending Email:</p>
                <small className="ml-2">{data.from_address}</small>
              </div>
              <div>
                <p className="text-sm ml-2">Receiving Email:</p>
                <small className="ml-2">{data.reply_to_address}</small>
              </div>
              <div className="flex justify-end pt-4">
                <CampaignStatusButton status={data.status} />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-4/6">
            <div className="w-2/3 h-full p-4 flex bg-white">
              <div className="w-1/4 border-l-2 border-gray-200">
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.messages_send ?? 0}
                    </p>
                    <p className="flex justify-center">Messages Sent</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-purple-200 w-fit p-2 rounded-full">
                      <SwitchVerticalIcon className="w-8 h-8 text-ofg-purple" />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.bounced ?? 0}
                    </p>
                    <p className="flex justify-center">Bounced</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-purple-200 w-fit p-2 rounded-full">
                      <SwitchVerticalIcon className="w-8 h-8 text-ofg-purple" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.email_opened ?? 0}
                    </p>
                    <p className="flex justify-center">Emails Opened</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-blue-200 w-fit p-2 rounded-full">
                      <UserIcon className="w-8 h-8 text-blue-500" />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.click ?? 0}
                    </p>
                    <p className="flex justify-center">Clicks</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-blue-200 w-fit p-2 rounded-full">
                      <UserIcon className="w-8 h-8 text-blue-500" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.unsubscribed ?? 0}
                    </p>
                    <p className="flex justify-center">Unsubscribed</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-red-100 w-fit p-2 rounded-full">
                      <CubeIcon className="w-8 h-8 text-red-500" />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.unique_clicks ?? 0}
                    </p>
                    <p className="flex justify-center">Unique Clicks</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-red-100 w-fit p-2 rounded-full">
                      <CubeIcon className="w-8 h-8 text-red-500" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/4">
                <div className="h-1/2 flex flex-col justify-evenly">
                  <div className="flex justify-center flex-col">
                    <p className="flex justify-center font-bold">
                      {data.to_sent ?? 0}
                    </p>
                    <p className="flex justify-center">To Send</p>
                  </div>
                  <div className="flex justify-center">
                    <div className="bg-green-100 w-fit p-2 rounded-full">
                      <SwitchVerticalIcon className="w-8 h-8 text-primary-color-dark" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/3 bg-white container border-l-2 border-gray-200 w-full">
              <div className="h-full w-100 flex flex-col justify-center content-center">
                <SemiCircleProgress
                  strokeWidth={10}
                  diameter={180}
                  percentage={data.score ?? 0}
                  label={"Score"}
                  nurturing
                />
              </div>
            </div>
          </div>
          <div className="w-1/6 bg-white border-l-2 border-gray-200">
            <div className="flex content-center justify-center w-full h-auto overflow-hidden mt-10">
              <img src={data.template_render ?? 0}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-6 bg-gray-100">
        <button
          className="w-12 bg-white border-2-black cursor-pointer rounded-xl hover:bg-primary-color-dark hover:text-white flex justify-center"
          onClick={(e) => {
            setReportData({
              campaignId: data.campaign_id,
              stepsId: data.step_id,
              nurturing: true,
            });
            setStepsId(data.step_id);
            setOpen(!open);
          }}
        >
          <EyeIcon className="w-6 h-6 text-primary-color-dark hover:text-white hover:bg-primary-color-dark" />
        </button>
      </div>
      {open && (
        <div className="bg-white w-full">
          {loaded ? (
            <EngagementReportMainBody
              reportGraphData={dropdownData?.graphData?.data}
              reportMapData={dropdownData?.mapData?.data}
              reportLinksData={dropdownData?.linksData?.data}
              idData={dropdownData?.reportData?.data[0]}
              nurturing={true}
            />
          ) : (
            <div class="w-full h-52 flex justify-center items-center">
              <div className="w-16 h-16 border-solid rounded-full animate-spin border-4 border-primary-color-dark border-w-400 border-t-transparent mr-6"></div>
            </div>
          )}
        </div>
      )}
      <style>
        {`
          span.semicircle-percent-value,
          p.flex.text.justify-center.font-semibold {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .semicircle-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      </style>
    </div>
  ) : null;
}

export default Steps;
