import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";
import moment from "moment";

export const reportInitialState = {
  campaign: {
    name: "",
    id: "",
    data: []
  },
  campaigns: {
    status: "idle",
    data: [],
    selectedCampaignName: undefined,
    selectedCampaignId: undefined
  },
  maps: {
    type: "Sent",
  },
  regions: {
    status: "idle",
    selectedRegionIds: [],
    data: []
  },
  occurrences: {
    status: "idle",
    selectedOccurrenceIds: [],
    data: [],
    minDate: undefined,
    maxDate: undefined,
  },
  reportsByCampaign: {
      status: "idle",
      data: [],
      error: {},
  },
  occurrenceModal: {
    open: false,
    contacts: {
      data: [],
      status: "idle",
      error: {}
    },
    campaign: {
      data: {},
      status: "idle",
      error: {}
    }
  },
  reportRegionData : {
    status: "idle",
    data: [],
    error: {},
  },
  reportGraphData : {
    status: "idle",
    data: [],
    error: {},
  },
  reportLinksData : {
    status: "idle",
    data: [],
    error: {},
  },
  reportMapData : {
    status: "idle",
    data: [],
    error: {},
  },
  aggregatedCampaignData: {
    status: "idle",
    data: {}, //unlike all the other data structures, this should be a single, combined instance
    error: {}
  }
}

export const getAllCampaignsReportPage = createAsyncThunk(
  "getAllCampaignsReportPage",
  async () => {
    const response = await SecureFetch(`${api_server}/report/campaign`)
    const payload = await response.json()
    return {
      data: payload.data ?? [],
    };
  }
)
export const getReportByCampaignId = createAsyncThunk(
  "getReportByCampaignId",
  async (params) => {
    const { campaignId } = params;
    let queryString = `${api_server}/report/campaign/${campaignId}`;
    const response = await SecureFetch(queryString);
    const payload = await response.json();
    return {
      data: payload.data ?? [],
    };
  }
);

export const getOccurrencesByCampaignId = createAsyncThunk(
  "getOccurrencesByCampaignId",
  async (params) => {
    const { occurrenceId } = params;
    const response = await SecureFetch(
      `${api_server}/report/occurrence/list?id=${occurrenceId}`
    );
    const payload = await response.json();
    return {
      data: payload.data ?? [],
    };
  }
);

export const getAllRegions = createAsyncThunk(
  "getAllRegions",
  async () => {
    const response = await SecureFetch(`${api_server}/report/region`, "GET")
    const payload = await response.json()
    const regionData = payload.data.map(
      (v) => {
        return {
          ...v,
          disabled: false
        }
      }
    )
    const selectedRegionIds = payload.data.map(
      (v) => {
        return v.id
      }
    )
    return {
      data: regionData ?? [],
      selectedRegionIds: selectedRegionIds
    };
  }
)

export const getData = createAsyncThunk(
  "getData",
  async (params, thunkAPI) => {
    const sliceState = thunkAPI.getState().report
    const campaignId = sliceState.campaigns.selectedCampaignId
    let selectedOccurrenceIds = []
    const regionIds = sliceState.regions.selectedRegionIds
    let payload = []
    const minDate = sliceState.occurrences.minDate
    const maxDate = sliceState.occurrences.maxDate
    let newSelectedOccurrenceIds = []
    if (campaignId && regionIds.length) {
      if (!params?.subset) {
        const occurrencesResponse = await SecureFetch(`${api_server}/report/occurrence/list?id=${campaignId}`)
        const occurrencesPayload = await occurrencesResponse.json()
        selectedOccurrenceIds = occurrencesPayload.data.map(v => v.id)
      } else {
        selectedOccurrenceIds = sliceState.occurrences.selectedOccurrenceIds
      }
      const occurrenceString = selectedOccurrenceIds?.length ? `&stepsId=${selectedOccurrenceIds.join(",")}` : ""
      const regionString = `&regionId=${regionIds.join(",")}`
      const minDateString = minDate ?? moment(new Date(0)).format("YYYY-MM-DD")
      const maxDateString = maxDate ?? moment(Date.now() + (1000 * 60 * 60 * 24 * 365 * 1000)).format("YYYY-MM-DD") //1000 years in the future
      const dateString = `&dateRange=${minDateString},${maxDateString}`
      const fullURL = `${api_server}/report/campaign/${campaignId}?occurrences=true${regionString}${dateString}${occurrenceString}`
      const response = await SecureFetch(fullURL)
      payload = await response.json()
      payload.data.forEach(
        (occurrence, occurrenceIndex) => {
          Object.entries(occurrence).forEach(([k, v]) => {
            if (!v) {
              occurrence[k] = 0
            }
          })
          if (params.subset === false) {
            newSelectedOccurrenceIds.push(occurrence.id)
          }
          payload.data[occurrenceIndex] = occurrence
        }
      )
      if (params.subset === true) {
        newSelectedOccurrenceIds = selectedOccurrenceIds
      }
    }

    return {
      campaignId: campaignId,
      campaignName: sliceState.campaigns.selectedCampaignName,
      data: payload?.data ?? [],
      subset: params?.subset ?? true,
      newSelectedOccurrenceIds: newSelectedOccurrenceIds
    }
  }
)

export const getContactsList = createAsyncThunk("getContactsList", async (params) => {
  const response = await SecureFetch(`${api_server}/contacts-list?campaign_id=${params.campaignId}`);
  const payload = await response.json();
  return {
    data: payload.data ?? [],
  };
});

export const getCampaignData = createAsyncThunk("getCampaignData", async (params) => {
  const response = await SecureFetch(`${api_server}/campaign/${params.campaignId}`);
  const payload = await response.json();
  return {
    data: payload.data ?? [],
  };
});
export const getReportRegion = createAsyncThunk(
  "getReportRegion", async (params) => {
    const response = await SecureFetch(`${api_server}/report/region`)
    const payload = await response.json()
    return {
      data: payload ?? []
    }
  }
)

export const getReportGraph = createAsyncThunk(
  "getReportGraph", async (params) => {
    const { campaignId, occurrenceId, regionId } = params
    let queryString = `${api_server}/report/graph/${campaignId}`
    if (occurrenceId) {
      queryString = `${api_server}/report/graph/${occurrenceId}`
    }
    if (regionId) {
      queryString.concat(`?regionId=${regionId}`)
    }
    const response = await SecureFetch(queryString)
    const payload = await response.json()
    return {
      data: payload ?? []
    }
  }
 )

 export const getReportMap = createAsyncThunk("getReportMap", async (params) => {
  let { campaignId, dataSourceType, type, stepsId } = params;
  let eventType;
  switch (type) {
    default:
      eventType = 3;
      break;
    case "Sent":
      eventType = 1;
      break;
    case "Delivery":
      eventType = 2;
      break;
    case "Opens":
      eventType = 3;
      break;
    case "Clicks":
      eventType = 4;
      break;
    case "Bounced":
      eventType = 5;
      break;
    case "Unsubscribed":
      eventType = 7;
      break;
  }
  let queryString = `${api_server}/report/maps/${campaignId}?dataSourceType=${dataSourceType}`;
  if (eventType) {
      queryString = queryString + `&eventType=${eventType}`
  }
  if (stepsId) {
    queryString = queryString + `&stepsId=${stepsId}`
  }
  const response = await SecureFetch(queryString);
  const payload = await response.json();
  return {
    data: payload ?? [],
  };
});

export const getReportLinks = createAsyncThunk(
  "getReportLinks", async (params) => {
    const { campaignId } = params
    const response = await SecureFetch(`${api_server}/report/links/${campaignId}`)
    const payload = await response.json()
    return {
      data: payload ?? []
    }
  }
 )

export const getAggregatedCampaignData = createAsyncThunk(
  "getAggregatedCampaignData",
  async (campaignId) => {
    const response = await SecureFetch(`${api_server}/report/campaign/${campaignId}?occurrences=false`)
    const payload = await response.json()
    return {
      data: payload.data[0] ?? {},
    }
  }
);


export const report = createSlice({
  name: "report",
  initialState: reportInitialState,
  reducers: {
    updateReport: (state, action) => {
      state.report = { ...state.report, ...action.payload }
    },
    resetReport: (state) => {
      state.report = reportInitialState
    },
    toggleCampaignsRegionId: (state, action) => {
      const elementIndex = state.regions.selectedRegionIds.indexOf(action.payload)
      if (elementIndex === -1) {
        state.regions.selectedRegionIds.push(action.payload)
      } else {
        state.regions.selectedRegionIds.splice(elementIndex, 1)
      }
    },
    addCampaignsRegionId: (state, action) => {
      if (!state.regions.selectedRegionIds.includes(action.payload.id)) {
        state.regions.selectedRegionIds.push(action.payload.id)
      }
    },
    addCampaignsOccurrenceId: (state, action) => {
      if (!state.occurrences.selectedOccurrenceIds.includes(action.payload.id)) {
        state.occurrences.selectedOccurrenceIds.push(action.payload.id)
      }
    },
    setSelectedCampaignData: (state, action) => {
      state.campaigns.selectedCampaignId = action.payload.campaignId
      state.campaigns.selectedCampaignName = action.payload.campaignName
    },
    setSelectedMapData: (state, action) => {
      state.maps = {...state.maps, ...action.payload}
    },
    toggleOccurrenceSelection: (state, action) => {
      const elementIndex = state.occurrences.selectedOccurrenceIds.indexOf(action.payload)
      if (elementIndex === -1) {
        state.occurrences.selectedOccurrenceIds.push(action.payload)
      } else {
        state.occurrences.selectedOccurrenceIds.splice(elementIndex, 1)
      }
    },
    setMinDate: (state, action) => {
      state.occurrences.minDate = action.payload
    },
    setMaxDate: (state, action) => {
      state.occurrences.maxDate = action.payload
    },
    setModalOpen: (state, action) => {
      state.occurrenceModal.open = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state) => {
      state.occurrences.status = "loading"
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      state.occurrences.status = "loaded"
      // if (!action.payload.subset) {
        state.occurrences.data = action.payload.data
      // }
      state.occurrences.selectedOccurrenceIds = action.payload.newSelectedOccurrenceIds
    });
    builder.addCase(getData.rejected, (state, action) => {
      state.occurrences.status = "error"
      state.occurrences.error = action.error.message
    });
    builder.addCase(getAllCampaignsReportPage.pending, (state) => {
      state.campaigns.data = []
      state.campaigns.status = "loading"
    });
    builder.addCase(getAllCampaignsReportPage.fulfilled, (state, action) => {
      state.campaigns.data = action.payload.data
      state.campaigns.status = "loaded"
    });
    builder.addCase(getAllCampaignsReportPage.rejected, (state, action) => {
      state.campaigns.status = "error"
      state.campaigns.error = action.error.message
    });



    builder.addCase(getAllRegions.pending, (state) => {
      state.regions.data = []
      state.regions.status = "loading"
    });
    builder.addCase(getAllRegions.fulfilled, (state, action) => {
      state.regions.data = action.payload.data
      state.regions.status = "loaded"
      state.regions.selectedRegionIds = action.payload.selectedRegionIds
    });
    builder.addCase(getAllRegions.rejected, (state, action) => {
      state.regions.status = "error"
      state.regions.error = action.error.message
      state.regions.selectedRegionIds = []
    });



    builder.addCase(getContactsList.pending, (state) => {
      state.occurrenceModal.contacts.data = [];
      state.occurrenceModal.contacts.status = "loading";
    });
    builder.addCase(getContactsList.fulfilled, (state, action) => {
      state.occurrenceModal.contacts.data = action.payload.data;
      state.occurrenceModal.contacts.status = "loaded";
    });
    builder.addCase(getContactsList.rejected, (state, action) => {
      state.occurrenceModal.contacts.data = []
      state.occurrenceModal.contacts.status = "error";
      state.occurrenceModal.contacts.error = action.error.message;
    });


    
    builder.addCase(getCampaignData.pending, (state) => {
      state.occurrenceModal.campaign.data = [];
      state.occurrenceModal.campaign.status = "loading";
    });
    builder.addCase(getCampaignData.fulfilled, (state, action) => {
      state.occurrenceModal.campaign.data = action.payload.data;
      state.occurrenceModal.campaign.status = "loaded";
    });
    builder.addCase(getCampaignData.rejected, (state, action) => {
      state.occurrenceModal.campaign.data = []
      state.occurrenceModal.campaign.status = "error";
      state.occurrenceModal.campaign.error = action.error.message;
    });
    builder.addCase(getReportByCampaignId.pending, (state) => {
      state.reportsByCampaign.data = [];
      state.reportsByCampaign.status = "loading";
    });
    builder.addCase(getReportByCampaignId.fulfilled, (state, action) => {
      state.reportsByCampaign.data = action.payload.data.data;
      state.reportsByCampaign.status = "loaded";
    });
    builder.addCase(getReportByCampaignId.rejected, (state, action) => {
      state.reportsByCampaign.status = "error";
      state.reportsByCampaign.error = action.error.message;
    });
    
    builder.addCase(getReportRegion.pending, (state) => {
      state.reportRegionData.data = [];
      state.reportRegionData.status = "loading";
    });
    builder.addCase(getReportRegion.fulfilled, (state, action) => {
      state.reportRegionData.data = action.payload.data.data;
      state.reportRegionData.status = "loaded";
    });
    builder.addCase(getReportRegion.rejected, (state, action) => {
      state.reportRegionData.status = "error";
      state.reportRegionData.error = action.error.message;
    });
    builder.addCase(getReportGraph.pending, (state) => {
      state.reportGraphData.data = [];
      state.reportGraphData.status = "loading";
    });
    builder.addCase(getReportGraph.fulfilled, (state, action) => {
      state.reportGraphData.data = action.payload.data.data;
      state.reportGraphData.status = "loaded";
    });
    builder.addCase(getReportGraph.rejected, (state, action) => {
      state.reportLinksData.status = "error";
      state.reportLinksData.error = action.error.message;
    });
    builder.addCase(getReportLinks.pending, (state) => {
      state.reportLinksData.data = [];
      state.reportLinksData.status = "loading";
    });
    builder.addCase(getReportLinks.fulfilled, (state, action) => {
      state.reportLinksData.data = action.payload.data.data;
      state.reportLinksData.status = "loaded";
    });
    builder.addCase(getReportLinks.rejected, (state, action) => {
      state.reportLinksData.status = "error";
      state.reportLinksData.error = action.error.message;
    });
    builder.addCase(getReportMap.pending, (state) => {
      state.reportMapData.data = [];
      state.reportMapData.status = "loading";
    });
    builder.addCase(getReportMap.fulfilled, (state, action) => {
      state.reportMapData.data = action.payload.data.data;
      state.reportMapData.status = "loaded";
    });
    builder.addCase(getReportMap.rejected, (state, action) => {
      state.reportMapData.status = "error";
      state.reportMapData.error = action.error.message;
    });

    builder.addCase(getAggregatedCampaignData.pending, (state) => {
      state.aggregatedCampaignData.data = {};
      state.aggregatedCampaignData.status = "loading";
    });
    builder.addCase(getAggregatedCampaignData.fulfilled, (state, action) => {
      state.aggregatedCampaignData.data = action.payload.data;
      state.aggregatedCampaignData.status = "loaded";
    });
    builder.addCase(getAggregatedCampaignData.rejected, (state, action) => {
      state.aggregatedCampaignData.status = "error";
      state.aggregatedCampaignData.error = action.error.message;
    });
  }
});

export const {
  updateReport,
  resetReport,
  toggleOccurrenceSelection,
  toggleCampaignsRegionId,
  addCampaignsRegionId,
  addCampaignsOccurrenceId,
  setSelectedCampaignData,
  setSelectedMapData,
  setMinDate,
  setMaxDate,
  setModalOpen,
} = report.actions;

export default report.reducer;