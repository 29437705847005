import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import axios from 'axios';

export const unsubscribeListInitialState = {
  getUnsubscribeList : {
    status: "idle",
    data: [],
    error: {}
  }
};

export const getUnsubscribeList = createAsyncThunk(
  "getUnsubscribeList",
  async (params, thunkAPI) => {
    var config = {
      method: 'get',
      url: `${api_server}/unsubscribe`,
      headers: { 
        'Content-Type': 'application/json'
      }
    };
    var payload = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.error(error);
        return error;
      });
    return {
      data: payload ?? []
    }
  }
)

export const unsubscribeList = createSlice({
  name: "unsubscribeList",
  initialState: unsubscribeListInitialState,
  reducers: {
    getUnsubscribe: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnsubscribeList.pending, (state) => {
        state.getUnsubscribeList.data = [];
        state.getUnsubscribeList.status = "loading";
      })
      .addCase(getUnsubscribeList.fulfilled, (state, action) => {
        state.getUnsubscribeList.data = action.payload.data;
        state.getUnsubscribeList.status = "loaded";
      })
      .addCase(getUnsubscribeList.rejected, (state) => {
        state.getUnsubscribeList.status = "error";
        state.getUnsubscribeList.error = action.error.message;
      });
  },
});

export const {
  getUnsubscribe
} = unsubscribeList.actions;

export const getData = (state) => state;

export default unsubscribeList.reducer;
