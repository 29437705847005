export default async function SecureFetch(url, method, body) {
    const auth = JSON.parse(localStorage.getItem("token")); 
    const response = await fetch(url, { 
        method: method || "GET",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": 'bearer '+auth?.token
        }),
        body: body || null
    }).then((response) => {
        if (response.status == 401) {
            window.location.href = "/login";
            return false;
        }
        return response;
    }).catch((error) => {
        console.error(error);
        return false;
    })
    return response;
}