import React, {useEffect, useState} from 'react';
import { CSVLink } from 'react-csv'
import Button from './Button';
import { DocumentDownloadIcon } from '@heroicons/react/outline';

export default function DownloadLinks({data, fileName}) {

  const [disabled, setDisable] = useState(true);
  const [formatedData, setFormatedData] = useState([]);

  useEffect(() => {
    data.length > 0 ? setDisable(false) : setDisable(true)
  }, [data])

  const formatCsvData = (data) => {
    let csvData;
      csvData = [['Link'],['Total Clicks'],['Unique Clicks']];
      data.forEach(link => {
        csvData[0].push(link.event_data);
        csvData[1].push(link.total_click);
        csvData[2].push(link.unique_clicks);
      })
    setFormatedData(csvData)
  }

  return(
    <div className={'mx-2'}>
      {disabled ?
        (<Button interior={<DocumentDownloadIcon className="w-6 h-6 text-white" /> } disabled={disabled} backgroundColorOverride={4}></Button>) : 
        (<CSVLink data={formatedData} filename={fileName} onClick={()=>formatCsvData(data)}>
          <Button interior={<DocumentDownloadIcon className="w-6 h-6 text-white" /> } disabled={disabled}></Button>
        </CSVLink>)
      }
    </div>
  )

}