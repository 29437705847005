import { useState, useEffect, useRef } from "react";
import _debounce from "debounce";
import {
  FolderAddIcon,
  FilterIcon,
} from "@heroicons/react/outline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Calendar from "./Calendar";
import Dropdown from "./Dropdown";
import { useAppDispatch } from "../redux/store";
import { setDataFromAPI, orderBy, toggleModal } from "../features/engagementHubManager"
import {useSelector} from "react-redux"
import { parseGetTimeToDateString } from "../utils/dates";
import { getOrderBy, getCampaignType, getCampaignStatus, getDateString } from "../utils/getStrings";
import TextBox from "./TextBox";
import Button from "./Button";
import FilterButton from "./FilterButton";
import { getStatus, getType } from "./../features/campaign";
import moment from "moment";

const EngagementHubManagerSearch = () => {
  const inputRef = useRef(null)
  const dispatch = useAppDispatch()
  const {filter, modalVisible} = useSelector((state) => state.engagementHubManager)
  const {statusData, typeData} = useSelector((state) => state.campaign);
  const [statuses, setStatuses] = useState([1])
  const previousStatuses = useRef(statuses).current
  const [typeList, setTypeList] = useState([])
  const previousTypeList = useRef(typeList).current

  useEffect(() => {
    if (previousStatuses.current && statuses !== previousStatuses.current) {
      dispatch(
        setDataFromAPI(
          {
            ...filter,
            status_id: statuses
          }
        )
      )
    }
    previousStatuses.current = statuses
  }, [statuses, previousStatuses])

  useEffect(() => {
    if (previousTypeList.current && typeList !== previousTypeList.current) {
      dispatch(
        setDataFromAPI(
          {
            ...filter,
            type_id :typeList
          }
        )
      )
    }
    previousTypeList.current = typeList
  }, [typeList, previousTypeList])

  const searchTextboxOnKeyUp = async () => {
    //setTouched(true)
    _debounce(
      dispatch(
        setDataFromAPI(
          {
            ...filter,
            search_name: inputRef.current.value
          }
        )
      )
    )
  }

  useEffect(() => {
    dispatch(getStatus());
    dispatch(getType());
  }, []);    

  const visibleFilters = (filter) => {
    const filterButtons = []
    if (filter.created_date || filter.start_date || filter.type_id || filter.status_id || filter.order_by) {
      if (filter.created_date) {
        filterButtons.push(
          <FilterButton
            key={filterButtons.length}
            index={filterButtons.length}
            onClick={() => {
              //setTouched(true)
              dispatch(setDataFromAPI(
                {
                  ...filter,
                  created_date: undefined
                }
              ))
            }}
            text={`Created After: ${parseGetTimeToDateString(filter.created_date, true)}`}
           />
        )
      }
      if (filter.start_date) {
        filterButtons.push(
          <FilterButton
            key={filterButtons.length}
            index={filterButtons.length}
            onClick={() => {
              //setTouched(true)
              dispatch(setDataFromAPI(
                {
                  ...filter,
                  start_date: undefined
                }
              ))
            }}
            text={`Start Date After: ${parseGetTimeToDateString(filter.start_date, true)}`}
           />
        )
      }
      
      if (filter.type_id) {
        filter.type_id.forEach(element => {
          filterButtons.push(
            <FilterButton
              key={filterButtons.length}
              index={filterButtons.length}
              onClick={() => {
                //setTouched(true)
                const unique_types = new Set([...typeList, element])
                unique_types.delete(element)
                setTypeList([...unique_types])
              }}
              text={`Campaign Type: ${getCampaignType(element, true)}`}
            />
          )
        });
       
      }
      
      if (filter.status_id && filter.status_id.length) {
        const status_ids = filter.status_id;

        status_ids.forEach(status_id => {
          
          filterButtons.push(
            <FilterButton
              key={filterButtons.length}
              index={filterButtons.length}
              onClick={() => {
                //setTouched(true)
                setStatuses(status_ids.filter(id => id != status_id))
              }}
              text={`Status: ${getCampaignStatus(status_id, true)}`}
             />
          )
        });
      }

      return (
        <div className="flex flex-wrap items-center p-2">
          {filterButtons.map(v => v)}
        </div>
      )
    }
  }


  return (
    <>
      <div className="border-b-2 border-primary-color-dark bg-white">
        <div className="flex items-center justify-between p-2">
          <div className="relative right-4 flex items-center justify-start w-search">
            <TextBox
              icon={(
                <svg
                  className="z-20 cursor-pointer relative left-8 w-4"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2716 13.1684L11.3313 10.2281C12.0391 9.28574 12.4213 8.13865 12.42 6.96C12.42 3.94938 9.97063 1.5 6.96 1.5C3.94938 1.5 1.5 3.94938 1.5 6.96C1.5 9.97063 3.94938 12.42 6.96 12.42C8.13865 12.4213 9.28574 12.0391 10.2281 11.3313L13.1684 14.2716C13.3173 14.4046 13.5114 14.4756 13.711 14.47C13.9105 14.4645 14.1004 14.3827 14.2415 14.2415C14.3827 14.1004 14.4645 13.9105 14.47 13.711C14.4756 13.5114 14.4046 13.3173 14.2716 13.1684ZM3.06 6.96C3.06 6.18865 3.28873 5.43463 3.71727 4.79328C4.14581 4.15192 4.7549 3.65205 5.46754 3.35687C6.18017 3.06169 6.96433 2.98446 7.72085 3.13494C8.47738 3.28542 9.17229 3.65686 9.71772 4.20228C10.2631 4.74771 10.6346 5.44262 10.7851 6.19915C10.9355 6.95567 10.8583 7.73983 10.5631 8.45247C10.268 9.1651 9.76808 9.77419 9.12673 10.2027C8.48537 10.6313 7.73135 10.86 6.96 10.86C5.92604 10.8588 4.93478 10.4475 4.20365 9.71635C3.47253 8.98522 3.06124 7.99396 3.06 6.96Z"
                    fill="#4B5563"
                  />
                </svg>)
              }
              type="search"
              placeholder="Search"
              reference={inputRef}
              onKeyUp={searchTextboxOnKeyUp}
            />
          </div>
          <div className="flex items-center">
            <div className="mx-2">
              <Button onClick={
                () => {
                  dispatch(toggleModal(!modalVisible))
                }}
                interior={<FolderAddIcon className="w-6 h-6 text-white" />}
                backgroundColorOverride={1}
                margins="vertical" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="p-2 flex justify-between">
            <Calendar
              type={DatePicker}
              buttonText="Set Created Date"
              onChange={
                (newValue) => {                   
                  //setTouched(true) 
                  if (moment(newValue, "DD/MM/YYYY").isValid()) {
                    dispatch(
                      setDataFromAPI(
                        {
                          ...filter,
                          created_date: getDateString(newValue)
                        }
                      )
                    )
                  }
              }}/>
            <Calendar
              type={DatePicker}
              buttonText="Set Campaign Date"
              onChange={(newValue) => {
                //setTouched(true)
                if (moment(newValue, "DD/MM/YYYY").isValid()) {
                  dispatch(
                    setDataFromAPI(
                      {
                        ...filter,
                        start_date: getDateString(newValue)
                      }
                    )
                  )
                }
              }} />
          </div>
          <div className="flex flex-wrap justify-start">
            <div className="p-2 pr-0">
              <Dropdown
                placeHolder="Campaign Type"
                options={typeData.data.data}
                optionOnClick = {async (e) => {
                  //setTouched(true)
                  const campaignTypeID = parseInt(e.currentTarget.dataset.value)
                  const uniqueTypes = new Set([...typeList, campaignTypeID])
                  setTypeList([...uniqueTypes])
                }}
              />
            </div>
            <div className="pt-2 pb-2">
              <Dropdown
                placeHolder="Status" 
                options={statusData.data.data}
                optionOnClick = {async (e) => {
                  //setTouched(true)
                  const status =  parseInt(e.currentTarget.dataset.value);
                  const uniqueStatuses = new Set([...statuses, status])
                  setStatuses([...uniqueStatuses])
                }}
                />
            </div>
            <div className="pt-2 pb-2">
              <Dropdown
                placeHolder="Order by"
                options={["Status", "Oldest first", "Next Occurrence"]}
                optionOnClick = {async (e) => {
                  //setTouched(true)
                  dispatch(
                    orderBy(e.currentTarget.dataset.value.toLocaleLowerCase())
                  )
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-2 text-s">
          Ordered by: <strong>{getOrderBy(filter.order_by, true)}</strong>
        </div>
        {visibleFilters(filter)}
      </div>
    </>
  );
};
export default EngagementHubManagerSearch;
