import Dropdown from "../Dropdown";
import { useSelector, useDispatch } from "react-redux";
import Heading2 from "../Heading2";
import Label from "../Label";
import _ from "lodash";
import { setEditingStepData } from "../../features/nurturingJourneyManager";

function TemplateDesign({validated}) {

  if (!validated.includes(0)) {
    var list = validated;
    list.push(0);
  }

  const {templateData} = useSelector((state) => state.campaign);
  const editMode = useSelector((state) => state.nurturingJourneyManager.editMode )
  const dispatch = useDispatch();
  let templateList = templateData.data.map((template) => {
    return {
      text : template.template_name,
      value: template.template_id,
    };
  });

  const templateInfo = templateData.data.filter(
    (option) => {
      return option.template_id === editMode.editingStepData.template_id
    }
  );
  if (templateInfo.length) {
    createMarkup()
  }
  
  function createMarkup() {
    return { __html: templateInfo[0]?.template_body };
  }
  
  const stepID = editMode.editingStepData.id ?? editMode.editingStepData.step_id
  return (
    <>
      <div className="w-full min-h-fit flex justify-start border-b border-gray-200 pb-30" style={{paddingBottom: "30px"}}>
        <div className="w-64">
          <div className="flex items-center">
            <Heading2>Template Design</Heading2>
          </div>
          <p className="mt-4 text-sm leading-5 text-gray-600">
            Select a template design from the list
          </p>
        </div>
        <div>
          <div className="md:flex items-start lg:ml-24 mt-0">
            <div className="md:w-64 md:ml-12 md:mt-0 mt-4">
              <Label text="Template List" />
              <Dropdown
                optionOnClick={(e) => {
                  dispatch(setEditingStepData({
                    step_id: stepID,
                    template_id: parseInt(e.target.dataset.value)
                  }))
                }}
                options={templateList}
                error={editMode.errors.includes("template") && editMode.editingStepData.template_id === undefined}
                placeHolder={"List"}
                overwritePlaceholder={true}
              />
            </div>
          </div>
        </div>
      </div>
      {templateInfo.length > 0 && (
        <div className="w-full min-h-fit flex justify-between border-b border-gray-200 mt-4 flex-wrap">
          <div className="w-80">
            <div className="flex items-center">
              <Heading2>
                Template Render
              </Heading2>
            </div>
          </div>
          <div className="w-full flex justify-center mb-4">
            <div
              className="w-200 h-200 border border-1 border-gray-200"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

export default TemplateDesign;
