import React, { useState, useEffect, useRef } from "react";
import { SearchIcon, ChevronRightIcon } from "@heroicons/react/outline";
import Error from "./Error";
import useOutsideAlerter from "../utils/useOutsideAlerter";
import { ConstructionOutlined } from "@mui/icons-material";

const MultiSelectDropdown = ({
  selectedCampaigns,
  placeHolder,
  optionOnToggle,
  options,
  alignLeft = true,
  disabled = false,
  loaded = true,
  error,
  errorMessage = "* Please fill in all required fields",
  marginTop = false,
  marginBottom = false,
  search = false,
  checkedFn,
}) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("")
  const [visibleText, setVisibleText] = useState(placeHolder);
  const margin = `${marginTop ? "mt-10" : "mt-0"} ${marginBottom ? "mb-10" : "mb-0"} `;
  const wrapperRef = useRef(null)

  useOutsideAlerter(wrapperRef,
    (ref, event) => {
      return ref.current && !ref.current.contains(event.target)
    },
    () => {
      setShow(false)
    }
  )

  useEffect(() => {
    setVisibleText(placeHolder);
  }, [placeHolder]);

  if (search && searchTerm) {
    options = options.length > 0 ? options?.filter(
      (option) => {
        return option?.text.toLowerCase().includes(searchTerm.toLowerCase()) ?? false
      }
    ) : []
  }

  return (
    <>
      <div
        ref={wrapperRef}
        className={`flex justify-start mr-6 last:mr-0 ${margin}`}
      >
        {/* Code block starts */}
        <div className="relative">
          {loaded ? (
            <div
              className={`bg-white dark:bg-gray-800 
                  flex items-center justify-between 
                  border rounded border-gray-300 dark:border-gray-700 
                  w-40 h-12 
                  cursor-pointer${
                    disabled ? " cursor-pointer" : ""
                  }`}
              onClick={() => {
                if (!disabled) {
                  setShow(!show);
                }
              }}
            >
              <p className={`pl-3 py-3 text-gray-600 dark:text-gray-400 text-sm leading-3
                              tracking-normal font-normal overflow-hidden${disabled ? " opacity-[0.5]" : ""
              }`}>
                {visibleText}
              </p>
              <div className="cursor-pointer text-gray-600 dark:text-gray-400 mr-3">
                {show ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-up"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-up"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="6 15 12 9 18 15" />
                  </svg>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-white dark:bg-gray-800 flex items-center justify-between border rounded border-gray-300 dark:border-gray-700 w-40 cursor-pointer h-12">
              <div>
                <div className="w-6 h-6 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent ml-3 mr-3"></div>
              </div>
            </div>
          )}
          {show && (
            <div
              className={`visible transition duration-300 opacity-100 bg-white dark:bg-gray-800 rounded min-w-48 max-w-96 py-1 absolute z-50 
              ${alignLeft ? "left-0" : "right-0"} border border-1 shadow`}
            > 
              {search &&
                <div className="flex items-center justify-between mx-2">
                  <div className="w-full flex items-center p-2 bg-gray-100 rounded-md">
                    <SearchIcon className="w-4 h-4"/>
                    <input
                      onChange={(e) => {
                        setSearchTerm(e.target.value)
                      }}
                      type="search1"
                      className="w-full h-full text-sm bg-transparent focus:outline-none pl-2"
                    />
                  </div>
                  <div className="w-8 h-8 ml-2 border rounded-md cursor-pointer hover:bg-gray-100 bg-transparent border-gray-200 flex items-center justify-center">
                    <ChevronRightIcon className="w-4 h-4"/>
                  </div>
                </div> 
              }
              <ul className="visible position-absolute transition duration-300 opacity-100 mt-2 w-56 py-1 z-9999 max-h-80 overflow-y-auto break-all">
                {options.map((option, index) => {
                  const text = option?.text;
                  const value = option?.value
                  const optionData = JSON.parse(value)
                  const isChecked = checkedFn(optionData)
                  return (
                    <li
                      key={index}
                      className={`cursor-pointer text-gray-600 dark:text-gray-400 dark:hover:bg-gray-700
                                  text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal
                                  flex items-center${disabled ? " opacity-[0.5]" : ""}`}
                    >
                      <input type="checkbox" 
                        id={`occurrences-${index}`}
                        checked={isChecked}
                        disabled={selectedCampaigns?.length >= 6 && !selectedCampaigns.includes(optionData.parent_campaign_id)}
                        data-value={value}
                        onChange={async (e) => {
                          optionOnToggle(e);
                          setSearchTerm("")
                        }} />
                        &nbsp;
                      <label htmlFor={`occurrences-${index}`}>{text}</label>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {/* Code block ends */}
      </div>
      {error !== undefined && (
        <Error error={error} errorMessage={errorMessage} />
      )}
    </>
  );
};
export default MultiSelectDropdown;
