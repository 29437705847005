import Error from "./Error"
import Label from "./Label"

export default function Button({
  error,
  topStyle,
  buttonStyle,
  errorMessage = "* Please fill in all required fields",
  disabled,
  inert = false,
  labelText,
  labelledBy,
  onClick,
  margins = "horizontal",
  marginBefore = false,
  marginAfter = false,
  name,
  interior,
  flat = false,
  backgroundColorOverride = 0,
  reference
}) {

  let marginClasses = ""
  switch (margins) {
    case "horizontal": marginClasses = `${marginBefore ? "mt-10" : "mt-0"} ${marginAfter ? "mb-10" : "mb-0"} `; break;
    case "vertical": marginClasses = `${marginBefore ? "ml-10" : "ml-0"} ${marginAfter ? "mr-10" : "mr-0"} `; break;
    default: marginClasses = "m-0"; break;
  }
  function buildColor(statusID) {
    /*
    case 1: return "Active"
    case 2: return "Draft"
    case 3: return "Completed"
    case 4: return "Archived"
    */
    switch (statusID) {
      case 0: return "bg-ofg-black"; break;
      case 1: return "bg-primary-color-dark"; break;
      case 2: return "bg-ofg-orange"; break;
      case 3: return "bg-ofg-purple"; break;
      case 4: return "bg-ofg-blue"; break;
      case 5: return "bg-ofg-red"; break;
    }
  }

  function getCursor(disabled) {
    if (disabled) {
      return "cursor-default";
    } else {
      return "cursor-pointer";
    }
  }

  
  let cursor = getCursor(disabled);
  let ofgColor = buildColor(backgroundColorOverride)

  const buttonProps = {
    style: buttonStyle,
    className: `min-w-10 h-10 ${ofgColor} ${cursor} flex transition focus:outline-none duration-150 ease-in-out rounded text-white disabled p-2 flex align-center leading-6`,
    id: name,
    tabIndex: "0",
    "aria-labelledby": labelledBy,
    disabled: disabled
  }
  if (reference) {
    buttonProps.ref = reference
  }
  if (!inert) {
    buttonProps.onClick = onClick
  }
  return (
    <>
      <div style={topStyle} className={`flex flex-col ${marginClasses} bg-palette-green-600 bg-palette-green-400 bg-palette-green-900 bg-palette-gray-500 bg-primary-color-dark bg-transparent first-of-type:ml-0 first:ml-0 last:mr-0 last-of-type:mr-0 h-12 min-h-12`}>
        <div className={`flex${!flat ? " flex-col" : ""} items-center`}>
          {labelText && <Label text={labelText} for={name} />}
          <button {...buttonProps}>
            {interior}
          </button>
        </div>
      </div>
      {error !== undefined && <Error
        error={error}
        errorMessage={errorMessage} />}
    </>
  )
}