let api_server = 'http://127.0.0.1:3333/api/v1'
let localApiUrl = 'http://127.0.0.1:3333/api/v1'
let devApiUrl = 'https://api.crmeh.dev.ofgcentralservices.co.uk/api/v1'
let uatApiUrl ='https://api.crmeh.uat.ofgcentralservices.co.uk/api/v1'
let prodApiUrl = 'https://api.ofgengagementhub.co.uk/api/v1'

switch (window.location.hostname) {
 default:
  case '127.0.0.1': // local
    api_server = localApiUrl;
    break
  case 'crmeh.dev.ofgcentralservices.co.uk':
    api_server = devApiUrl;
    break
  case 'crm-eh-dev.s3-website.eu-west-2.amazonaws.com':
    api_server = devApiUrl;
    break
  case 'crmeh.uat.ofgcentralservices.co.uk':
    api_server = uatApiUrl;
    break
  case 'ofgengagementhub.co.uk':
  case 'www.ofgengagementhub.co.uk':
    api_server = prodApiUrl;
    break
}

export default api_server;
