import MultiSelectDropdown from "./MultiSelectDropdown";
import { getData, toggleOccurrenceSelection } from "../features/report";
import { useDispatch, useSelector } from "react-redux";

export default function OccurrencesMultiSelectDropdown({alignLeft = true}) {
  const dispatch = useDispatch();
  
  const { occurrences } = useSelector((state) => state.report);

  const occurrenceDropdown = occurrences.data.length
    ? occurrences.data.flatMap((item) => {
        if (item?.issued_date && item?.status_id) {
          return {
            text: item.issued_date,
            value: JSON.stringify(item),
          };
        } else { 
          return []
        }
      })
    : [];
  
  return <MultiSelectDropdown
    placeHolder={"Occurrences"}
    disabled={occurrenceDropdown.length === 0}
    search={true}
    alignLeft={alignLeft}
    options={occurrenceDropdown}
    checkedFn={(value) => {
      return occurrences.selectedOccurrenceIds.includes(value.id)
    }}
    optionOnToggle={
      (e) => {
        const val = JSON.parse(e.target.dataset.value)
        dispatch(toggleOccurrenceSelection(val.id))
      }
    }
  />
}