export function parseGetTimeToDateString(num, truncate = false) {
    const date = new Date(num)
    const longDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`

    if (truncate) {
        return longDate
    } else {
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let timeOfDay = "am"
        if (hours > 12) {
            timeOfDay = "pm"
            hours -= 12
        }
        if (minutes < 10) {
            minutes = `0${minutes}`
        }
        
        return `${longDate} ${hours}:${minutes} ${timeOfDay}`
    }
}

export function parseDateStringToDate(date, time = "00:01") {
    const dateChunks = date.split("/")
    const timeChunks = time.split(":")
    const f = new Date(parseInt(dateChunks[2]), parseInt(dateChunks[1]) - 1, parseInt(dateChunks[0]), parseInt(timeChunks[0]), parseInt(timeChunks[1])).getTime()
    return f
}