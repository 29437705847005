import { getCampaignStatus } from "../utils/getStrings";
import { toggleModal, updateEngagementHubManager } from "../features/engagementHubManager";
import {
  ChatAltIcon,
  MailIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/outline";
import Reach from "./Reach";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { updateCampaign } from "../features/campaign";
import moment from "moment";

const EngagementHubManagerCard = (props) => {
  let icon = (
    <QuestionMarkCircleIcon className="h-6 w-6 min-h-6 min-w-6 text-gray-500" />
  );
  if (props.campaignTypeID === 2) {
    icon = <ChatAltIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />;
  }
  if (props.campaignTypeID === 1) {
    icon = <MailIcon className="h-6 w-6 min-h-6 min-w-6 text-black" />;
  }

  function buildColor(statusID) {
    /*
    case 1: return "Active"
    case 2: return "Draft"
    case 3: return "Completed"
    case 4: return "Archived"
    case 4: return "Failed"
    */
    switch (statusID) {
      case 0: return "bg-ofg-black"; break;
      case 1: return "bg-primary-color-dark"; break;
      case 2: return "bg-ofg-orange"; break;
      case 3: return "bg-ofg-purple"; break;
      case 4: return "bg-ofg-blue"; break;
      case 5: return "bg-ofg-red"; break;
    }
  }

  const undefinedValueText = (
    <span className="italic text-sm text-gray-500">Not set</span>
  );
  const dispatch = useAppDispatch();
  const { modalVisible } = useSelector((state) => state.engagementHubManager);
  const style = {
    width:"100px",
    color:"white",
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: "5px",
    height: "33px",
    borderRadius: "5px",
    padding: "4px",
    marginTop: "8px"
  };
  var campaignName = props.campaignName;
  if (campaignName.length > 35) {
    campaignName = campaignName.slice(0,30);
    campaignName = campaignName+"[...]";
  }
  let reach = 0;
  if (props.campaignTypeID === 1 && props.reach.email_reach > 0) reach = props.reach.email_reach;
  if (props.campaignTypeID === 2 && props.reach.phone_reach > 0) reach = props.reach.phone_reach;
  if (reach < 1 && props.dynamicReach > 0) reach = props.dynamicReach;
  return (
    <div
      id={props.id}
      className="justify-center
        w-full 
        w-96 
        max-w-xl
        basis-96 overflow-y-hidden cursor-pointer"
      onClick={(e) => {
        dispatch(toggleModal(!modalVisible));
        dispatch(updateEngagementHubManager({
          editCampaign: true,
        }));
        dispatch(updateCampaign({
          campaignId: e.currentTarget.id,
        }))
      }}
    >
      <div className="w-96 relative rounded overflow-y-hidden md:mt-0">
        {props.topImage
          ? <img className="w-full border border-1 border-gray-300" src={props.topImage} />
          : <div className="w-96 h-64 flex items-center justify-center bg-white border border-1 border-gray-300">No template selected</div>
        }
        <div
          className="relative z-10 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg -mt-16 
                    pb-0 px-4 pt-0 
                    md:pb-0 md:px-6 md:pt-0 
                    w-11/12 mx-auto"
        >
          <div className="flex items-center justify-between w-full align-items-center border-b-2 border-gray-90">
            <h3 className="flex text-sm md:text-xl text-gray-900 dark:text-gray-50 min-h-56 font-semibold font-sans pt-3 pb-3">
              {campaignName}
            </h3>
          </div>
          <div className="flex pt-1 pb-1 text-sm border-b-2 border-gray-90">
            <div className="flex flex-col w-1/2">
              <span className="block pt-1 pb-1">Campaign Start Date</span>
              <span className="block pt-1 pb-1 text-xs">
                {props.parentCampaignStartDate
                  ? `${props.parentCampaignStartDate}`
                  : undefinedValueText}
              </span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="block pt-1 pb-1">Campaign End Date</span>
              <span className="block pt-1 pb-1 text-xs">
                {props.dates.end_date && moment(props.dates.end_date, "YYYY/MM/DD").isValid()
                  ? `${moment(props.dates.end_date).format("DD/MM/YYYY")}`
                  : undefinedValueText}
              </span>
            </div>
          </div>
          <div className="flex pt-1 pb-1 text-sm border-b-2 border-gray-90">
            <div className="flex flex-col w-1/2">
              <span className="block pt-1 pb-1">Next Occurrence</span>
              <span className="block pt-1 pb-1 text-xs">
                {props.nextOccurrenceDate
                  ? moment.unix(props.nextOccurrenceDate).format("DD/MM/YYYY h:mm a")
                  : "n/a"
                }
              </span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="block pt-1 pb-1">Occurrence Type</span>
              <span className="block pt-1 pb-1 text-xs">
                {props.occurrence || undefinedValueText}
              </span>
            </div>
          </div>
          <div className="pt-2 pb-2 flex items-center border-b-2 border-gray-90">
            <div className="pr-2">{icon}</div>
            <div>{props.header || undefinedValueText}</div>
          </div>
          {props.campaignTypeID === 1 && (
            <>
              <div className="flex h-20 text-sm border-b-2 border-gray-90">
                <div className="flex flex-col w-1/2 justify-center">
                  <span className="block pt-1 pb-1">Sending email</span>
                  <span className="block pt-1 pb-1 text-xs">
                    {props.sendingAddress || undefinedValueText}
                  </span>
                </div>
              </div>
              <div className="flex h-20 text-sm border-b-2 border-gray-90">
                <div className="flex flex-col w-1/2 justify-center">
                  <span className="block pt-1 pb-1">Reply to email</span>
                  <span className="block pt-1 pb-1 text-xs break-all">
                    {props.replyAddress || undefinedValueText}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="flex">
            <div className="flex w-1/2 pt-2 pb-2 justify-start items-center">
              <Reach
                num={reach}
              />
            </div>
            <div className="flex w-1/2 pt-2 pb-2 justify-end">
              <div className={`flex flex-col bg-palette-green-600 bg-palette-green-400 bg-palette-green-900 bg-palette-gray-500 bg-primary-color-dark bg-transparent first-of-type:ml-0 first:ml-0 last:mr-0 last-of-type:mr-0 h-12`}>
                <div style={{width:"100px !important"}}>
                  <div className={` ${buildColor(props.campaignStatusID)} font-sans`} style={style}>
                    {getCampaignStatus(props.campaignStatusID)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagementHubManagerCard;
