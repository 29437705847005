import moment from 'moment';

export default function CheckAuth() {
  try {
    const auth = JSON.parse(localStorage.getItem("token"));
    if (!auth || auth?.token === null) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      var a = auth.token.split(".");
      var exp = moment(auth.expires_at)
      var now = moment();
      if (moment.utc(exp.diff(now)) <= 0 || isNaN(atob(a[0]))) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  } catch(error) {
    console.error(error);
    localStorage.clear();
    window.location.href = "/login";
  }
}