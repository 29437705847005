import { XIcon } from "@heroicons/react/outline";
import Button from "./Button";

export default function FilterButton({index, text, disabled, onClick}) {
  return (
    <Button 
      key={index}
      topStyle={{marginRight:"10px"}}
      disabled={disabled}
      onClick={onClick}
      interior={<><XIcon className="text-white w-8 h-6 pr-2" />&nbsp;<span className="block pr-2" >{text}</span></>}
    />
  )
}