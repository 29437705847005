import moment from "moment"

export const getNextOccurrenceAfterDateTime = (initialUnixDateTime, period, nowUnixDateTime) => {
    let add = []
    switch (period.toLocaleLowerCase()) {
        case "daily":   add = [1, "days"]; break;
        case "weekly":  add = [7, "days"]; break;
        case "monthly": add = [1, "months"]; break;
        case "quarterly": add = [3, "months"]; break;
        case "yearly": add = [1, "years"]; break;
    }
    let outputDateTime = initialUnixDateTime
    while (outputDateTime < nowUnixDateTime) {
      outputDateTime = moment.unix(outputDateTime).add(...add).unix()
    }

    return outputDateTime
}

const addNextOccurrences = (data) => {
  const nowUnixDateTime = moment(Date.now()).unix()
  return data.map((v) => {
    let nextOccurrenceDate = 0
    const unixEnd = v.dates.end_date && v.dates.start_time && v.dates.start_date
      ? moment(v.dates.end_date + " " + v.dates.start_time, "YYYY-MM-DD HH:mm").unix()
      : 0
    if (nowUnixDateTime < unixEnd) {
      const initialUnixDateTime = moment(v.dates.start_date + " " + v.dates.start_time, "YYYY-MM-DD HH:mm").unix()
      nextOccurrenceDate = getNextOccurrenceAfterDateTime(initialUnixDateTime, v.occurrence, nowUnixDateTime)
    }
    return {
      ...v,
      nextOccurrenceDate: nextOccurrenceDate
    }
  })
}

export default addNextOccurrences