import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import ModalHeader from "../components/ModalHeader";
import { AgGridReact } from "ag-grid-react";
import { getCampaignData, getContactsList, setModalOpen } from "../features/report";
import { EngagementReportsCampaignDataPanel } from "../components/EngagementReportsCampaignDataPanel";
import Button from "../components/Button";

function EngagementReportOccurrenceModal({campaignId}) {
  const dispatch = useAppDispatch();
  const { occurrenceModal } = useSelector((state) => state.report);
  const gridRef = useRef();

  useEffect(() => {
    if (!occurrenceModal.contacts.data.length && !["loading", "loaded", "error"].includes(occurrenceModal.contacts.status)) {
      dispatch(
        getContactsList(
          {
            campaignId: campaignId
          }
        )
      )
    }
  })

  useEffect(() => {
    dispatch(getCampaignData({campaignId}))
  }, [])

  const staffColumnDefs = [
    {field: "contact_id", hide: true},
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { field: "date_of_birth", headerName: "DOB", filter: "agDateColumnFilter" },
    { field: "gender_name", headerName: "Gender" },
    { field: "email_address", headerName: "Email" },
    { field: "email_address_type_name", headerName: "Email Type" },
    { field: "phone_number", headerName: "Phone Number" },
    { field: "created_ts", headerName: "Created Date", filter: "agDateColumnFilter" },
  ]

  if (["loading", "idle"].includes(occurrenceModal.contacts.status)) {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  const [step, setStep] = useState(1);

  let color;
  switch (occurrenceModal.campaign.data.status_name) {
    default: color = 0; break;
    case "Active": color = 1; break;
    case "Draft": color = 2; break;
    case "Completed": color = 3; break;
    case "Archived": color = 4; break;
    case "Failed": color = 5; break;
  }

  return (
    <>
      <div id="popup" className="z-50 fixed w-full flex justify-center inset-0">
        <div className="w-full h-full bg-gray-400 opacity-60 z-0 absolute inset-0" />
        <div className="flex items-center justify-center h-full w-full">
          <div className="bg-white rounded-tl-md shadow fixed overflow-y-auto w-10/12 md:w-10/12 lg:w-10/12 2xl:w-10/12 overflow-visible max-h-modal">
            <ModalHeader onModalCloseClickFn={
              () => {
                dispatch(setModalOpen(false));
              }}>
              <div>
                <Button interior={occurrenceModal.campaign.data.status_name} backgroundColorOverride={color} />
              </div>
              <div 
                className="cursor-pointer w-52 h-16 mt-0"
                style={{float:'left', position:'absolute', left:'10%', marginTop:'-10px'}}
                onClick={
                () => {
                  setStep(1);
                }}
              >
                <img
                  src="https://i.ibb.co/c2k4Gbr/Steps3.png"
                  alt="step3"
                  className="w-full h-full"
                />
                <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
                  <p className={`w-full text-sm font-medium leading-4 ${step === 1 ? 'text-primary-color-dark' : 'text-gray-700'}`}>
                    Template
                  </p>
                  <p className="w-full text-xs mt-1 leading-none text-gray-500">
                  Check the template
                  </p>
                </div>
              </div>
              <div
                className="cursor-pointer w-52 h-16 mt-0" 
                style={{float:'left', position:'absolute', left:'25%', marginTop:'-10px'}}
                onClick={
                () => {
                  setStep(2);
                }}
              >
                <img
                  src="https://i.ibb.co/c2k4Gbr/Steps3.png"
                  alt="step3"
                  className="w-full h-full"
                />
                <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
                  <p className={`w-full text-sm font-medium leading-4 ${step === 2 ? 'text-primary-color-dark' : 'text-gray-700'}`}>
                    Source List
                  </p>
                  <p className="w-full text-xs mt-1 leading-none text-gray-500">
                    Create or select a list of recipients
                  </p>
                </div>
              </div>
            </ModalHeader>
            <div className="p-4" style={{fontWeight: '600'}}>
              <h2 className="text-xl font-medium pb-3 leading-5 text-gray-800 flex" style={{paddingLeft: "5px"}}>
                Occurrence Details - {occurrenceModal.campaign.data.campaign_name}
              </h2>
              <hr></hr>
              { step === 1 &&
                <div className="flex flex-row py-4" id="templates">
                  <img src={occurrenceModal.campaign.data.render} className="max-w-96 max-h-96 mr-4" style={{marginLeft: '-38px'}} />
                  <EngagementReportsCampaignDataPanel data={occurrenceModal.campaign.data} />
                </div>
              }
              { step === 2 &&
                <div className="ag-theme-alpine" style={{ height: 400, width: "100%", paddingTop: '20px' }} id="source">
                  <AgGridReact
                    ref={gridRef}
                    paginationAutoPageSize={true}
                    pagination={true}
                    sideBar={false}
                    rowData={occurrenceModal.contacts.data}
                    columnDefs={staffColumnDefs}
                    suppressRowClickSelection={true}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                    }
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EngagementReportOccurrenceModal;
