import SemiCircleProgressBar from "react-progressbar-semicircle";

function SemiCircleProgress({ percentage, label, diameter, strokeWidth, subLabel, isRed = false}) {
  const color = isRed ? "#FF4F45" : "#00946E"
  return (
    <div className="flex flex-col justify-center align-center p-4 bg-white rounded-xl font-semibold">
      <p className="flex text justify-center font-semibold pb-1">{label}</p>
      <SemiCircleProgressBar
        className="justify-center"
        percentage={percentage}
        showPercentValue
        diameter={diameter}
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <p className="flex text justify-center font-normal text-sm mb-2">{subLabel}</p>
    </div>
  );
}

export default SemiCircleProgress;
