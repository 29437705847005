import Dropdown from "./Dropdown";
import {
  toggleCampaignsRegionId,
  addCampaignsRegionId,
  addCampaignsOccurrenceId,
  setSelectedCampaignData,
  getData,
  setMinDate,
  setMaxDate,
  getReportGraph,
  getReportLinks,
  updateReport,
} from "../features/report";
import { getCampaign } from "../features/campaign";
import { useDispatch, useSelector } from "react-redux";
import OccurrencesMultiSelectDropdown from "./OccurrencesMultiSelectDropdown";
import MultiSelectDropdown from "./MultiSelectDropdown";
import Button from "./Button";
import { useState } from "react";

const EngagementReportsSearch = () => {
  const dispatch = useDispatch();
  const { campaigns, regions, occurrences } = useSelector((state) => state.report);
  const [disabled, setDisabled] = useState(true);

  const campaignDropdown = []
  if (campaigns.data.length > 0) {
    campaigns.data.forEach(
      (item) => {
        if ([1,3,4].includes(item.status_id) && item.events > 0) {
          campaignDropdown.push(
            {
              text: item.campaign_name,
              value: item.parent_campaign_id,
              valid: item.events > 0 ? true : false
            }
          )
        }
      }
    )
  }

  const regionDropdown = regions.data.length
    ? regions.data.map((item) => {
      return {
        text: item.display_name,
        value: JSON.stringify(item),
      };
    }).sort((v1, v2) => {
      return v2.display_order - v1.display_order
    })
    : [];

  return (
    <>
      <div className="bg-white py-6 w-full">
        <div className="z-10 relative container w-11/12 flex flex-col md:flex-row">
          <div className="w-full flex flex-col ml-6">
            <div className="flex items-center flex-row text-xs w-full flex-wrap">
              <Dropdown
                disabled={!campaignDropdown.length}
                loaded={campaignDropdown.length}
                placeHolder="Campaign"
                search={true}
                overwritePlaceholder={true}
                options={campaignDropdown}
                optionOnClick={
                  (e) => {
                    setDisabled(false)
                    const text = e.target.innerText
                    const id = e.target.dataset.value
                    dispatch(
                      setSelectedCampaignData(
                        {
                          campaignName: text,
                          campaignId: id
                        }
                      )
                    )
                    dispatch(getCampaign({ campaignId: id }))
                    dispatch(getReportGraph({ campaignId: id, regionId: regions.selectedRegionIds }));
                    dispatch(getReportLinks({ campaignId: id, regionId: regions.selectedRegionIds }));
                    dispatch(
                      updateReport({
                        occurrenceId: "",
                      })
                    );
                    dispatch(getData({ subset: false }))
                  }
                }
              />
              <OccurrencesMultiSelectDropdown />
              <MultiSelectDropdown
                disabled={disabled}
                loaded={campaignDropdown.length}
                placeHolder={"Region"}
                search={false}
                overwritePlaceholder={true}
                options={regionDropdown}
                checkedFn={(value) => {
                  return regions.selectedRegionIds.includes(value.id)
                }}
                optionOnToggle={(e) => {
                  const value = JSON.parse(e.target.dataset.value)
                  const regionId = value.id
                  dispatch(toggleCampaignsRegionId(regionId))
                  if (campaigns.selectedCampaignId !== undefined) {
                    dispatch(getData({ subset: true }))
                  }
                }}
              />
              {!disabled &&
              <div className="ml-4">
                <Button
                  interior="Reset Filters"
                  onClick={(e) => {
                    regions.data.forEach(element => {
                      dispatch(addCampaignsRegionId(element))
                    });
                    occurrences.data.forEach(element => {
                      dispatch(addCampaignsOccurrenceId(element))
                    });
                    dispatch(setMinDate(null));
                    dispatch(setMaxDate(null));
                  }} 
                ></Button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EngagementReportsSearch;
