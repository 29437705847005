import React, { useEffect , useState } from "react";
import CheckAuth from "../components/CheckAuth";
import Map from "../components/Reporting/Map";
import EngagementReportsSearch from "../components/EngagementReportsSearch";
import OccurrencesTable from "../components/OccurrencesTable";
import CircularProgress from "../components/Reporting/CircularProgress";
import SemiCircleProgress from "../components/Reporting/SemiCircleProgress";
import { useSelector, useDispatch } from "react-redux";
import { getAllCampaignsReportPage, getAllRegions, resetReport } from "../features/report";
import Heading2 from "../components/Heading2";
import OccurrencesMultiSelectDropdown from "../components/OccurrencesMultiSelectDropdown";
import LineChart from "../components/Reporting/LineChart";
import Links from "../components/Reporting/Links";
import CampaignModal from "./CampaignModal";
import {toggleModal, updateEngagementHubManager } from "../features/engagementHubManager";
import {
  getReportGraph,
  getReportLinks,
} from "../features/report";
import Heading1 from "../components/Heading1";
import Button from "../components/Button";
import { FolderAddIcon } from "@heroicons/react/outline";
import moment from "moment";

function EngagementReports() {
  const dispatch = useDispatch();
  const {
    campaigns,
    occurrences,
    regions,
    reportGraphData,
    reportMapData,
    reportLinksData,
    occurrenceModal,
  } = useSelector((state) => state.report);
  const { campaignId } = useSelector((state) => state.campaign.flags)
  const { modalVisible } = useSelector((state) => state.engagementHubManager);

  var reportData = {
    bounced: 0,
    campaign_score: 0,
    click: 0,
    contact_list_id: 0,
    ctr: 0,
    deliverability: 0,
    delivered: 0,
    engaged: 0,
    global_unsubscribed: 0,
    open_rate: 0,
    opens: 0,
    reach: 0,
    score: 0,
    sent: 0,
    spam: 0,
    status_id: 0,
    to_sent: 0,
    unique_clicks: 0,
    unique_opens: 0,
    unsubscribe_rate: 0,
    unsubscribed: 0,
    unsubscribed_email: 0,
    unsubscribed_phone: 0,
    unique_unsubscribed: 0
  };

  var collection = [];
  occurrences.data.forEach((element) => {
    if (occurrences.selectedOccurrenceIds.includes(element.id)) {
      collection.push(element);
    }
  });
  collection.forEach((element) => {
    reportData = {
      bounced: reportData.bounced + element.bounced,
      campaign_score: reportData.campaign_score + element.campaign_score,
      click: reportData.click + element.click,
      ctr: reportData.ctr + element.ctr,
      deliverability: reportData.deliverability + element.deliverability,
      delivered: reportData.delivered + element.delivered,
      engaged: reportData.engaged + element.engaged,
      global_unsubscribed:
        reportData.global_unsubscribed + element.global_unsubscribed,
      opens: reportData.opens + element.opens,
      reach: reportData.reach + element.reach,
      score: reportData.score + element.score,
      sent: reportData.sent + element.sent,
      spam: reportData.spam + element.spam,
      to_sent: reportData.to_sent + element.to_sent,
      unique_clicks: reportData.unique_clicks + element.unique_clicks,
      unique_opens: reportData.unique_opens + element.unique_opens,
      unsubscribed: reportData.unsubscribed + element.unsubscribed,
      unsubscribed_email:
        reportData.unsubscribed_email + element.unsubscribed_email,
      unsubscribed_phone:
        reportData.unsubscribed_phone + element.unsubscribed_phone,
    };
  });

  reportData.open_rate = !isNaN(
    Math.round((reportData.opens * 100) / reportData.reach)
  )
    ? Math.round((reportData.opens * 100) / reportData.reach)
    : "0";
  reportData.send_rate = !isNaN(
    Math.round((reportData.sent * 100) / reportData.reach)
  )
    ? Math.round((reportData.sent * 100) / reportData.reach)
    : "0";
  reportData.unsubscribe_rate = !isNaN(
    Math.round((reportData.global_unsubscribed * 100) / reportData.reach)
  )
    ? Math.round((reportData.global_unsubscribed * 100) / reportData.reach)
    : "0";
  reportData.click_rate = !isNaN(
    Math.round((reportData.unique_clicks  / reportData.sent)* 100) 
  )
    ?  Math.round((reportData.unique_clicks  / reportData.sent)* 100)
    : "0";
  reportData.bounce_rate = !isNaN(
    Math.round((reportData.bounced * 100) / reportData.reach)
  )
    ? Math.round((reportData.bounced * 100) / reportData.reach)
    : "0";

  const openCloseModal = () => {
    dispatch(toggleModal(!modalVisible));
  };

  useEffect(() => {
    if (occurrences.selectedOccurrenceId) {
      dispatch(
        getReportGraph({
          occurrenceId: occurrences.selectedOccurrenceId,
          regionId: regions.selectedRegionIds,
        })
      );
      dispatch(
        getReportLinks({
          occurrenceId: occurrences.selectedOccurrenceId,
          regionId: regions.selectedRegionIds,
        })
      );
    }
  }, [regions.selectedRegionIds])

  useEffect(() => {
    document.title = "Engagement Hub - Engagement Hub Reports"
    dispatch(resetReport())
  }, [])
  useEffect(() => {
    dispatch(updateEngagementHubManager({
      editCampaign: true,
    }));
    if (campaigns.status === "idle") {
      dispatch(getAllCampaignsReportPage());
    }
    if (regions.status === "idle") {
      dispatch(getAllRegions());
    }
  },[campaigns.status, regions.status])

  const occurrencesTableData = occurrences.data.filter((v) => {
    return occurrences.selectedOccurrenceIds.includes(v.id);
  });

  var createdTs = useSelector((state) => state?.campaign?.getCampaignData?.data[0]?.created_ts);
  var campaignData = useSelector((state) => state?.campaign?.getCampaignData);

  const [updateTime, setUpdateTime] = useState(undefined)
  useEffect(() => {
    if(campaignData.data) {
      setUpdateTime(campaignData.data?.updated_ts)
    }
  }, [campaignData])
  
  return (
    <>
      <EngagementReportsSearch openCloseModal={openCloseModal} />
      <CheckAuth />
      <div className="py-6 md:py-10 md:px-6 flex-auto flex flex-col">
        {(campaigns.selectedCampaignName && occurrences.data.length > 0) && (
          <>
            <h3 className="mb-2"><strong>Last Updated</strong>: {moment(updateTime || createdTs).format("DD/MM/YYYY HH:mm:ss")}</h3>
            <div className="flex flex-row items-center py-6">
              <Heading1 paddingBottom={false}>{campaigns.selectedCampaignName}</Heading1>&nbsp;
              <Button
                buttonStyle={{ marginLeft: "1rem" }}
                onClick={openCloseModal}
                interior={<FolderAddIcon className="w-6 h-6 text-white" />}
              ></Button>
            </div>
          </>
        )}
        {occurrences?.status === "loaded"
          ? (
            <>
              <div className="flex flex-wrap h-full w-full bg-gray-100">
                <div className="w-full h-full flex flex-wrap">
                  {!occurrences.data.length ? (
                    <div className="grid grid-cols-nurturing flex-auto justify-between">
                      <div className="col-span-5 justify-self-center self-center flex items-center">
                        There is currently no event data for this campaign.
                      </div>
                    </div>
                  ) : (
                    <div className="bg-white rounded-xl pb-2 h-fit w-full flex flex-wrap sm:justify-evenly justify-center">
                      <div className="flex h-40 w-50 justify-center m-1 sm:m-2 rounded-xl shadow-lg">
                        <SemiCircleProgress
                          percentage={
                            isNaN((reportData.unique_opens / reportData.sent)) ? 0 : Math.round((reportData.unique_opens / reportData.sent) * 100)
                        }
                          label={"Opened"}
                          subLabel={`${reportData.unique_opens} out of ${reportData.sent}`}
                          isRed={
                            isNaN((reportData.unique_opens / reportData.sent))
                              ? false
                              : Math.round((reportData.unique_opens / reportData.sent) * 100) < 25}
                        />
                      </div>
                      <div className="flex h-40 w-50 justify-center m-1 sm:m-2 rounded-xl shadow-lg">
                        <SemiCircleProgress
                          percentage={reportData.unsubscribe_rate}
                          label={"Unsubscribed"}
                          subLabel={`${reportData.global_unsubscribed} out of ${reportData.sent}`}
                          isRed={reportData.global_unsubscribed / reportData.sent > 1.5}
                        />
                      </div>
                      <div className="flex h-40 w-50 sm:justify-center m-1 sm:m-2 rounded-xl shadow-lg">
                        <SemiCircleProgress
                          percentage={reportData.bounce_rate}
                          label={"Bounced"}
                          subLabel={`${reportData.bounced} out of ${reportData.sent}`}
                          isRed={((reportData?.bounced ?? 0 * reportData?.sent) / 100) > 1.5}
                        />
                      </div>
                      <div className="w-full h-fit grid grid-cols-10 auto-cols-max bg-gray-white">
                        <div className="flex flex-wrap flex-col lg:col-span-2 sm:col-span-4 col-span-10 m-2">
                          <CircularProgress
                            title={"Sent"}
                            value={reportData.send_rate}
                            text={reportData.send_rate}
                            data={[
                              `Messages to Send : ${reportData.reach}`,
                              `Messages Sent : ${reportData.sent}`,
                            ]}
                          />
                          <CircularProgress
                            title={"Unique Click Through Rate"}
                            value={reportData.click_rate}
                            text={reportData.click_rate}
                            data={[
                              `Clicked Links : ${reportData.click}`,
                              `Unique Clicks : ${reportData.unique_clicks}`,
                            ]}
                            isRed={reportData?.unique_clicks / reportData?.sent < 0.1}
                          />
                        </div>
                        <div className="bg-white lg:col-span-3 sm:col-span-6 col-span-10 mt-2 mb-2 rounded-xl shadow-xl">
                          <Map data={reportMapData.data} campaignId={campaignId}/>
                        </div>
                        <div className="bg-white lg:col-span-5 col-span-10 m-2 rounded-xl shadow-xl">
                          <LineChart data={reportGraphData?.data} campaignName={campaigns.selectedCampaignName} />
                        </div>
                        <div className="col-span-10  bg-white overflow-scroll scrollbar-hide ml-2 mr-2 rounded-xl shadow-xl min-h-28">
                          {reportLinksData.data.length < 1 ? (
                            <div className="h-full w-full flex justify-center align-center">
                              <p className="m-auto">No Links Data Available</p>
                            </div>
                          ) : (
                            <Links data={reportLinksData.data} />
                          )}
                          <div className="col-span-10 sm:col-span-5"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {occurrences.data.length
                ? (
                    <div className="mt-2 shadow-lg bg-white w-full p-4 pb-8 rounded-xl">
                      <div className="mt-4  border-1 border-b pb-2 mb-8 flex items-center justify-between">
                        <Heading2>Occurrence</Heading2>
                        <div className="flex flex-rowitems-centre justify-center">
                          <OccurrencesMultiSelectDropdown alignLeft={false} />
                        </div>
                      </div>
                      <OccurrencesTable
                        data={occurrencesTableData}
                        modalData={occurrenceModal}
                        fileName="Engagement Report"
                      />
                    </div>
                  )
                : null
              }
            </>
            )
          : (
              <div className="grid grid-cols-nurturing flex-auto justify-between">
                <div className="col-span-5 justify-self-center self-center flex items-center">
                  {occurrences.state === "loaded"
                    ? "No results found"
                    : occurrences.status === "loading"
                      ? (
                          <div className="justify-self-center self-center flex items-center">
                            <div>
                              <div className="w-8 h-8 border-solid rounded-full animate-spin border-4 border-primary-color-dark border-w-400 border-t-transparent mr-6"></div>
                            </div>
                            <span className="text-3xl">Loading...</span>
                          </div>
                        )
                      : "Please use the controls above to view information about a specific campaign."
                  }
                </div>
              </div>
            )
        }
        {modalVisible && <CampaignModal reports={true} openCloseModal={openCloseModal} />}
      </div>
    </>
  );
}

export default EngagementReports;
