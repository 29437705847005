import React , { useState,  useEffect} from "react";
import { ClipboardIcon } from "@heroicons/react/outline";
import DownloadLinks from "../DownloadLinks";

function Links({ data }) {

  const [totalClick , setTotalClick] = useState(0);
  const [totalUniqueClick , setUniqueTotalClick] = useState(0);

  useEffect(() => {

    let clicks = 0, uniqueClicks = 0;

    data.map(link => {
      clicks += link?.total_click || 0;
      uniqueClicks += link.unique_clicks || 0;
    })
    setTotalClick(clicks);
    setUniqueTotalClick(uniqueClicks);

  }, [data])



  return (
    <>
      <div className="w-full h-full px-4">
        <div className="flex flex-col w-full h-full rounded-lg pb-6">
          <div className="flex items-center justify-between py-3">
            <h2 className="font-semibold text-lg pl-4 leading-tight text-gray-800">
              Links
            </h2>
            <DownloadLinks
              data={data}
              fileName="Links Report"
            />
          </div>
          <table className="w-full whitespace-nowrap overflow-hidden text-center">
            <tbody>
              {data.map((link) => {
                return (
                  <tr className="bg-blue-100">
                    <td className="pl-2 rounded-l-xl border-t-4 border-white">
                      <button className="bg-gray-100 rounded-xl p-1.5 hover:animate-pulse" onClick={() => {navigator.clipboard.writeText(link?.event_data); alert('Link Copied to Clipboard')}}>
                        <ClipboardIcon className="h-6 w-6" />
                      </button>
                    </td>
                    <td className="border-t-4 border-white">
                      <span className="font-semibold text-gray-800 overflow-hidden block text-left">
                        {link.event_data.length > 45
                          ? `${link.event_data.slice(0,45)}...`
                          : link.event_data
                        }
                      </span>
                    </td>
                    <td className="border-t-4 border-white">
                      <span className="block">Total Clicks</span>
                      <span className="text-sm font-semibold leading-none text-primary-color-dark">
                        {link?.total_click ?? 0}
                      </span>
                    </td>
                    <td className="pr-4 rounded-r-xl border-t-4 border-white">
                      <span className="block">Unique Clicks</span>
                      <span className="text-sm font-semibold leading-none text-primary-color-dark">
                        {link.unique_clicks ?? 0}
                      </span>
                    </td>
                  </tr>
                )
              })}
              <tr className="bg-blue-100">
                <td className="p-4 rounded-l-xl border-t-4 border-white" colSpan={2}>
                  <span className="font-bold text-black text-lg block text-left pl-12">
                    Running Totals
                  </span>
                </td>
                <td className="border-t-4 border-white">
                  <span className="text-sm font-semibold leading-none text-primary-color-dark">
                    {totalClick}
                  </span>
                </td>
                <td className="pr-4 rounded-r-xl border-t-4 border-white">
                  <span className="text-sm font-semibold leading-none text-primary-color-dark">
                    {totalUniqueClick}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Links;
