import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highmaps";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react"
import { getReportMap, setSelectedMapData, resetReport } from "../../features/report";
import ukMap from "../../utils/ukMap";
import Dropdown from "../Dropdown";
import markerClusters from "highcharts/modules/marker-clusters";
markerClusters(Highcharts);

const Map = ({ data, campaignId, nurturing }) => {
const { type } = useSelector((state) =>  state.report.maps)
const { flags } = useSelector((state) => state.campaign)
const dispatch = useDispatch();

useEffect(() => {
  dispatch(resetReport())
}, [flags.campaignId])

useEffect(() => {
  if (!nurturing) {
    dispatch(getReportMap({dataSourceType : 'Region', type , campaignId, stepsId : 0}))
  }
}, [type])

  const options = {
    chart: {
      map: ukMap,
      animation: false,
    },
    legend: {
      enabled : false
    },
    title: {
      text: "Report Map",
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    plotOptions: {
      mappoint: {
        cluster: {
          drillToCluster: false,
          enabled: true,
          allowOverlap: false,
          animation: true,
          layoutAlgorithm: {
            type: 'grid',
            gridSize: 70
          },
          zones: [{
            from: 1,
            to: 4,
            marker: {
              radius: 13
            }
          }, {
            from: 5,
            to: 9,
            marker: {
              radius: 15
            }
          }, {
            from: 10,
            to: 15,
            marker: {
              radius: 17
            }
          }, {
            from: 16,
            to: 20,
            marker: {
              radius: 19
            }
          }, {
            from: 21,
            to: 100,
            marker: {
              radius: 21
            }
          }]
        }
      }
    },
    series: [
      {
        color: 'red',
        accessibility: {
          exposeAsGroupOnly: true,
        },
        borderColor: "#A0A0A0",
        nullColor: "rgba(177, 244, 177, 0.5)",
        showInLegend: false,
      },
      {
        type: "mappoint",
        enableMouseTracking: true,
        colorKey: "clusterPointsAmount",
        data : data
      },
    ],
  };

  return (
    <div className="min-h-full flex flex-col justify-center">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={options}
      />
      <div className="w-full flex flex-row justify-evenly flex-wrap mb-2">
        <Dropdown placeHolder={type}
        options={["Sent", "Opens", "Clicks", "Bounced", "Unsubscribed"]}
        optionOnClick={(e) =>
          dispatch(setSelectedMapData({
          type: e.target.dataset.value
        }))
      }
        overwritePlaceholder={true}
        />
      </div>
      <p className="my-4 mx-2 text-xs text-slate-600 justify-center">* This is based on data across all emails in this occurrence that have been sent</p>
    </div>
    
  );
};

export default Map;
