import React, {useEffect, useState} from 'react';
import { CSVLink } from 'react-csv'
import Button from './Button';
import { DocumentDownloadIcon } from '@heroicons/react/outline';
//import { DocumentDownloadIcon } from '@heroicons/react/solid';
import { getCampaignStatus } from "../utils/getStrings";

export default function DownloadCSV({data, fileName}) {
    const [disabled, setDisable] = useState(true);
    const [formatedData, setFormatedData] = useState([]);

    useEffect(() => {
        data.length > 0 ? setDisable(false) : setDisable(true)
    }, [data])

    const formatCsvData = (data) => {
        let csvData;
            csvData = [[''],['Status'],['Messages to send (Reach)'],['Messages sent'],['Emails opened'],['Unsubscribed'],['Bounced'],['Links clicked'],["Unique clicks"],["Campaign Score"]];
            
            data.forEach(occurrence => {
                let status = getCampaignStatus(occurrence?.status_id)
                csvData[0].push(occurrence?.issued_date ? occurrence?.issued_date : '');
                csvData[1].push(status ? status : '');
                csvData[2].push(occurrence?.reach);
                csvData[3].push(occurrence?.sent);
                csvData[4].push(occurrence?.opens);
                csvData[5].push(occurrence?.global_unsubscribed);
                csvData[6].push(occurrence?.bounced);
                csvData[7].push(occurrence.click);
                csvData[8].push(occurrence?.unique_clicks);
                csvData[9].push(occurrence?.campaign_score ? occurrence?.campaign_score : 0);
            })

        setFormatedData(csvData)
    }

return(
    <div className={'mx-2'}>
        {disabled ?
            (<Button interior={<DocumentDownloadIcon className="w-6 h-6 text-white" /> } disabled={disabled} backgroundColorOverride={4}></Button>) : 
            (<CSVLink data={formatedData} filename={fileName} onClick={()=>formatCsvData(data)}>
                <Button interior={<DocumentDownloadIcon className="w-6 h-6 text-white" /> } disabled={disabled}></Button>
            </CSVLink>)
        }
    </div>
)

}