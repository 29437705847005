// import { useState } from "react";
import NurturingJourneyManagerCard from "../components/NurturingJourneyManagerCard";
import NurturingJourneyManagerSearch from "../components/NurturingJourneyManagerSearch";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {
  setContactListModalOpen,
  modifyStepOrder,
  archiveStep,
  updateStep,
} from "../features/nurturingJourneyManager";
import NurturingModal from "./NurturingModal";
import Heading1 from "../components/Heading1";
import Heading2 from "../components/Heading2";
import Button from "../components/Button";
import ContactListModal from "./ContactListModal";
import CheckAuth from "../components/CheckAuth";
import { getContactDetailsFromContactIDs } from "../features/contactList";
import { useEffect, useMemo } from "react";
import { sortSteps } from "../utils/sortSteps";
import { useState } from "react";
import ConfirmModal from "../containers/ConfirmModal";

function NurturingJourneyManager() {
  const dispatch = useAppDispatch();
  const {
    editMode,
    filter,
    journeysListLoaded,
    journeyStepsAwaiting,
    journeyStepsLoaded,
    journeySteps,
    contactListModalOpen,
  } = useSelector((state) => state.nurturingJourneyManager);
  const {data} = useSelector((state) => state.campaign.templateData)
  const {getListData} = useSelector((state) => state.contactList);
  const [journey, setJourney] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [indexOfItemToBeArchived, setIndexOfItemToBeArchived] = useState(null);
  const [stepIDOfItemToBeArchived, setStepIDOfItemToBeArchived] = useState(null);
  const hasFilter = Object.values(filter).some((v) => {
    return !!v !== false;
  });

  function onDragEnd(e) {
    const fromIndex = e.source.index;
    const toIndex = e.destination.index;
    if (fromIndex !== toIndex) {
      dispatch(modifyStepOrder({ fromIndex, toIndex }));
    }
  }

  useEffect(
    () => {
      document.title = "Engagement Hub - Nurturing Journey Manager"
    },
    []
  )

  const updateJourneyStep = (payload) => {
    setJourney({...journey, ...payload});
  };

  let steps = useMemo(() => {
    const rawSteps = editMode.editing ? editMode.newSteps : journeySteps;
    const orderedSteps = sortSteps(rawSteps);
    return orderedSteps;
  }, [editMode.newSteps, journeySteps]);

  /* handle modal result click (yes,no) */
  const handleOnClickModalButton = (confirm) => {
    if (confirm) {
      dispatch(archiveStep(indexOfItemToBeArchived));
      dispatch(updateStep({ step_id: stepIDOfItemToBeArchived, archived: true }));
    }
    setShowModal(false);
  };

  return (
    <>
      <CheckAuth />
      <ConfirmModal
        show={showModal}
        text={"Are you sure you want to delete journey step?"}
        handleOnClickModalButton={handleOnClickModalButton}
        setShowModal={setShowModal}
      />
      <NurturingJourneyManagerSearch
        visible={true}
        processJourney={updateJourneyStep}
      />
      <div className="py-6 md:py-10 md:px-6 flex-auto flex flex-col">
        <Heading1>Nurturing Journey Manager</Heading1>
        <div className="grid grid-cols-nurturing flex-auto justify-between">
          {!journeyStepsAwaiting ? (
            steps !== undefined && steps.length ? (
              <>
                <div className="min-w-56 w-56 max-w-56">
                  <div className="bg-white text-sm p-4 rounded flex flex-col items-start">
                    <div className="border-2px border-gray-900 border-b mb-2 w-full">
                      <Heading2>Contact List</Heading2>
                    </div>
                    <>
                      <div className="flex w-full justify-between">
                        <div className="pr-2">
                          <p className="mb-1 break-all">
                            {filter?.groupName && filter?.groupName}
                          </p>
                          <p>Group reach: {filter.reach}</p>
                        </div>
                        <Button
                          interior="View List"
                          onClick={(e) => {
                            dispatch(setContactListModalOpen(true));
                            dispatch(
                              getContactDetailsFromContactIDs(filter.contacts)
                            );
                          }}
                        />
                      </div>
                    </>
                  </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => {
                      return (
                        <div
                          className="flex shrink-0 flex-col items-center flex-auto justify-center
														xl:w-184
														min-w-104 w-104"
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {steps.map((journeyStep, index) => {
                            let localData
                            let render = data.filter(template =>  template.template_id === journey.template_id)
                            if (journeyStep.id === journey.step_id) {
                              localData = {
                                ...journey,
                                template_body: render[0]?.template_render
                              }
                            }
                            return (
                              <Draggable
                                key={index}
                                draggableId={"" + index}
                                index={index}
                              >
                                {(draggableProvided) => {
                                  return (
                                    <div
                                      ref={draggableProvided.innerRef}
                                      className="mb-16 last:mb-16 lg:mt-0"
                                      {...draggableProvided.draggableProps}
                                    >
                                      <NurturingJourneyManagerCard
                                        value={journeyStep.id}
                                        topImage={
                                          localData?.template_body ?? journeyStep.render
                                        }
                                        name={
                                          localData?.step_name ?? journeyStep.step_name
                                        }
                                        typeID={journeyStep.type_id}
                                        subject={localData?.subject ?? journeyStep.subject}
                                        replyAddress={
                                          localData?.reply_to_address ??
                                          journeyStep.reply_to_address ??
                                          undefined
                                        }
                                        fromAddress={
                                          localData?.from_address ??
                                          journeyStep.from_address ??
                                          undefined
                                        }
                                        reach={journeyStep.reach}
                                        statusID={journeyStep.status_id}
                                        waitingDays={
                                          localData?.wait_days ? localData?.wait_days :
                                          journeyStep.waiting_days
                                        }
                                        successRate={
                                          journeyStep.success_rate
                                        }
                                        dropOff={journeyStep.unsubscribed}
                                        sent={journeyStep.sent}
                                        dragHandleProps={
                                          draggableProvided.dragHandleProps
                                        }
                                        editing={editMode.editing}
                                        loaded={journeysListLoaded}
                                        planned={journeyStep.planned}
                                        id={
                                          journeyStep.id ?? journeyStep.step_id
                                        }
                                        contactIDs={journeyStep.contact_id}
                                        setShowModal={setShowModal}
                                        setIndex={setIndexOfItemToBeArchived}
                                        setStepId={setStepIDOfItemToBeArchived}
                                        stepOrder={journeyStep.step_order}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </>
            ) : (
              <div className="col-span-5 justify-self-center self-center flex items-center">
                {journeyStepsLoaded && hasFilter
                  ? "No results found"
                  : "No data loaded. Please use the controls above to view information about a specific journey."}
              </div>
            )
          ) : (
            <div className="col-span-5 justify-self-center self-center flex items-center">
              <div>
                <div className="w-8 h-8 border-solid rounded-full animate-spin border border-4 border-primary-color-dark border-w-400 border-t-transparent mr-6"></div>
              </div>
              <span className="text-3xl">Loading...</span>
            </div>
          )}
        </div>
      </div>
      {editMode.addingStep && (
        <NurturingModal
          updateJourneyStep={updateJourneyStep}
        />
      )}
      {editMode.editingStep && (
        <NurturingModal
          updateJourneyStep={updateJourneyStep}
        />
      )}
      {contactListModalOpen && <ContactListModal data={getListData.data} />}
    </>
  );
}

export default NurturingJourneyManager;
