import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";
import funnelDataFormatter from "../utils/funnelDataFormatter";
import moment from 'moment';

export const nurturingReportInitialState = {
  journeysList: {
    data: [],
    status: "idle"
  },
  typeList: {
    data: [{text: 'Show All' , value : 0} , {text: 'Email' , value : 1} , {text: 'SMS' , value : 2}],
    status: "idle"
  },
  graphData: {
    data: {}, //top level properties are series and categories
    rawData: [],
    status: "idle",
    minDate: undefined,
    campaignType: '',
    maxDate: undefined,
    selectedId: undefined
  },
  regions: {
    status: "idle",
    selectedRegionIds: [],
    data: []
  },
  errors: [],
};

export const setJourneysListFromAPI = createAsyncThunk(
  "setJourneysListFromAPI",
  async () => {
    const response = await SecureFetch(`${api_server}/nurturing`);
    const payload = await response.json()
    const data = payload.data
    if (!data || data.length === 0) {
      return thunkAPI.rejectWithValue()
    } else {
      return {
        data: data
      }
    }
  }
);

export const getAllRegions = createAsyncThunk(
  "getAllRegions",
  async () => {
    const response = await SecureFetch(`${api_server}/report/region`, "GET")
    const payload = await response.json()
    const data = payload.data
    const selectedRegionIds = data.map(
      (v) => {
        return v.id
      }
    )

    if (!data || data.length === 0) {
      return thunkAPI.rejectWithValue()
    } else {
      return {
        data: data,
        selectedRegionIds: selectedRegionIds
      }
    }
  }
)

export const getGraphDataById = createAsyncThunk(
  "getGraphDataById",
  async (params, thunkAPI) => {
    const sliceState = thunkAPI.getState().nurturingReport
    const regionIds = sliceState.regions.selectedRegionIds
    const id = sliceState.graphData.selectedId
    const minDate = sliceState.graphData.minDate
    const maxDate = sliceState.graphData.maxDate
    const campaignType = sliceState.graphData.campaignType
    let data = []
    if (id && regionIds.length) {
      const regionString = `?regionId=${regionIds.join(",")}`
      const minDateString = minDate ?? moment(new Date(0)).format("YYYY-MM-DD")
      const maxDateString = maxDate ?? moment(Date.now() + (1000 * 60 * 60 * 24 * 365 * 1000)).format("YYYY-MM-DD") //1000 years in the future
      const dateString = `&dateRange=${minDateString},${maxDateString}`
      const campaignTypeString = `&campaignType=${campaignType}`
      const fullURL = `${api_server}/report/nurturing/${id}${regionString}${dateString}${campaignTypeString}`
      const response = await SecureFetch(fullURL)
      const payload = await response.json()
      data = payload.data
    }

    if (!data || data.length === 0) {
      return thunkAPI.rejectWithValue()
    } else {
      return {
        data: data
      }
    }
  }
);

export const nurturingReport = createSlice({
  name: "nurturingReport",
  initialState: nurturingReportInitialState,
  reducers: {
    updateReport: (state, action) => {
      state.flags = { ...state.flags, ...action.payload }
    },
    setSelectedId: (state, action) => {
      state.graphData.selectedId = action.payload
    },
    setCampaignType: (state, action) => {
      state.graphData.campaignType = action.payload
    },
    resetReport: (state) => {
      state.flags = {
        ...nurturingReportInitialState
      };
    },
    toggleCampaignsRegionId: (state, action) => {
      const elementIndex = state.regions.selectedRegionIds.indexOf(action.payload)
      if (elementIndex === -1) {
        state.regions.selectedRegionIds.push(action.payload)
      } else {
        state.regions.selectedRegionIds.splice(elementIndex, 1)
      }
    },
    setMinDate: (state, action) => {
      state.graphData.minDate = action.payload
    },
    setMaxDate: (state, action) => {
      state.graphData.maxDate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setJourneysListFromAPI.pending, (state) => {
        state.journeysList.status = "loading"
        const index = state.errors.indexOf("journeysList")
        if (index !== -1) {
          state.errors.splice(index, 1)
        }
      })
      .addCase(setJourneysListFromAPI.fulfilled, (state, action) => {
        state.journeysList.status = "loaded"
        state.journeysList.data = action.payload.data;
      })
      .addCase(setJourneysListFromAPI.rejected, (state, action) => {
        state.journeysList.status = "error"
        state.journeysList.data = []
        state.errors.push("journeysList")
      })
      .addCase(getGraphDataById.pending, (state) => {
        state.graphData.status = "loading"
        const index = state.errors.indexOf("graphData")
        if (index !== -1) {
          state.errors.splice(index, 1)
        }
      })
      .addCase(getGraphDataById.fulfilled, (state, action) => {
        state.graphData.status = "loaded"
        /*
        series: [
          {
            name: "Messages Sent", 
            data: [
              50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
              50, 50, 50, 50, 50, 50, 50,
            ],
          }
        ]
        */
        state.graphData.rawData = action.payload.data
        state.graphData.data = funnelDataFormatter(action.payload.data)
      })
      .addCase(getGraphDataById.rejected, (state) => {
        state.graphData.status = "error"
        state.graphData.data = []
        state.errors.push("graphData")
      })
      .addCase(getAllRegions.pending, (state) => {
        state.regions.data = []
        state.regions.status = "loading"
        const index = state.errors.indexOf("getAllRegions")
        if (index !== -1) {
          state.errors.splice(index, 1)
        }
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.regions.data = action.payload.data
        state.regions.status = "loaded"
        state.regions.selectedRegionIds = action.payload.selectedRegionIds
      })
      .addCase(getAllRegions.rejected, (state) => {
        state.regions.status = "error"
        state.regions.selectedRegionIds = []
        state.errors.push("getAllRegions")
      });
  },
});

export const {
  updateReport,
  resetReport,
  setSelectedId,
  setCampaignType,
  toggleCampaignsRegionId,
  setMinDate,
  setMaxDate
} = nurturingReport.actions;

export default nurturingReport.reducer;
