function CampaignStatusButton({ status }) {
  let button;
  switch (status) {
    case "Active":
      button = (
        <button className="mr-2 my-2 bg-primary-color-dark transition duration-150 ease-in-out focus:outline-none rounded text-white border border-gray-300 px-5 py-1 text-xl cursor-default">
          Active
        </button>
      );
      break;
    case "Draft":
      button = (
        <button className="mr-2 my-2 bg-ofg-orange transition duration-150 ease-in-out focus:outline-none rounded text-white border border-gray-300 px-5 py-1 text-xl cursor-default">
          Draft
        </button>
      );
      break;
    case "Completed":
    button = (
        <button className="mr-2 my-2 bg-ofg-purple transition duration-150 ease-in-out focus:outline-none rounded text-white border border-gray-300 px-5 py-1 text-xl cursor-default">
          Completed
        </button>
      );
      break;
    case "Archived": 
    button = (
        <button className="mr-2 my-2 bg-ofg-blue transition duration-150 ease-in-out focus:outline-none rounded text-white border border-gray-300 px-5 py-1 text-xl cursor-default">
          Archived
        </button>
      );
      break;
    case "Failed": 
    button = (
        <button className="mr-2 my-2 bg-ofg-red transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-5 py-1 text-xs cursor-default">
          Failed
        </button>
      );
      break;
  }
  return button
}

export default CampaignStatusButton;
