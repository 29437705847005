
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api_server from "../axios/baseURL"
import SecureFetch from "../components/SecureFetch"

export const reportInitialState = {
  campaignDropdown: {
    status: "idle",
    data: [],
    error: undefined,
  },
  campaignsData: {
    status: "idle",
    data: [], //every element is a response from /reports/campaign WITH ITS NAME ADDED, for use in the ABOTTable
    error: undefined
  },
  occurrenceModal: {
    open: false,
    contacts: {
      data: [],
      status: "idle",
      error: {}
    },
    campaign: {
      data: {},
      status: "idle",
      error: {}
    }
  },
  lastLoadReturnedNoData: false
}

export const getAllCampaigns = createAsyncThunk(
  "getAllCampaigns",
  async () => {
    const response = await SecureFetch(`${api_server}/report/campaign`)
    const payload = await response.json()
    return {
      data: payload.data ?? [],
    }
  }
)

export const getContactsList = createAsyncThunk("getContactsList", async (params) => {
  const response = await SecureFetch(`${api_server}/contacts-list?campaign_id=${params.campaignId}`)
  const payload = await response.json()
  return {
    data: payload.data ?? [],
  }
})

export const getCampaignData = createAsyncThunk("getCampaignData", async (params) => {
  const response = await SecureFetch(`${api_server}/campaign/${params.campaignId}`)
  const payload = await response.json()
  return {
    data: payload.data ?? [],
  }
})

export const toggleCampaignSelection = createAsyncThunk(
  "toggleCampaignSelection", async (params, thunkAPI) => {
    const {id, name} = params
    let state = thunkAPI.getState()
    const index = state.abComparison.campaignsData.data.findIndex((v) => {
      if (!state.abComparison.campaignsData.data.includes(id)) {
        return v.id === id
      }
    })
    const idArrays = state.abComparison.campaignsData.data.map(item => item.id)
    if (index === -1) {
      const response = await SecureFetch(`${api_server}/report/campaign/${id}?occurrences=false`)
      const payload = await response.json()
      if (payload?.data && payload.data.length) {
        thunkAPI.dispatch(toggleLastLoadReturnedNoData(false))
        const response2 = await SecureFetch(`${api_server}/report/occurrence/list?id=${id}`)
        const payload2 = await response2.json()
        const stepIds = payload2.data.map(v => v.id)
        const dataWithCampaignName = {
          ...payload.data[0],
          campaign_name: name,
          stepIds: stepIds
        }
        if (!idArrays.includes(id)) {
          thunkAPI.dispatch(addCampaignToTable(dataWithCampaignName))
        }
      } else {
        thunkAPI.dispatch(toggleLastLoadReturnedNoData(true))
        thunkAPI.rejectWithValue()
      }
    } else {
      thunkAPI.dispatch(removeCampaignFromTable(index))
    }
    thunkAPI.fulfillWithValue()
  }
)

export const report = createSlice({
  name: "report",
  initialState: reportInitialState,
  reducers: {
    setModalOpen: (state, action) => {
      state.occurrenceModal.open = action.payload
    },
    addCampaignToTable: (state, action) => {
      state.campaignsData.data.push(action.payload)
    },
    removeCampaignFromTable: (state, action) => {
      state.campaignsData.data.splice(action.payload, 1)
    },
    toggleLastLoadReturnedNoData: (state, action) => {
      state.lastLoadReturnedNoData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCampaigns.pending, (state) => {
      state.campaignDropdown.data = []
      state.campaignDropdown.status = "loading"
    })
    builder.addCase(getAllCampaigns.fulfilled, (state, action) => {
      state.campaignDropdown.data = action.payload.data
      state.campaignDropdown.status = "loaded"
    })
    builder.addCase(getAllCampaigns.rejected, (state, action) => {
      state.campaignDropdown.data = []
      state.campaignDropdown.status = "error"
      state.campaignDropdown.error = action.error.message
    })



    builder.addCase(toggleCampaignSelection.pending, (state) => {
      state.campaignsData.status = "loading"
    })
    builder.addCase(toggleCampaignSelection.fulfilled, (state, action) => {
      state.campaignsData.status = "loaded"
      state.campaignsData.error = undefined
    })
    builder.addCase(toggleCampaignSelection.rejected, (state, action) => {
      state.campaignsData.data = []
      state.campaignsData.status = "error"
      state.campaignsData.error = action.error.message
    })



    builder.addCase(getContactsList.pending, (state) => {
      state.occurrenceModal.contacts.data = []
      state.occurrenceModal.contacts.status = "loading"
    })
    builder.addCase(getContactsList.fulfilled, (state, action) => {
      state.occurrenceModal.contacts.data = action.payload.data
      state.occurrenceModal.contacts.status = "loaded"
    })
    builder.addCase(getContactsList.rejected, (state, action) => {
      state.occurrenceModal.contacts.data = []
      state.occurrenceModal.contacts.status = "error"
      state.occurrenceModal.contacts.error = action.error.message
    })


    
    builder.addCase(getCampaignData.pending, (state) => {
      state.occurrenceModal.campaign.data = []
      state.occurrenceModal.campaign.status = "loading"
    })
    builder.addCase(getCampaignData.fulfilled, (state, action) => {
      state.occurrenceModal.campaign.data = action.payload.data
      state.occurrenceModal.campaign.status = "loaded"
    })
    builder.addCase(getCampaignData.rejected, (state, action) => {
      state.occurrenceModal.campaign.data = []
      state.occurrenceModal.campaign.status = "error"
      state.occurrenceModal.campaign.error = action.error.message
    })
  }
})

export const {
  setModalOpen,
  addCampaignToTable,
  removeCampaignFromTable,
  toggleLastLoadReturnedNoData
} = report.actions

export default report.reducer