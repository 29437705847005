import { useState, useEffect } from "react";
import CampaignStatusButton from "../components/CampaignModal/CampaignModalHeader/CampaignStatusButton";
import ProgressCards from "../components/CampaignModal/CampaignModalHeader/ProgressCards";
import CampaignHeader from "../components/CampaignModal/campaignModalMain/campaignHeader/CampaignHeader";
import TemplateDesign from "../components/CampaignModal/campaignModalMain/templateDesign/TemplateDesign";
import SourceList from "../components/CampaignModal/campaignModalMain/sourceList/SourceList";
import Scheduler from "../components/CampaignModal/campaignModalMain/scheduler/Scheduler";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import ConfirmModal from "./ConfirmModal";
import {
  addError,
  removeErrors,
  postCampaign,
  patchCampaign,
  getTemplates,
  resetCampaign,
} from "./../features/campaign";
import { getListHeaders, getList, resetList, removeError } from "./../features/contactList";
import { getCampaign } from "./../features/campaign";
import {
  setDataFromAPI,
  toggleModal,
  updateEngagementHubManager,
} from "./../features/engagementHubManager";
import ModalHeader from "../components/ModalHeader";
import CheckAuth from "../components/CheckAuth";
import Dropdown from "../components/Dropdown";

function CampaignModal({ openCloseModal, reports }) {
  const dispatch = useAppDispatch();
  const [campaignStep, setCampaignStep] = useState(0);
  const {
    outboundEmail,
    campaignName,
    campaignType,
    campaignTypeName,
    subject,
    pretext,
    replyToAddress,
    template,
    startDateTime,
    endDateTime,
    recurrenceType,
    campaignId,
    status,
  } = useSelector((state) => state.campaign.flags);

  const { dataSource, sourceList, newListSaved, createNewList } = useSelector(
    (state) => state.contactList.flags
  );
  const { modalVisible, data, filter } = useSelector((state) => state.engagementHubManager);
  const { editCampaign } = useSelector(
    (state) => state.engagementHubManager.flags
  );
  const { getCampaignData , statusData, templateData} = useSelector(
    (state) => state.campaign
  );

  /* drop down and modal state */
  const [ currentDropDownStatusValue , setCurrentDropDownStatusValue ] = useState(null);
  const [ showModal , setShowModal ] = useState(false);

  /* status value and status text state */
  const [ possibleStatuses , setPossibleStatuses ] = useState(statusData.data.data || []);
  const [ currentSelectedStatus , setCurrentSelectedStatus ] = useState(null);
  const [ originalStatus , setOriginalStatus ] = useState(true);
  const [ currentSelectedStatusText , setCurrentSelectedStatusText ] = useState(status);

  if (editCampaign) {
    var [ validatedSteps , setValidatedSteps ] = useState([0,1,2,3,4]);
  } else {
    var [ validatedSteps , setValidatedSteps ] = useState([]);
  }
  
  useEffect(() => {
    /* setting up the status value (status_id) from status text */
    if (status ) {
      const currentStatus = statusData?.data?.data?.find(s => s.text == status) || {};
      setCurrentSelectedStatus(currentStatus.value); // 1, 2
    }
  }, [status])

  useEffect(() => {
    dispatch(getCampaign({ campaignId:campaignId }));
  }, [campaignId])

  useEffect(() => {
    /* when the status value get changes >> 2 to 4  */
    if(!currentSelectedStatus)
      return

    const allStatuses = statusData.data.data || [];
    const currentStatus = allStatuses.find(s => s.value == currentSelectedStatus) || {};

    let statuses = [];
    switch (currentStatus.text) {
      case "Active":
        statuses = allStatuses.filter(s => s.text != "Active")
        break;
      case "Draft":
        statuses = allStatuses.filter(s => s.text != "Completed")
        break;
      case "Completed":
        statuses = allStatuses.filter(s => s.text != "Active" && s.text != "Draft" && s.text != "Completed" && s.text != "Failed")
        break;
      case "Archived":
        statuses = allStatuses.filter(s =>  s.text != "Archived" && s.text != "Completed" && s.text != "Failed")
        break;
      case "Failed":
        statuses = allStatuses.filter(s =>  s.text != "Archived" && s.text != "Completed" && s.text != "Failed")
        break;
  
      default:
        statuses = allStatuses;
        break;
    }
    setPossibleStatuses(statuses)
    setCurrentSelectedStatusText(currentStatus.text || status)
  }, [currentSelectedStatus])


  /* handle modal result click (yes,no) */
  const handleOnClickModalButton = (confirm) => {
    if (confirm) {
      // call the api
      dispatch(patchCampaign({ campaignId: getCampaignData.data[0]?.id , statusId : currentDropDownStatusValue }));
      setCurrentSelectedStatus(currentDropDownStatusValue) 
    }
    setShowModal(false)
  }

  const { templateName} = useSelector(
    (state) => state.campaign.flags
  );
  let temp
  if (templateName != null && templateName != "") {
    temp = templateName;
  } else {
    temp = "List";
  }
  let [selectedTemplate, setSelectedTemplate] = useState(temp);

  useEffect(() => {
    dispatch(getListHeaders({ dataSource }));
    dispatch(getList({ dataSource }));
    dispatch(getTemplates());
  }, [dataSource, newListSaved]);

  const changeCampaignStep = (step) => {
    setCampaignStep(step);
  }

  const campaignNameArray = data.map((campaign) => campaign.campaign_name.toLowerCase())

  const formValidation = async (e) => {
    if (currentSelectedStatus == 4 || currentSelectedStatus == 5) var archive = true;
    if (currentSelectedStatus != 4 && currentSelectedStatus != 5) var archive = false;
    var specials = /[^A-Za-z 0-9]/g;
    if (campaignStep === 0) {
      dispatch(removeErrors());
      if (!archive && (!campaignName || !campaignTypeName)) {
        dispatch(addError("campaignType"));
      } else if (!archive && specials.test(campaignName)) {
        dispatch(addError("campaignCharacters"))
      } else if (!archive && campaignNameArray.includes(campaignName.toLowerCase()) && !editCampaign) {
        dispatch(addError("campaignDuplicate"))
      } else if (campaignTypeName === "SMS") {
        if (!archive && !subject) {
          dispatch(addError("campaignSMS"));
        } else if (/\s/g.test(campaignName)) {
          dispatch(addError("campaignSpacesSMS"));
        } else {
          setStepStateDisabled(false)
          setCampaignStep(campaignStep + 1);
          editCampaign
            ? dispatch(patchCampaign({campaignId, campaignName, campaignTypeName, subject}))
            : dispatch(postCampaign({ campaignName, campaignTypeName, subject }));
          let steps = [...validatedSteps]
          steps.push(campaignStep)
          setValidatedSteps(steps);
          dispatch(removeErrors());
        }
      } else if (campaignTypeName === "Email") {
        if (!archive && (!subject || !replyToAddress || !outboundEmail)) {
          dispatch(addError("campaignEmail"));
        } else if (!archive && specials.test(subject)) {
          dispatch(addError("subjectCharacters"))
        } if (!archive && specials.test(pretext)) {
          dispatch(addError("pretextCharacters"))
        } else {
          setCampaignStep(campaignStep + 1);
          setStepStateDisabled(false)
           editCampaign
            ? dispatch(
                patchCampaign({
                  campaignId,
                  campaignName,
                  campaignType,
                  subject,
                  pretext,
                  replyToAddress,
                  outboundEmail,
                  statusId: currentSelectedStatus,
                })
              )
            : dispatch(
                postCampaign({
                  campaignName,
                  campaignType,
                  subject,
                  pretext,
                  replyToAddress,
                  outboundEmail,
                  statusId: currentSelectedStatus,
                })
              )
          dispatch(removeErrors());
          let steps = [...validatedSteps]
          steps.push(campaignStep)
          setValidatedSteps(steps)
        }
      }
    }
    if (campaignStep === 1) {
      // if (isNaN(template)) template = 1;
      if (template) {
        console.log(template);
        dispatch(
          patchCampaign({
            campaignId,
            template: template,
            statusId: currentSelectedStatus
          })
        );
        setCampaignStep(campaignStep + 1);
        dispatch(removeErrors());
        let steps = [...validatedSteps]
        steps.push(campaignStep)
        setValidatedSteps(steps);
      } else {
        dispatch(addError("template"));
      }
    }
    if (campaignStep === 2) {
      if (!archive && campaignNameArray.includes(campaignName.toLowerCase())) {
        dispatch(addError("campaignDuplicate"))
      }
      if (status === "Completed" || status === "Archived") {
        setCampaignStep(campaignStep + 1);
        dispatch(removeErrors());
      }
      else if (!archive && !sourceList) {
        dispatch(addError("currentList"));
      } else {
        dispatch(
          patchCampaign({
            campaignId,
            sourceList,
            statusId: currentSelectedStatus
          })
        );
        setCampaignStep(campaignStep + 1);
        dispatch(removeErrors());
        let steps = [...validatedSteps]
        steps.push(campaignStep)
        setValidatedSteps(steps);
      }
    }
    if (campaignStep === 3) {
      let newStatus = editCampaign ? currentSelectedStatus : 1;
      if (
        status === 'Draft' && 
        currentSelectedStatus == 2 && 
        originalStatus
      ) {
        newStatus = 1;
      }
      if (
        !archive && (
          startDateTime == null || 
          endDateTime == null || 
          recurrenceType == null ||
          startDateTime == undefined || 
          endDateTime == undefined || 
          recurrenceType == undefined
        )
      ) {
        dispatch(addError("dateTime"));
      } else {
        let steps = [...validatedSteps]
        steps.push(campaignStep)
        setValidatedSteps(steps);
        dispatch(
          patchCampaign({
            campaignId,
            startDateTime,
            endDateTime,
            recurrenceType,
            statusId: newStatus
          })
        );
        dispatch(removeErrors());
        setCampaignStep(0);
        dispatch(resetCampaign());
        dispatch(
          updateEngagementHubManager({
            editCampaign: false,
          })
        );
        dispatch(toggleModal(!modalVisible));
        dispatch(setDataFromAPI(filter))
      }
    }
  };

  const [stepStateDisabled, setStepStateDisabled] = useState(
    editCampaign
    ? false
    : campaignTypeName === "SMS"
      ? !(campaignName && subject)
      : !(campaignName && subject && replyToAddress  && outboundEmail)
  )
  return (
    <>
      <CheckAuth />
      <div id="popup" className="z-50 fixed w-full flex justify-center inset-0">
        <div className="w-full h-full bg-gray-400 opacity-60 z-0 absolute inset-0" />
        <div className="mx-auto container">
          <div className="flex items-center justify-center h-full w-full">
            <div className="bg-white rounded-md shadow fixed overflow-y-auto w-10/12 md:w-10/12 lg:w-10/12 2xl:w-10/12 overflow-visible h-modal">
              <ConfirmModal 
                show={showModal}
                text={'Are you sure you want to change the campaign status?'}
                handleOnClickModalButton={handleOnClickModalButton}
              />
              {(status || !reports) && (
                <ModalHeader
                  onModalCloseClickFn={() => {
                    openCloseModal();
                    !reports && dispatch(resetCampaign());
                    dispatch(resetList());
                    dispatch(
                      updateEngagementHubManager({
                        editCampaign: false,
                      })
                    );
                  }}
                >
                  {(reports && status) && (
                    <CampaignStatusButton
                      status={status ?? currentSelectedStatusText}
                    />
                  )}
                  {(!reports) && (
                    <CampaignStatusButton
                      status={currentSelectedStatusText ??  "Draft"}
                    />
                  )}
                  {(status === "Active" || status === "Draft") && (
                    <Dropdown
                      disabled={stepStateDisabled}
                      placeHolder="Status" 
                      options={possibleStatuses}
                      optionOnClick = {async (e) => {
                        setOriginalStatus(false);
                        setCurrentSelectedStatus(e.target.attributes[0].value);
                        setCurrentDropDownStatusValue(e.target.attributes[0].value);
                        setShowModal(true);
                      }}
                    />
                  )}
                  <ProgressCards changeCampaignStep={changeCampaignStep} campaignStep={campaignStep} validatedSteps={validatedSteps}/>
                </ModalHeader>
              )}
              <div className="bg-white md:px-4 md:py-8 py-8 flex flex-col min-h-modal-content">
                <div className="px-4 md:px-10 pt-2 md:pt-4 md:pb-4 pb-7">
                 
                    <div>
                      {campaignStep === 0 && (
                        <CampaignHeader editCampaign={editCampaign} campaignNameArray={campaignNameArray} currentSelectedStatus={currentSelectedStatus}/>
                      )}
                      {campaignStep === 1 && (
                        <TemplateDesign editCampaign={editCampaign} setTemplate={setSelectedTemplate}/>
                      )}
                      {campaignStep === 2 && (
                      <SourceList editCampaign={editCampaign} currentSelectedStatus={currentSelectedStatus} campaignId={getCampaignData?.data?.id}/>
                      )}
                      {campaignStep === 3 && (
                        <Scheduler editCampaign={editCampaign} currentSelectedStatus={currentSelectedStatus}/>
                      )}
                    </div>
                  
                  
                </div>
              </div>
              <div 
                className="flex items-center justify-between mt-9 mb-4 border-gray-200 border-t" 
                style={{width: "100%", position: "sticky", paddingTop: "20px", paddingBottom: "20px", left: "0px", bottom: "0px", backgroundColor: "white"}}
              >
                    <div>
                      <button
                        onClick={() => {
                          openCloseModal();
                          dispatch(resetCampaign());
                          dispatch(resetList());
                          dispatch(
                            updateEngagementHubManager({
                              editCampaign: false,
                            })
                          );
                          dispatch(removeError())
                          setCurrentSelectedStatus(null)
                        }}
                        className="px-6 py-3 bg-ofg-purple hover:bg-gray-500 shadow rounded text-sm text-white mr-3"
                        style={{marginLeft: "30px"}}
                      >
                        Cancel
                      </button>
                      {campaignStep === 3 && 
                      status != "Completed" &&
                      status != "Archived"  &&
                      status != "Failed" 
                      ? (
                        <></>
                      ) : false} 
                    </div>
                    {campaignStep === 3 ? (
                      <button
                        type="button"
                        onClick={() => formValidation()}
                        className="px-6 py-3 bg-primary-color-dark hover:bg-gray-500 shadow rounded text-sm text-white"
                        style={{marginRight: "30px"}}
                      >
                        Submit
                      </button>
                    ) : createNewList ? null : (
                      <button
                        onClick={() =>  formValidation()}
                        className="px-6 py-3 bg-primary-color-dark shadow rounded text-sm hover:bg-gray-500 text-white"
                        style={{marginRight: "30px"}}
                      >
                        Save and Continue
                      </button>
                    )}
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampaignModal;