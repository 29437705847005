import { useEffect, useState } from "react";
import { UserAddIcon, BackspaceIcon } from "@heroicons/react/outline";
import LeadAgGrid from "./LeadAgGrid";
import { useDispatch, useSelector } from "react-redux";
import { updateList, getList, removeError } from "../../../../features/contactList";
import Dropdown from "../../../Dropdown";
import Label from "../../../Label";
import Heading2 from "../../../Heading2";
import TextBox from "../../../TextBox";
import Button from "../../../Button";

const dataSourceDropdown = ["Lead Data"];

function SourceList({editCampaign, currentSelectedStatus, campaignId}) {
  const [displayAgGrid, setDisplayAgGrid] = useState(false);
  const [selectedSourceList, setSelectedSourceList] = useState('');
  const dispatch = useDispatch();
  const { dataSource, sourceList, newListSaved, newListName, createNewList, sourceListName, errors, source } =
    useSelector((state) => state.contactList.flags);
  const { status } = useSelector((state) => state.campaign.flags);
  const {postListData} = useSelector((state) => state.contactList)
  const { getListHeadersData, getListData } = useSelector(
    (state) => state.contactList
  );

  const sourceListDropdown = getListHeadersData.data.data
    ? getListHeadersData.data.data.map((item) => {
        return {
          text: item.list_name,
          value: item.id,
        };
      })
    : [];

  const listTypeDropdown = ["Fixed", "Dynamic"];

  const sourceListInfo = getListHeadersData.data.data
    ? getListHeadersData.data.data.filter((option) => option.id == sourceList)
    : "";

  useEffect(() => {
    dispatch(getList({ sourceList, campaignId }))
  }, [sourceList, campaignId]);

  useEffect(() => {
    dispatch(
      updateList({
        dataSource: "Lead Data",
      })
    )
  }, []);

  // useEffect(() => {dispatch(updateList({
  //   sourceList: ""
  // }));}, [dataSource])

  return (
    <>
      {!displayAgGrid || newListSaved ? (
        <div className="w-full min-h-fit pb-8 flex flex-col justify-start flex-wrap">
          <Heading2>Source List</Heading2>
          <div className="flex items-end mb-8">
            <div className="w-64 pr-8">
              <p className="mt-4 text-sm leading-5 text-gray-600">
                Select a source list from the options. You can create a new list on the List Manager page. 
              </p>
            </div>
            <Dropdown
              placeHolder={sourceListInfo[0]?.list_name ?? sourceListName ?? "List"}
              search={true}
              options={sourceListDropdown}
              disabled={
                status === "Completed" || 
                status === "Archived" || 
                status === "Active" ||
                status === "Failed" ||
                currentSelectedStatus == 4 ||
                currentSelectedStatus == 5 ||
                currentSelectedStatus == 3
              }
              optionOnClick={(e) => {
                dispatch(updateList({ sourceList: e.target.dataset.value, sourceListName: e.target.dataset.text }));
              }}
              overwritePlaceholder={true}
              marginAfter={true}
            />
          </div>
          <div className="w-full flex justify-center flex-wrap">
            {sourceList && (
              <LeadAgGrid
                source={true}
                gridData={getListData.data}
                gridFilters={getListData.queryFilters}
                status={status}
              />
            )}
          </div>
        </div>
      ) : null}
      {displayAgGrid & !newListSaved ? (
        <div className="w-full min-h-fit flex justify-start border-b mt-4 border-gray-200 flex-wrap">
          <div className="md:w-64">
            <div className="flex items-center">
              <Heading2>Create List</Heading2>
            </div>
          </div>
          <div className="flex justify-center flex-wrap">
            <div className="md:w-44 md:ml-12 md:mt-0 mt-4 min-h-24">
              <Label text="Data Source" />
              <Dropdown
                optionOnClick={(e) => {
                  dispatch(updateList({ dataSource: e.target.dataset.value }));
                }}
                overwritePlaceholder={true}
                disabled={
                  status === "Completed" || 
                  status === "Archived" || 
                  status === "Active" ||
                  status === "Failed" ||
                  currentSelectedStatus == 4 ||
                  currentSelectedStatus == 5 ||
                  currentSelectedStatus == 3
                }
                placeHolder={"Source"}
                options={dataSourceDropdown}
                name={"dataSource"}
              />
            </div>
            <div className="md:w-44 md:ml-12 md:mt-0 mt-4 min-h-24">
              <Label text="List Type" />
              <Dropdown
                overwritePlaceholder={true}
                disabled={
                  status === "Completed" || 
                  status === "Archived" || 
                  status === "Active" ||
                  status === "Failed" ||
                  currentSelectedStatus == 4 ||
                  currentSelectedStatus == 5 ||
                  currentSelectedStatus == 3
                }
                optionOnClick={(e) => {
                  dispatch(
                    updateList({
                      listType: e.target.dataset.value,
                    })
                  );
                }}
                placeHolder="Source"
                options={listTypeDropdown}
                name={"listType"}
              />
            </div>
            <div className="md:w-64 md:ml-12 md:mt-0 mt-4 min-h-24">
              <TextBox
                name="listName"
                labelText="List Name"
                disabled={
                  status === "Completed" || 
                  status === "Archived" || 
                  status === "Active" ||
                  status === "Failed" ||
                  currentSelectedStatus == 4 ||
                  currentSelectedStatus == 5 ||
                  currentSelectedStatus == 3
                }
                onChange={(e) => {
                  dispatch(
                    updateList({
                      newListName: e.target.value,
                    })
                  );
                  dispatch(removeError())
                }}
                type="name"
                aria-labelledby="Campaign name"
                placeholder="Name"
                error={postListData?.error && Object.keys(postListData.error).includes('message')}
                errorMessage={`* ${postListData.error.message}`}
              />
            </div>
            <div className="md:w-64 md:ml-12 md:mt-0 mt-10 min-h-24 content-center">
              <Button
                name="selectExistingList"
                labelText="Select Existing List"
                onClick={() => {
                  setDisplayAgGrid(!displayAgGrid);
                  dispatch(
                    updateList({
                      createNewList: false,
                      dataSource: "",
                    })
                  );
                }}
                interior={<BackspaceIcon className="text-white w-8 h-6" />}
                flat={true}
                marginTop={true}
                marginBottom={false}
                error={
                  errors?.includes("newList") && (!dataSource || !newListName)
                }
              />
            </div>
            {createNewList && (
              <>
                <LeadAgGrid
                  source={true}
                  error={errors?.includes("agGrid") ? "agGrid" : ""}
                  gridData={getListData.data}
                  gridFilters={getListData.queryFilters}
                  status={status}
                />
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SourceList;
