export function rotateTableData(data) {
  //filters data, currently columns, that have not been selected using the MultiSelectDropdown
  if (!data.length) return []
  const rows = {}
  /*
  This .forEach is pretty simple, but obtusely written. It pivots the table so your input, which looks like this...
    const input = [
      {
        "date": "11/02/2022",
        "disabled": false,
        "selected": true,
        "reach": 123,
        "sent": 234,
        "delivered": 456,
        "unqiue_opens": 567
      },
      {
        "date": "19/05/2022",
        "disabled": true,
        "selected": true,
        "reach": 111,
        "sent": 222,
        "delivered": 444,
        "unqiue_opens": 441
      },
    ]

    ...is output like this:
    const output = {
      "date": ["11/02/2022", "19/05/2022"],
      "disabled": [false, true],
      "selected": [true, true],
      "reach": [123, 111],
      "sent": [234, 222],
      "delivered": [456, 444],
      "unqiue_opens": [567, 441]
    }
  */
  data.forEach((element, i) => {
    Object.entries(element).forEach(([key, value]) => {
      if (!rows.hasOwnProperty(key)) {
        rows[key] = [value ?? 0]
      } else {
        rows[key].push(value ?? 0)
      }
    })
  })

  return rows
}