import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_server from "../axios/baseURL";
import SecureFetch from "../components/SecureFetch";

export const contactListInitialState = {
    flags: {
      dataSource: "",
      listType: "",
      sourceList: "",
      statusId: "",
      sourceListName: "",
      source: "",
      createNewList: false,
      newListName: "",
      newListIds: [],
      newListSaved: false,
      savedListId: "",
      listSelection: "",
      newListFilters: [],
      disabled: false,
      errors: []
    },
    getListHeadersData: {
      status: "idle",
      data: [],
      error: {},
    },
    getListData: {
      status: "idle",
      data: [],
      error: {},
    },
    postListData: {
      status: "idle",
      data: [],
      error: {},
    },  
    changeListData: {
      status: "idle",
      data: [],
      error: {},
    },  
    getCampaignCountData: {
      status: "idle",
      data: [],
      error: {},
    },
  };

  
export const getList = createAsyncThunk("getList", async (params) => {
    const { dataSource, campaignId, sourceList } = params;
    let contactTypeId;
    if (dataSource === "Staff Data") {
      contactTypeId = 434;
    } else if (dataSource === "Lead Data") {
      contactTypeId = 433;
    }

    let queryString = ""
    if (dataSource) {
      queryString = `${api_server}/contacts-list?contact_type_id=${contactTypeId}`
    } else if (campaignId && !sourceList) {
      queryString = `${api_server}/contacts-list?campaign_id=${campaignId}`
    } else if (sourceList && !campaignId) {
      queryString =  `${api_server}/contacts-list?contact_list_id=${sourceList}`
    } else if (sourceList && campaignId) {
      queryString = `${api_server}/contacts-list?contact_list_id=${sourceList}&campaign_id=${campaignId}`
    }
      let response
    if (queryString) {
      response = await SecureFetch(
        queryString
      );
    }
    const payload = await response.json();
    return {
      data: payload ?? [],
    };
  });

  export const postList = createAsyncThunk(
    "postList",
    async (params, thunkAPI) => {
      const { newListName, newListIds, listTypeId, dataSource, filters, statusId } = params;
      let contact_type_id;
      if (dataSource === "Staff Data") {
        contact_type_id = 434;
      } else if (dataSource === "Lead Data") {
        contact_type_id = 433;
      }
      let body = {};
      statusId && (body.status_id = statusId)
      newListName && (body.list_name = newListName);
      newListIds && (body.contact_ids = newListIds);
      listTypeId && (body.list_type_id = listTypeId);
      dataSource && (body.contact_type_id = contact_type_id);
      filters && (body.query_filter = filters);
      
      const response = await SecureFetch(`${api_server}/contacts-list`,"POST",JSON.stringify(body));
      const payload = await response.json();

      if (response.status === 400) {
        return thunkAPI.rejectWithValue({message: payload.message})
      }
    
      await thunkAPI
        .dispatch(
          updateList({
            newListSaved: true,
            sourceList: payload.data[0].contact_list_id,
            createNewList: false,
          })
        )
        .then(() => {
          return {
            data: payload ?? [],
          };
        });
    }
  );

  export const getListHeaders = createAsyncThunk(
    "getListHeaders",
    async (params) => {
      const { dataSource } = params;
      let contactTypeId;
      if (dataSource === "Staff Data") {
        contactTypeId = 434;
      } else if (dataSource === "Lead Data") {
        contactTypeId = 433;
      }
      
      const response = await SecureFetch(`${api_server}/contacts-list-headers?contact_type_id=${contactTypeId}`);
      const payload = await response.json();
      return {
        data: payload ?? [],
      };
    }
  );

  export const changeList = createAsyncThunk("changeList",async (params) => {
    const {sourceList, statusId} = params
    if (statusId != 1) var newStatus = 1;
    if (statusId == 1) var newStatus = 4;
    const response = await SecureFetch(`${api_server}/contacts-list/${sourceList}`,"PUT", JSON.stringify({'status':newStatus}))
    const payload  = await response.json()
    return {
      data: payload ?? []
    }
  })

  export const getCampaignCount = createAsyncThunk("getCampaignCount",
  async (params) => {
    const { sourceList  } = params; 
    const response = await SecureFetch(`${api_server}/campaign-count?contact_list_id=${sourceList}`);
    const payload = await response.json();
    return {
      data: payload ?? []
    }
  })
  
  export const getContactDetailsFromContactIDs = createAsyncThunk(
    "getContactDetailsFromContactIDs",
    async (params) => {
      const contactIDs = typeof params === "number" ? [params] : params.concat(",")
      const response = await SecureFetch(
        `${api_server}/nurturing/steps/contacts?contactId=${contactIDs}`
      );
      const payload = await response.json();
      return payload;
    }
  )

  export const contactList = createSlice({
      name: "contactList",
      initialState: contactListInitialState,
      reducers: {
        updateList: (state, action) => {
          state.flags = {
            ...state.flags,
            ...action.payload
          }
        },
        resetList: (state) => {
          state = {
            ...contactListInitialState,
          }
        },
        addError: (state, action) =>  {
          state.flags.error =  state.flags.error.push(action.payload)
        },
        removeError: (state) => {
          state.postListData.error = {}
        }
      },
      extraReducers: (builder) => {
           builder.addCase(getListHeaders.pending, (state) => {
            state.getListHeadersData.data = [];
            state.getListHeadersData.status = "loading";
          });
          builder.addCase(getListHeaders.fulfilled, (state, action) => {
            state.getListHeadersData.data = action.payload.data;
            state.getListHeadersData.status = "loaded";
          });
          builder.addCase(getListHeaders.rejected, (state, action) => {
            state.getListHeadersData.status = "error";
            state.getListHeadersData.error = action.error.message;
          });
          builder.addCase(getList.pending, (state) => { 
            state.getListData.data = [];
            state.getListData.status = "loading";
          });
          builder.addCase(getList.fulfilled, (state, action) => {
            state.getListData.data = action.payload.data;
            state.getListData.status = "loaded";
          });
          builder.addCase(getList.rejected, (state, action) => {    
            state.getListData.status = "error";
            state.getListData.error = action.error.message;
          });

          builder.addCase(postList.pending, (state) => { 
            state.postListData.data = [];
            state.postListData.status = "loading";
          });
          builder.addCase(postList.fulfilled, (state, action) => {
            state.postListData.data = action.payload.data;
            state.postListData.status = "loaded";
          });
          builder.addCase(postList.rejected, (state, action) => {    
            state.postListData.status = "error";
            state.postListData.error = action.payload;
          });
          builder.addCase(changeList.pending, (state) => {
            state.changeListData.data = [];
            state.changeListData.status = "loading";
          });
          builder.addCase(changeList.fulfilled, (state, action) => {
            state.changeListData.data = action.payload.data;
            state.changeListData.status = "loaded";
          });
          builder.addCase(changeList.rejected, (state, action) => {
            state.changeListData.status = "error";
            state.changeListData.error = action.error.message;
          });
          
          builder.addCase(getCampaignCount.pending, (state) => { 
            state.getCampaignCountData.data = [];
            state.getCampaignCountData.status = "loading";
          });
          builder.addCase(getCampaignCount.fulfilled, (state, action) => {
            state.getCampaignCountData.data = action.payload.data;
            state.getCampaignCountData.status = "loaded";
          });
          builder.addCase(getCampaignCount.rejected, (state, action) => {    
            state.getCampaignCountData.status = "error";
            state.getCampaignCountData.error = action.error.message;
          })

          builder.addCase(getContactDetailsFromContactIDs.pending, (state) => {
            state.getListData.status = "loading";
          })
          .addCase(getContactDetailsFromContactIDs.fulfilled, (state, action) => {
            state.getListData.status = "loaded";
            state.getListData.data = action.payload.data
          })
          .addCase(getContactDetailsFromContactIDs.rejected, (state, action) => {
            state.getListData.status = "error";
            state.getListData.data = []
          })
      }
  })

  export const { updateList, resetList, removeError, addError} = contactList.actions;

  export default contactList.reducer;