export const getCampaignType = (val) => {
    if (typeof val === "number") {
        switch (val) {
            case 1: return "Email"
            case 2: return "SMS"
            case 3: return "WhatsApp"
        }
    }
    if (typeof val === "string") {
        switch (val.toLocaleLowerCase()) {
            case "email": return 1
            case "sms": return 2
            case "whatsapp": return 3
        }
    }
}

export const getCampaignStatus = (val) => {
    if (typeof val === "number") {
        switch (val) {
            default: return "Active";
            case 0: return "No Reports"
            case 1: return "Active"
            case 2: return "Draft"
            case 3: return "Completed"
            case 4: return "Archived"
            case 5: return "Failed"
        }
    }
    if (typeof val === "string") {
        switch (val.toLocaleLowerCase()) {
            default: return 0
            case "active": return 1
            case "draft": return 2
            case "completed": return 3
            case "archived": return 4
        }
    }
}

export const getOrderBy = (val) => {
    switch (val.toLocaleLowerCase()) {
        default:
        case "status": return "Status"
        case "oldest first": return "Oldest First"
        case "next occurrence": return "Next Occurrence"
    }
}

export const getDateString = (val) => {
    const date = new Date(val)
    const month = date.getMonth() + 1
    const monthString = month < 10 ? `0${month}` : month
    const day = date.getDate()
    const dayString = day < 10 ? `0${day}` : day
    return `${date.getFullYear()}-${monthString}-${dayString}`
}