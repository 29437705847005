import React, { useEffect, useMemo, useState } from "react"
import CheckAuth from "../components/CheckAuth"
import OccurrencesTable from "../components/OccurrencesTable"
import { useSelector, useDispatch } from "react-redux"
import {
  getAllCampaigns,
  toggleCampaignSelection,
} from "../features/abComparison"
import MultiSelectDropdown from "../components/MultiSelectDropdown"
import ABComparisonHeading from "../components/ABComparisonHeading"

function ABComparison() {
  const dispatch = useDispatch()
  const { campaignDropdown, campaignsData, occurrenceModal, lastLoadReturnedNoData } = useSelector(
    (state) => state.abComparison
  )

  useEffect(
    () => {
      dispatch(getAllCampaigns());
      document.title = "Engagement Hub - A/B Comparison"
    },
    []
  )

  const campaignsDropdownFiltered = campaignDropdown?.data?.filter(
    (item) => {
      if (item.campaign_name === "Campaign Email 4") {debugger;}
      // return ([1,3,4].includes(item.status_id) && item.events > 0)
      return item.status_id === 3 || (item.status_id === 4 && item.value > 0)
    }
  )
  const campaignsDropdownMapped = campaignsDropdownFiltered.map(
    (item) => {
      return {
        text: item.campaign_name,
        value: JSON.stringify(item),
      }
    }
  )

  const selectedCampaigns = useMemo(
    () => {
      return campaignsData.data.length
        ? campaignsData.data.map(
            (v) => {
              return v.parent_campaign_id
            }
          )
        : []
    },
    [campaignsData]
  )

  return (
    <>
      <ABComparisonHeading />
      <CheckAuth />
      <div className="md:px-6 flex-auto flex flex-col">
        <div className="flex flex-col">
        {/*page wrapper*/}
        
        <div className="flex items-center pt-6">
          <MultiSelectDropdown
            disabled={campaignsData.status === "loading"}
            selectedCampaigns={selectedCampaigns}
            marginTop={false}
            loaded={campaignDropdown.status === "loaded"}
            placeHolder={`Campaigns (+${selectedCampaigns.length})`}
            search={true}
            overwritePlaceholder={true}
            options={campaignsDropdownMapped}
            checkedFn={(value) => {
              return selectedCampaigns.includes(value.parent_campaign_id)
            }}
            optionOnToggle={(e) => {
              const value = JSON.parse(e.target.dataset.value)
              dispatch(
                toggleCampaignSelection({
                  id: value.parent_campaign_id,
                  name: value.campaign_name,
                })
              )
            }}
          />
          {lastLoadReturnedNoData && <span className="flex items-center">An error occurred while attempting to load data. Please contact an administrator.</span>}
        </div>
        {selectedCampaigns.length >= 6 && (
          <span className="text-gray-600 mt-12">Maximum of 6 campaigns selected. Please deselect a campaign to add additional campaigns.</span>
        )}
        </div>
        {(campaignsData.data.length || (!campaignsData.data.length && campaignsData.status === "loading")) && (
          <div className="my-6">
            <OccurrencesTable
              data={campaignsData.data}
              loading={campaignsData.status === "loading"}
              modalData={occurrenceModal}
              abComparison={true}
              fileName="A/B Comparison Report"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ABComparison
