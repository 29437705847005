import Dropdown from "../../../Dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  updateCampaign,
} from "../../../../features/campaign";
import Heading2 from "../../../Heading2";
import Label from "../../../Label";
import _ from "lodash";
import React from "react";

function TemplateDesign({setTemplate, currentSelectedStatus}) {
  const { template, templateName, status, errors} = useSelector(
    (state) => state.campaign.flags
  );
  const { templateData } = useSelector((state) => state.campaign);
  const dispatch = useDispatch();


  let templateList = templateData.data.map((template) => {
    console.log(template);
    return {
      text  : template.template_name,
      value : template.template_id,
      render: template.template_render,
      body  : template.template_body
    };
  });

  function createMarkup() {
    let temp = templateList.filter(x => x.value === template);
    if (templateInfo[0]?.template_body !== null && templateInfo[0]?.template_body !== "") {
      return { __html: templateInfo[0]?.template_body };
    } else if (temp[0].body !== null && temp[0].body !== "") {
      return { __html: temp[0].body };
    } else {
      return { __html: '<h3>Error loading template render, please try again.</h3>' };
    }
  }
  
  const templateInfo = templateData.data.filter(
    (option) => option.template_id === template
  );

  const assignTemplate = (target) => {
    if (!isNaN(target.dataset.value)) {
      setTemplate(target.dataset.value);
    } else if (target.getAttribute("data-value")) {
      setTemplate(target.getAttribute("data-value"));
    } else {
      setTemplate(-1);
    }
  }

  return (
    <>
      <div className="w-full min-h-fit flex justify-start border-b border-gray-200" style={{paddingBottom: "30px"}}>
        <div className="w-64">
          <div className="flex items-center">
            <Heading2>Template Design</Heading2>
          </div>
          <p className="mt-4 text-sm leading-5 text-gray-600">
            Select a template design from the list
          </p>
        </div>
        <div>
          <div className="md:flex items-start lg:ml-24 mt-0">
            <div className="md:w-64 md:ml-12 md:mt-0 mt-4">
              {(templateData.status !== "loading") && (
                <>
                  <Label text="Template List" />
                  <Dropdown
                    search={true}
                    overwritePlaceholder={true}
                    optionOnClick={(e) => {
                      assignTemplate(e.target);
                      dispatch(
                        updateCampaign({
                          template: parseInt(e.target.dataset.value),
                        })
                      );
                    }}
                    disabled={
                      status === "Completed" || 
                      status === "Archived" || 
                      status === "Active" ||
                      status === "Failed" ||
                      currentSelectedStatus == 4 ||
                      currentSelectedStatus == 5 ||
                      currentSelectedStatus == 3
                    }
                    options={templateList}
                    error={errors?.includes("template") && !template}
                    placeHolder={templateName ?  templateName : "Template"}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {templateInfo.length > 0 && (
        <div className="w-full min-h-fit flex justify-between border-b border-gray-200 mt-4 flex-wrap">
          <div className="w-80">
            <div className="flex items-center">
              <Heading2>
                Template Render
              </Heading2>
            </div>
          </div>
          <div className="w-full flex justify-center mb-4 relative">
            <div className="absolute top-0 left-0 w-full h-full"></div>
            <div
              className="w-200 h-200 "
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

export default TemplateDesign;
