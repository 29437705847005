import CheckAuth from "../components/CheckAuth";
import { useSelector } from "react-redux";
import { useState } from "react";
import Funnel from "../components/Reporting/Funnel";
import NurturingReportsHeading from "../components/NurturingReportsHeading";
import Steps from "../components/Reporting/Steps";
import Switch from "react-switch";
import { useEffect } from "react";

function NurturingReports() {
  const { graphData } = useSelector((state) => state.nurturingReport)
  const [stepId, setStepId] = useState(1)
  const [showArchived, setShowArchived] = useState(false);
  const {
    reportGraphData,
    reportLinksData,
  } = useSelector((state) => state.report);
  const idData = graphData.data;

  useEffect(
    () => {
      document.title = "Engagement Hub - Nurturing Journey Reports"
    },
    []
  )
  const organizeSteps = () => {
    if (graphData.rawData) {
      var data;
      if (showArchived) {
        var data = graphData.rawData.map(
          (step, index) => {
            return (
              <Steps name={index} key={index} data={step} setStepId={setStepId} idData={idData} reportGraphData={reportGraphData} reportLinksData={reportLinksData}  />
            )
          }
        );
      } else {
        var data = graphData.rawData.map(
          (step, index) => {
            if (step.status !== 'Archived') {
              return (
                <Steps name={index} key={index} data={step} setStepId={setStepId} idData={idData} reportGraphData={reportGraphData} reportLinksData={reportLinksData}  />
              )
            }
          }
        );
      }
      return data;
    } else {
      return null;
    }
  }

  const organizeGraph = () => {
    if (graphData.data && graphData.data.categories && graphData.data.series) {
      if (showArchived) {
        return graphData.data;
      } else {
        // Get list of archived steps
        var filteredSteps = [];
        graphData.rawData.forEach(raw => { 
          if (raw.status === 'Archived') {
            filteredSteps.push(raw.step_name);
          }
        })
        // Build list of indexs
        var filteredIndex = [];
        var categories = [];
        graphData.data.categories.forEach((category, i) => {
          if (filteredSteps.includes(category)) {
            filteredIndex.push(graphData.data.categories.indexOf(category))
          } else {
            categories.push(category)
          }
        });
        // Filter series
        var filteredSeries = {};
        var seriesCollection = [];
        graphData.data.series.forEach(series => {
          filteredSeries[series.name] = [];
          series.data.forEach((count, i) => {
            if (!filteredIndex.includes(i)) {
              filteredSeries[series.name].push(count)
            }
          })
          var obj = {
            'name':series.name,
            'data':filteredSeries[series.name]
          }
          seriesCollection.push(obj)
        });
        // Build response
        var obj = {
          'categories':categories,
          'series':seriesCollection
        }
        // Build returned data 
        return obj;
      }
    } else {
      return null;
    }
  }

  var organizeStepsData = organizeSteps();
  var organizeGraphData = organizeGraph();

  return (
    <>
      <CheckAuth />
      <NurturingReportsHeading archived={showArchived} setArchived={setShowArchived} />
      {(graphData?.data?.series && graphData?.rawData && graphData?.data?.categories) ? <>
        <div className="col-span-1 justify-self-center flex items-center mt-5 ml-7">
          <Switch
            onChange={() => setShowArchived(!showArchived)}
            checked={showArchived}
          />
          <span className="ml-2">Show Archived Steps</span>
        </div>
        <div className="mx-6 mt-6">
          <Funnel data={organizeGraphData}/>
        </div>
        <div className="bg-gray-100">
          <div className="m-6">
            {organizeStepsData}
          </div>
        </div>
      </> : (
        <div className="grid grid-cols-nurturing flex-auto justify-between">
          <div className="col-span-5 justify-self-center self-center flex items-center">
            {
              graphData.state === "loaded"
                ? "No results found"
                : graphData.status === "loading"  ?  
                <div className="justify-self-center self-center flex items-center">
                <div>
                  <div className="w-8 h-8 border-solid rounded-full animate-spin border-4 border-primary-color-dark border-w-400 border-t-transparent mr-6"></div>
                </div>
                <span className="text-3xl">Loading...</span>
              </div>
              :
              "Please use the controls above to view information about a specific campaign."
            }
          </div>
        </div>
      )}
    </>
  );
}

export default NurturingReports;
