import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import {useSelector} from "react-redux"
import { useState, useRef } from "react";
import Button from './Button';

const Calendar = (props) => {
  const {filter} = useSelector((state) => state.engagementHubManager)
  const customInputRef = useRef();
	const styling = {
			marginRight: "1.5rem",
			"&:last-child": {
					marginRight: "0"
			},
			"& .MuiTextField-root": {
					width: "10rem",
					minWidth: "10rem"
			},
			"& .MuiOutlinedInput-input": {
					fontSize: "14px",
					height: "32px",
					padding: "8px"
			},
			"& .MuiOutlinedInput-root": {
					flexDirection: "row-reverse"
			},
			"& .MuiInputAdornment-root": {
					height: "auto",
					margin: "0 4px 0 8px"
			},
			"& .MuiButtonBase-root": {
					padding: "0",
					"& svg": {
							height: "32px",
							width: "32px",
							paddingLeft: "8px",
							paddingRight: "8px"
					}
			},
			"& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
					top: "-2px",
					paddingLeft: "24px",
					fontSize: "14px"
			},
			"& .Mui-focused": {
					color: "black"
			}
	}
	const [open, setOpen] = useState(false);
	return (
		<>
				<LocalizationProvider dateAdapter={AdapterMoment}>
						<Box 
								sx={styling}>
								<props.type
										PopperProps={{ anchorEl: customInputRef.current }}
										renderInput={
											({ ref, inputProps }) => {
												return (
													<div ref={ref}>
														<Button
															interior={props.buttonText}
															backgroundColorOverride={1}
															reference={customInputRef}
															onClick={() => setOpen(!open)} {...inputProps}>
														</Button>
													</div>
												)
											}
										}
										label={props.label}
										type="datetime-local"
										value={null}
										open={open}
										onClose={() => setOpen(false)}
										onChange={props.onChange}
								/>
						</Box>
				</LocalizationProvider>
		</>
	)
}

export default Calendar;
