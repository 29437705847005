import { CalendarIcon } from "@heroicons/react/outline"
import { useSelector } from "react-redux"

export default function CampaignCounter({count}) {
    const { getCampaignCountData } = useSelector((state) => state.contactList.flags)
    const loadingSpinner = <div className="w-2 h-2 border-solid rounded-full animate-spin border border-4 border-primary-color-dark"></div>
    return (
      <div className="flex items-center justify-items-stretch border-2 rounded border-primary-color-dark text-base px-2 py-2 w-64">
          <CalendarIcon className="h-6 w-6 mr-4 text-black" />
          {getCampaignCountData?.status === "loading" ? loadingSpinner : `Campaign Count: ${count === undefined ? "" : count}` }
      </div>
      )
  }